<div class="main-container" [ngStyle]="{'minHeight': headerHeight}" [ngClass]="{'disabled': isDisabled }" tabindex="0"
  (keydown.space)="openDropDown()" (keydown.esc)="onClickOutSideDropdown($event)">
  <div class="single-dropdown-container" [ngStyle]="{'minHeight': headerHeight, 'height': headerHeight}">
    <div (click)=" openDropDown();getDropDownPosition($event)" class="selected-elements-and-search-area-container"
      [ngStyle]="{'minHeight': headerHeight}">
      <input *ngIf="isDropdownOpened " #searchInput [(ngModel)]="searchText" (input)="searchInputChange.next()"
        maxlength="50" type="text" class="search-area" placeholder="search">
      <div *ngIf="!isDropdownOpened" class="selected-item-container" [ngStyle]="{'minHeight': headerHeight}"
        [ngClass]="{'disabled': isDisabled, 'text-cursor': showSearchIcon}">
        <ng-container *ngIf="template && selectedItem; else defaultedSelectedTemplate">
          <ng-container *ngTemplateOutlet="template; context: {$implicit: selectedItem}"></ng-container>
        </ng-container>
        <ng-template #defaultedSelectedTemplate>
          <div class="selected-item w-100" lfxEllipsis *ngIf="selectedItem">
            {{searchFieldName ? selectedItem[searchFieldName] : selectedItem}}
          </div>
        </ng-template>
        <div class="selected-item placeholder" lfxEllipsis *ngIf="!selectedItem">
          {{dropdownPlaceholder}}
        </div>
        <div class="dropdown-icon">
          <i class="fas fa-search" *ngIf="!selectedItem && showSearchIcon"></i>
          <i class="fas fa-chevron-down" *ngIf="!selectedItem && !showSearchIcon"></i>
          <i class="fas fa-times" (click)="selectItem();" *ngIf="selectedItem"></i>
        </div>
      </div>
    </div>
    <virtual-scroller #singleScroll [items]="viewItems" [delayClickOutsideInit]="true" @fadeInTop @fadeOutTop
      [ngClass]="{'dropdown-up': dropDownPosition === 'up','dropdown-down':dropDownPosition === 'down'}"
      [exclude]="'.single-dropdown-container'" *ngIf="isDropdownOpened" class="data-elements-container"
      style="height: 230px;" #dropdownScroll (clickOutside)="onClickOutSideDropdown($event)">
      <ng-container *ngTemplateOutlet="dropdownMainContent;context:{usedItems: singleScroll.viewPortItems}">
      </ng-container>
    </virtual-scroller>

  </div>
</div>


<ng-template #dropdownMainContent let-usedItems="usedItems">

  <ng-container *ngIf="!isLoading && !noDataFound">
    <div class="element-container" lfxEllipsis (click)="selectItem(item);"
      *ngFor="let item of usedItems; let itemIndex = index">

      <ng-container *ngIf="template; else defaultedTemplate">
        <ng-container *ngTemplateOutlet="template; context: {$implicit: item}"></ng-container>
      </ng-container>
      <ng-template #defaultedTemplate>
        <span class="w-100" lfxEllipsis>{{searchFieldName ? item[searchFieldName] : item}}</span>
      </ng-template>
    </div>
  </ng-container>

  <div *ngIf="noDataFound" class="no-items-found">
    No data
  </div>
  <div *ngIf="isLoading" class="no-items-found">
    <lfx-loading></lfx-loading>
  </div>
</ng-template>