import { MentorReviewApplicationComponent } from './pages/mentor-review-application/mentor-review-application.component';
import { ViewProgramPopupComponent } from './component/view-program-popup/view-program-popup.component';
import { MenteeOtherApplicationPopupComponent } from './component/mentee-other-application-popup/mentee-other-application-popup.component';
import { ProgramListViewComponent } from './pages/program-list-view/program-list-view.component';
import { ProgramDetailCardComponent } from './component/program-detail-card/program-detail-card.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ProgramOverviewComponent } from './pages/program-overview/program-overview.component';
import { ProgramOverviewHeaderComponent } from './component/program-overview-header/program-overview-header.component';
import { ProgramMenteeApplicationComponent } from './pages/program-mentee-application/program-mentee-application.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    ProgramOverviewComponent,
    ProgramOverviewHeaderComponent,
    ProgramDetailCardComponent,
    ProgramListViewComponent,
    MenteeOtherApplicationPopupComponent,
    ViewProgramPopupComponent,
    MentorReviewApplicationComponent,
    // ProgramMenteeApplicationsComponent,
    ProgramMenteeApplicationComponent
  ],
  entryComponents: [
    MenteeOtherApplicationPopupComponent,
    ViewProgramPopupComponent
  ],
  exports: [
    ProgramOverviewComponent,
    ProgramOverviewHeaderComponent
  ]
})
export class MentorshipModule { }
