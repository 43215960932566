import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { APIService } from 'src/app/API.service';
import { MentorService } from 'src/app/core/services/mentor.service';

@Component({
  selector: 'lfx-view-program-popup',
  templateUrl: './view-program-popup.component.html',
  styleUrls: ['./view-program-popup.component.scss']
})
export class ViewProgramPopupComponent implements OnInit {

  viewProgram = false;
  program: any;

  constructor(
    public activeModal: NgbActiveModal,
    private api: APIService,
    private mentorService: MentorService
  ) { }

  ngOnInit() {
  }

  viewProgramDetails() {
    this.activeModal.close('');
  }

  updateMentorProgramStatus(status: string) {
    this.api.UpdateMentorProgram({
      id: this.mentorService.getMentorProgramByProgramId(this.program.id)!.id,
      invitationStatus: status,
      dateAccepted: new Date().getTime()
    }).then(res => {
      this.mentorService.getMentorProgramByProgramId(this.program.id)!.invitationStatus = status;
      this.activeModal.close('updateStatus');
    });
  }

}
