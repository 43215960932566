export const MAX_TASK_NAME_LENGTH = 20;
export const MAX_TASK_DESCRIPTION_LENGTH = 500;
export const MIN_TITLE_LENGTH = 3;
export const MAX_TITLE_LENGTH = 100;
export const MAX_DESCRIPTION_LENGTH = 1500;
export const MENTEE_ID = '0034100000dFd99AAC'


export const ProgramStatus = {
    pending: "pending",
    completed: "completed",
    draft: 'draft',
    accepted: "accepted",
    declined: "declined",
    acceptingApplication: "Accepting Applications"
}

export const MenteeApplicationStatus = {
    draft: "draft",
    pending: "pending",
    accepted: "accepted",
    declined: "declined",
    submitted: "submitted",
    graduated: "graduated"
}

export const MentorInvitationStatus = {
    pending: "pending",
    accepted: "accepted",
    declined: "declined"
}

export const MentorReviewStatus = {
    pending: "pending",
    accepted: "accepted",
    declined: "declined"
}

export const taskStatus = {
    pending: 'pending',
    submitted: 'submitted',
    completed: 'completed',
    uploaded: 'uploaded',
    declined: "declined"
}

export const MilestoneActivityActionType = {
    submitted: 'submitted',
    feedback: 'feedback',
    comment: 'comment'
}

