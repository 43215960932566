import { MentorService } from './../../core/services/mentor.service';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { User } from 'src/app/core/models/user.model';
import { Program } from 'src/app/models/program';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  activeTab = 'Home';
  programsTypes = ['Program List', 'Program Applications', 'Program Mentees'];
  messagesTypes = ['Email', 'Message'];
  isProgramsDropdownOpen = false;
  isMessageDropdownOpen = false;
  @Input() user?: User;
  program!: Program;
  breadcrumbs = [
    {
      name: "programs",
      url: '/programs'
    },
    {
      name: '',
      url: '/program/overview/'
    },
    {
      name: "applications",
      url: ""
    }
  ];
  constructor(private route: Router,
    public mentorService: MentorService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.handleActiveTab();
    this.route.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        this.handleActiveTab();
      }
    });
  }

  private handleActiveTab() {
    const urlList = this.route.url.split('/');
    urlList.splice(0, 1);
    if (urlList.includes('programs')) {
      this.changeActiveTab('Programs');
    }
    if (urlList.includes('milestone')) {
      this.changeActiveTab('milestone');
    }
  }

  navigateToTaskBoard(tabName: string) {
    this.route.navigateByUrl('/program/milestone');
    this.changeActiveTab(tabName);
  }

  changeActiveTab(newTab: string) {
    this.activeTab = newTab;
    switch (newTab) {
      case 'Mentors':
        this.route.navigateByUrl('/mentors');
        break;
      case 'Mentees':
        this.route.navigateByUrl('/mentees');
        break;
      default:
        break;
    }
  }

  toggleProgramDropdown() {
    this.isProgramsDropdownOpen = !this.isProgramsDropdownOpen;
  }

  toggleMessageDropdown() {
    this.isMessageDropdownOpen = !this.isMessageDropdownOpen;
  }

  selectMessage(active: any) {
    this.changeActiveTab('Messages');
    this.toggleMessageDropdown();
  }

  selectProgramType(active: any) {
    this.changeActiveTab('Programs');
    this.route.navigate(['/programs']);
    this.toggleProgramDropdown();
  }

  removeUser() {
    this.mentorService.activeMentor = undefined;
    this.route.navigate(['/mentors'])
  }

  allPrograms(evt: any) {
    console.log("header prog:", evt)
  }
}
