import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lfx-card-number-view',
  templateUrl: './card-number-view.component.html',
  styleUrls: ['./card-number-view.component.scss']
})
export class CardNumberViewComponent implements OnInit {

  @Input() cardValue: any;
  @Input() cardText!: string;

  constructor() { }

  ngOnInit() {
  }

}
