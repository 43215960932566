<ng-container *ngIf="viewProgram; else viewProgramTemplate">

  <div class="modal-header align-items-center">
    <h4 class="modal-title weight-600 size-20" id="modal-basic-title">Program Overview</h4>
    <i class="fa fa-times close-btn" aria-hidden="true" (click)="activeModal.dismiss('Cross click')"></i>
  </div>

  <div class="modal-body">
    <lfx-program-overview [program]="program" [withBreadcrumbs]="false"></lfx-program-overview>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link px-0 mr-3" (click)="activeModal.dismiss('Cross click')">Decide
      Later</button>
    <button type="button" class="btn btn-outline-secondary px-3 ml-1 mr-3"
      (click)="updateMentorProgramStatus('Declined')">Decline Invitation</button>
    <button type="button" class="btn btn-primary px-3 ml-1" (click)="updateMentorProgramStatus('accepted')">Accept
      Invitation</button>
  </div>
</ng-container>
<ng-template #viewProgramTemplate>
  <div class="modal-body lg-padding flex-unset d-flex flex-column align-items-center">
    <img class="mb-40" src="assets/Handshake_741355.svg">
    <span class="size-32 weight-600 text-light-black">Welcome To Mentorship!</span>
    <span class="size-24 text-light-gray-1 mb-40">Matthew Fisher has invited you to mentorship program!</span>
    <div class="d-flex align-items-center">
      <button type="button" class="btn btn-outline-secondary mr-3"
        (click)="activeModal.dismiss('Cross click')">Cancel</button>
      <button type="button" class="btn btn-primary ml-1" (click)="viewProgramDetails()">View Program</button>
    </div>
  </div>
</ng-template>