<div class="header-container" id="app-header">
  <div class="d-flex align-items-center flex-wrap">
    <a [routerLink]="['/']">
      <div class="header-item home" [ngClass]="{ active: activeTab === 'Home' }" (click)="changeActiveTab('Home')">
        <i class="fas fa-home"></i>
      </div>
    </a>
    <div class="header-item" [ngClass]="{ active: activeTab === 'Programs' }"
      (clickOutside)="isProgramsDropdownOpen = false">
      <span class="d-flex align-items-center" (click)="toggleProgramDropdown()">
        Programs
        <i class="fas fa-chevron-down"></i>
      </span>
      <app-searchable-dropdown class="searchable-dropdown" *ngIf="isProgramsDropdownOpen" (allPrograms)="allPrograms($event)"
        (choiceItem)="selectProgramType($event)" [items]="programsTypes" [showSearchInput]="true">
      </app-searchable-dropdown>
    </div>
    <div class="header-item" (click)="navigateToTaskBoard('milestone')"
      [ngClass]="{ active: activeTab === 'milestone' }">
      Milestone
    </div>
    <div class="header-item" (click)="changeActiveTab('Mentors')" [ngClass]="{ active: activeTab === 'Mentors' }">
      Mentors
    </div>
    <div class="header-item" (click)="changeActiveTab('Mentees')" [ngClass]="{ active: activeTab === 'Mentees' }">
      Mentees
    </div>
    <div class="header-item" (clickOutside)="isMessageDropdownOpen = false"
      [ngClass]="{ active: activeTab === 'Messages' }">
      <span class="d-flex align-items-center" (click)="toggleMessageDropdown()">
        Messages
        <i class="fas fa-chevron-down"></i>
      </span>
      <app-searchable-dropdown class="searchable-dropdown" *ngIf="isMessageDropdownOpen"
        (choiceItem)="selectMessage($event)" [items]="messagesTypes">
      </app-searchable-dropdown>
    </div>
    <div class="header-item" (click)="changeActiveTab('Forms')" [ngClass]="{ active: activeTab === 'Forms' }">
      Forms
    </div>
  </div>
  <div class="user-profile d-flex align-items-center ml-4 mb-2" *ngIf="mentorService.activeMentor">
    <span class="cursor-pointer" (click)="removeUser()">Log out</span>
  </div>
</div>