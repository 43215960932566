<form class="card create-task-container w-100 px-0" [formGroup]="taskForm">
  <div class="card-header text-dark weight-600 size-18">
    Task Actions
  </div>
  <div class="card-body">
    <ngb-accordion #accordion [closeOthers]="true">
      <ngb-panel id="tab-1">
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="panel-header">
            <span class="d-flex align-items-center size-14 weight-600 gray-1">
              <i class="fas fa-folder-plus mr-3"></i>
              Add Task
            </span>
            <button class="btn btn-link p-0 gray-1" ngbPanelToggle>
              <i class="fas fa-chevron-right" *ngIf="!opened"></i>
              <i class="fas fa-chevron-down" *ngIf="opened"></i>
            </button>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="form-group">
            <label for="task-name" class="label-title">Task Name <span class="text-danger">*</span></label>
            <input type="text" class="form-control" formControlName="name" name="task-name" id="task-name"
              placeholder="Enter name here..">
          </div>
          <ng-container *ngTemplateOutlet="taskDescription"></ng-container>
          <div class="custom-control custom-checkbox mb-4-2">
            <input type="checkbox" class="custom-control-input" id="upload-is-required" formControlName="uploadRequired"
              [value]="true">
            <label class="custom-control-label label-title cursor-pointer" for="upload-is-required">
              Check If Completion Of This Task Requires That Mentee Submits A File.
            </label>
          </div>
          <div class="task-actions d-flex align-items-center justify-content-end">
            <button type="button" class="btn btn-primary custom-btn" (click)="accordion.toggle('tab-2')">Next</button>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="tab-2">
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="panel-header">
            <span class="d-flex align-items-center size-14 weight-600 gray-1">
              <i class="far fa-calendar-plus mr-3"></i>
              Add Due Date
            </span>
            <button class="btn btn-link p-0 gray-1" ngbPanelToggle>
              <i class="fas fa-chevron-right" *ngIf="!opened"></i>
              <i class="fas fa-chevron-down" *ngIf="opened"></i>
            </button>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="form-group ">
            <label for="task-due-date" class="label-title">Due Date <span class="text-danger">*</span></label>
            <input name="dp" formControlName="dueDate" [minDate]="utilities.convertDateToNgbDate(minTaskDueDate)"
              [maxDate]="utilities.convertDateToNgbDate(maxTaskDueDate)" ngbDatepicker #termEndDate="ngbDatepicker"
              [placement]="['bottom', 'top']" class="form-control date-input" readonly name="task-due-date"
              (click)="termEndDate.toggle()" id="task-due-date" placeholder="DD/MM/YY">
            <i class="fas fa-calendar-alt" (click)="termEndDate.toggle()" *ngIf="!taskForm.get('dueDate')?.value"></i>
            <i class="fas fa-times" (click)="clearDate('dueDate')" *ngIf="taskForm.get('dueDate')?.value"></i>
          </div>
          <div class="task-actions d-flex align-items-center justify-content-end">
            <button type="button" class="btn btn-primary custom-btn" (click)="accordion.toggle('tab-3')">Next</button>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="tab-3">
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="panel-header">
            <span class="d-flex align-items-center size-14 weight-600 gray-1">
              <i class="fas fa-link mr-3"></i>
              Attachment
            </span>
            <button class="btn btn-link p-0 gray-1" ngbPanelToggle>
              <i class="fas fa-chevron-right" *ngIf="!opened"></i>
              <i class="fas fa-chevron-down" *ngIf="opened"></i>
            </button>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="add-attachment d-flex align-items-center flex-column">
            <span>Drop files to upload</span>
            <span>Or</span>
            <button type="button" class="btn btn-primary custom-btn">Browse Files</button>
            <input type="file" name="uploadFile" (change)="uploadFile($event.target)" id="">
          </div>
          <div class="file-list">
            <div class="file w-100 px-3 py-2 d-flex align-items-center justify-content-between mb-2"
              *ngFor="let file of attachments.value; let index = index">
              <span lfxEllipsis>
                {{file?.name}}
              </span>
              <i class="fas fa-times-circle text-danger ml-3 cursor-pointer" (click)="removeFile(index)"></i>
            </div>
          </div>
          <div class="task-actions d-flex align-items-center justify-content-end">
            <button type="button" class="btn btn-link mr-3" (click)="accordion.toggle('tab-4')">Skip</button>
            <button type="button" class="btn btn-primary custom-btn" (click)="accordion.toggle('tab-4')">Next</button>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="tab-4">
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="panel-header">
            <span class="d-flex align-items-center size-14 weight-600 gray-1">
              <i class="fas fa-people-carry mr-3"></i>
              Assign To Mentee
            </span>
            <button class="btn btn-link p-0 gray-1" ngbPanelToggle>
              <i class="fas fa-chevron-right" *ngIf="!opened"></i>
              <i class="fas fa-chevron-down" *ngIf="opened"></i>
            </button>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="mentee-card d-flex align-items-center w-100 mb-2">
            <div class="custom-control custom-checkbox mr-2">
              <input type="checkbox" class="custom-control-input" [id]="'allMenteeCheck'" [checked]="isAllSelected()"
                (change)="$event ? toggleSelectAllMentees(): null">
              <label class="custom-control-label" [for]="'allMenteeCheck'">
              </label>
            </div>
            <ng-container
              *ngTemplateOutlet="nameTemplate;context:{mentee:{firstName: 'Assign', lastName: 'All'}, withOutImage: true}">
            </ng-container>
          </div>
          <div class="mentee-card d-flex align-items-center w-100 mb-2"
            *ngFor="let mentee of milestone.program.mentees;let index = index"
            (click)="selection.toggle(mentee);$event.stopPropagation()">
            <div class="custom-control custom-checkbox mr-2">
              <input type="checkbox" class="custom-control-input" [id]="'menteeCheck'+index"
                [checked]="selection.isSelected(mentee)" (change)="$event ? selection.toggle(mentee): null">
              <label class="custom-control-label" [for]="'menteeCheck'+index">
              </label>
            </div>
            <ng-container *ngTemplateOutlet="nameTemplate;context:{mentee:mentee}"></ng-container>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <div class="task-actions d-flex align-items-center justify-content-end mt-2">
      <button type="button" class="btn btn-outline-secondary custom-btn mr-3"
        (click)="clearTaskCreation();accordion.collapseAll()">Cancel</button>
      <button type="button" class="btn btn-primary custom-btn" [disabled]="taskForm.invalid"
        (click)="createNewTask();accordion.collapseAll()">Add Task</button>
    </div>
  </div>
</form>


<ng-template #nameTemplate let-mentee="mentee" let-withOutImage="withOutImage">
  <div class="d-flex align-items-center w-100">
    <img class="user-image mr-2" *ngIf="!withOutImage"
      [src]="mentee.imageUrl ? mentee.imageUrl :'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/'+mentee.firstName.toLowerCase().charAt(0)+'.png'"
      alt="">
    <span class="user-name" lfxEllipsis>{{mentee['firstName'] + ' ' + mentee['lastName']}}</span>
  </div>
</ng-template>



<ng-template #taskDescription>
  <form [formGroup]="taskForm" class="form-group">
    <label for="task-name" class="label-title">Task Description <span class="text-danger">*</span></label>
    <quill-editor formControlName="description"
      [styles]="{height: '150px', color: '#000', fontSize: '14px', fontFamily: 'Open Sans'}"
      name="milestone-description" id="milestone-description">
      <div quill-editor-toolbar>
        <span class="ql-formats">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
        </span>
        <span class="ql-formats">
          <select class="ql-align">
            <option selected></option>
            <option value="center"></option>
            <option value="right"></option>
            <option value="justify"></option>
          </select>
        </span>
        <span class="ql-formats">
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
        </span>
        <span class="ql-formats">
          <button class="ql-link"></button>
        </span>
      </div>
    </quill-editor>
  </form>
</ng-template>