import { MilestoneDetailsComponent } from './pages/milestone-details/milestone-details.component';
import { CreateNewTaskComponent } from './components/create-new-task/create-new-task.component';
import { MilestoneCardComponent } from './components/milestone-card/milestone-card.component';
import { TaskManagementComponent } from './pages/task-management/task-management.component';
import { CreateMilestonePopupComponent } from './components/create-milestone-popup/create-milestone-popup.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MenteeTaskManagementComponent } from './pages/mentee-task-management/mentee-task-management.component';
import { MentorshipModule } from '../mentorship/mentorship.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MentorshipModule
  ],
  declarations: [
    TaskManagementComponent,
    CreateMilestonePopupComponent,
    MilestoneCardComponent,
    MilestoneDetailsComponent,
    CreateNewTaskComponent,
    MenteeTaskManagementComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class TaskManagementModule { }
