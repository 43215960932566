<div class="mentee-card-container card">
  <div class="mentee-card-header card-header">
    <img class="mb-3-2"
      [src]="mentee.imageUrl ? mentee.imageUrl :'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/'+mentee.firstName.toLowerCase().charAt(0) +'.png'">
    <span class="weight-bold text-primary cursor-pointer" (click)="navigateToMenteeDetail()">{{mentee.name}}</span>
  </div>
  <div class="mentee-card-body card-body">
    <div class="col-12 info-section-container d-flex justify-content-between px-0 mb-3-2">
      <div class="col-6">
        <ng-template [ngTemplateOutlet]="programsSection"></ng-template>
      </div>
      <div class="col-6">
        <ng-template [ngTemplateOutlet]="statusSection"></ng-template>
      </div>
    </div>
    <div class="col-12 d-flex justify-content-between px-0 mb-3-2">
      <div class="col-6">
        <ng-template [ngTemplateOutlet]="menteeSection"></ng-template>
      </div>
      <div class="col-6">
        <ng-template [ngTemplateOutlet]="contactSection"></ng-template>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center py-4">
    <button type="button" class="btn btn-outline-primary custom-btn">View Profile</button>
  </div>
</div>


<!-- Programs section template -->
<ng-template #programsSection>
  <div class="bold-title">
    Program
  </div>
  <div class="d-flex flex-column w-100 ">
    <span class="text-primary weight-400 size-12 mb-2 w-100 mw-100 cursor-pointer" lfxEllipsis
      *ngFor="let program of mentee?.menteeApplications | slice: 0:2" (click)="viewProgram(program.program)">
      {{program.program.programName}}
    </span>
    <div *ngIf="mentee.menteeApplications && mentee.menteeApplications.length > 2"
      class="text-primary weight-400 size-12" (mouseleave)="closePopover()">
      <span [ngbPopover]="programListViewerTemplate" #programList="ngbPopover" [autoClose]="false"
        (mouseenter)="openPopover(programList)" [placement]="['bottom', 'top']" triggers="manual"
        popoverClass="custom-popover" class="cursor-default">
        +{{ mentee.menteeApplications.length - 2}}
      </span>
    </div>
  </div>
</ng-template>

<!-- Status section template -->
<ng-template #singleStatusSection>
  <div class="bold-title">
    Status
  </div>
  <span class="status weight-400 size-12 mb-2 w-100 mw-100" [ngClass]="{ 
      'pending-status': mentee.status === menteeStatus.pending,
      'accepted-status': mentee.status === menteeStatus.accepted,
      'declined-status': mentee.status === menteeStatus.declined,
      'completed-status': mentee.status === menteeStatus.graduated
    }" lfxEllipsis>
    {{mentee.status | titlecase}}
  </span>
</ng-template>

<!-- Status section template -->
<ng-template #statusSection>
  <div class="bold-title">
    Status
  </div>
  <div class="d-flex flex-column w-100">
    <span class="status weight-400 size-12 mb-2 w-100 mw-100"
      *ngFor="let application of mentee?.menteeApplications | slice: 0:2" [ngClass]="{ 
      'pending-status': application.applicationStatus === menteeStatus.pending,
      'accepted-status': application.applicationStatus === menteeStatus.accepted,
      'declined-status': application.applicationStatus === menteeStatus.declined,
      'completed-status': application.applicationStatus === menteeStatus.graduated
    }" lfxEllipsis>
      {{application.applicationStatus | titlecase}}
    </span>
    <div *ngIf="mentee.menteeApplications && mentee.menteeApplications.length > 2"
      class="text-primary weight-400 size-12" (mouseleave)="closePopover()">
      <span [ngbPopover]="statusListViewerTemplate" #statusList="ngbPopover" [autoClose]="false"
        (mouseenter)="openPopover(statusList)" [placement]="['bottom', 'top']" triggers="manual"
        popoverClass="custom-popover" class="cursor-default">
        +{{ mentee.menteeApplications.length - 2}}
      </span>
    </div>
  </div>
</ng-template>

<!-- Contact section template -->
<ng-template #contactSection>
  <div class="bold-title">
    Contact
  </div>
  <div class="d-flex align-items-center">
    <img class="mr-2 cursor-pointer" src="assets/ico_msg.svg" alt="">
    <img class="ml-1 cursor-pointer" src="assets/ico_mail.svg" alt="">
  </div>
</ng-template>

<!-- Mentors section template -->
<ng-template #menteeSection>
  <div class="bold-title">
    Mentors
  </div>
  <div class="icons-container">
    <ng-container *ngFor="let mentee of mentee?.mentors | slice: 0:3">
      <img class="icon"
        [src]="mentee.imageUrl ? mentee.imageUrl : 'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/'+mentee.firstName.toLowerCase().charAt(0)+'.png'"
        [title]="mentee.firstName + ' ' + mentee.lastName" />

    </ng-container>
    <div *ngIf="mentee.mentors && mentee.mentors.length > 3" class="icon-default" (mouseleave)="closePopover()">
      <span [ngbPopover]="menteeListViewerTemplate" #menteeList="ngbPopover" (mouseenter)="openPopover(menteeList)"
        [placement]="['bottom', 'top']" triggers="manual" popoverClass="custom-popover" class="cursor-default">
        +{{ mentee.mentors.length - 3}}
      </span>
    </div>
  </div>
</ng-template>


<ng-template #programListViewerTemplate>
  <div class="selected-items-popover-item text-primary weight-400 size-12 cursor-pointer ellipsis"
    *ngFor="let application of mentee.menteeApplications | slice: 2" (click)="viewProgram(application.program)">
    {{ application.program.programName }}
  </div>
</ng-template>

<ng-template #menteeListViewerTemplate>
  <div class="selected-items-popover-item ellipsis" *ngFor="let mentor of mentee.mentors | slice: 3">
    {{ mentor.firstName + ' ' + mentor.lastName }}
  </div>
</ng-template>

<ng-template #statusListViewerTemplate>
  <div class="status weight-400 size-12 mb-2 w-100 mw-100 ellipsis"
    *ngFor="let application of mentee.menteeApplications | slice: 2" [ngClass]="{ 
    'pending-status': application.applicationStatus === menteeStatus.pending,
    'accepted-status': application.applicationStatus === menteeStatus.accepted,
    'declined-status': application.applicationStatus === menteeStatus.declined,
    'completed-status': application.applicationStatus === menteeStatus.graduated
  }">
    {{application.applicationStatus | titlecase}}
  </div>
</ng-template>