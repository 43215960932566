import { Component, OnDestroy, Output, EventEmitter, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { Subject, Observable, timer, merge, of } from 'rxjs';
import { debounce, switchMap, map, takeUntil, tap, catchError, filter } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'lfx-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss'],
})
export class InputSearchComponent implements OnDestroy {
  @Input() filterFun!: (filterText: string) => Observable<{ items: any[], nextToken: any }>;
  @Input() template?: TemplateRef<any>;
  @Output() filter = new EventEmitter();

  showMore = false;
  listFilter = '';
  listFilterListener$ = new Subject();
  filteredList$: Observable<any[] | null> = new Observable();
  search$: Observable<any[] | null> = new Observable();
  clear$: Subject<null> = new Subject();
  destroy$ = new Subject();
  isLoading = false;
  showList = false;
  projectEnter = false;

  constructor(
    private router: Router
  ) {
    this.addListenerProgramSearch();
  }

  addListenerProgramSearch() {
    this.search$ = this.listFilterListener$.pipe(
      // distinctUntilChanged(),
      map((value: any) => value),
      // if character length greater than or equal to 3
      filter(res => res.length >= 3 || res.length === 0),
      tap(() => {
        this.isLoading = true;
        this.showList = false;
        this.projectEnter = false;
      }),
      debounce(() => timer(350)),
      switchMap((value: any) => this.getPageFromType(value)),
      map((page: any) => {
        if (page.nextToken) {
          this.showMore = true;
        } else {
          this.showMore = false;
        }
        return page.items?.length ? page.items : null;
      }),
      tap(() => {
        this.isLoading = false;
        this.showList = !!this.listFilter.length;
      }),
      takeUntil(this.destroy$),
      catchError(err => {
        this.isLoading = false;
        return of(null);
      })
    );

    this.filteredList$ = merge(this.clear$, this.search$);
  }

  getPageFromType(value: string): Observable<any> {
    if (value) {
      return this.filterFun(value);
    } else {
      return of({
        items: []
      });
    }
  }

  onSearchEnter() {
    this.projectEnter = true;
    this.updateParentList();
  }

  updateParentList() {
    this.filter.emit(this.listFilter);
    this.showList = false;
  }

  clearSearch() {
    this.clear$.next(null);
    this.listFilter = '';
    this.showList = false;
    this.filter.emit('');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  navigateTo(item: any) {
    if (item.path) {
      this.router.navigate([item.path]);
    } else {
      this.showList = false;
      this.listFilter = item.name || item.companyName;
      this.onSearchEnter();
    }
  }
}
