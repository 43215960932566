import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-blank-landing-page',
  templateUrl: './blank-landing-page.component.html',
  styleUrls: ['./blank-landing-page.component.scss']
})
export class BlankLandingPageComponent implements OnInit {

  @Output() private createANewOne = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  createNewOne() {
    this.createANewOne.emit();
  }

}
