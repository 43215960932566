import { Component, Input, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'lfx-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  @Input() breadcrumbs = new Array<{
    name: string,
    url: string
  }>();
  headerHeight = 50;
  constructor(private route: Router) { }

  ngOnInit() {
  }

  navigateToURL(breadcrumb: any) {
    if (breadcrumb.url === '') {
      return;
    }
    this.route.navigate([breadcrumb.url])
  }

  @HostListener('window:resize')
  handleResize() {
    const header = document.getElementById('app-header');
    this.headerHeight = (header?.clientHeight ? header.clientHeight : 50);
  }

}
