import { LoadingService } from './../services/loading.service';
// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { UserController } from '../controllers/user.controller';
import { AuthService } from '@auth0/auth0-angular';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(private userController: UserController, private loadingService: LoadingService, private autheService: AuthService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.checkExceptionURLs(request)) {
      this.loadingService.startLoading();
      return next.handle(request).pipe(tap(res => {
        if (res instanceof HttpResponse) {
          this.loadingService.endLoading();
        }
      }), catchError(res => {
        if (res instanceof HttpErrorResponse) {
          this.loadingService.endLoading();
        }
        return throwError(res);
      }));
    } else if (this.isTokenExpired()) {
      localStorage.clear();
      this.loadingService.startLoading();
      return this.autheService.idTokenClaims$.pipe(
        mergeMap((claims: any) => {
          localStorage.setItem('token', claims.__raw);
          const expDate = new Date();
          expDate.setHours(expDate.getHours() + 6);
          localStorage.setItem('exp', expDate.getTime() + '');
          const tokenReq = request.clone({
            setHeaders: { Authorization: `Bearer ${claims.__raw}` },
          });
          return next.handle(tokenReq).pipe(tap(res => {
            if (res instanceof HttpResponse) {
              this.loadingService.endLoading();
            }
          }), catchError(res => {
            if (res instanceof HttpErrorResponse) {
              this.loadingService.endLoading();
            }
            return throwError(res);
          }));
        })
      );
    } else {
      const token = localStorage.getItem('token');
      const tokenReq = request.clone({
        setHeaders: { Authorization: `Bearer ${token}` },
      });
      this.loadingService.startLoading();
      return next.handle(tokenReq).pipe(tap(res => {
        if (res instanceof HttpResponse) {
          this.loadingService.endLoading();
        }
      }), catchError(res => {
        if (res instanceof HttpErrorResponse) {
          this.loadingService.endLoading();
        }
        return throwError(res);
      }));
    }
  }

  isTokenExpired() {
    const date = localStorage.getItem('exp');
    if (date) {
      return new Date().getTime() > +date;
    }
    return true;
  }

  checkExceptionURLs(request: HttpRequest<any>) {
    return (
      request.url.includes('s3.amazonaws.com') ||
      request.url.includes('sso.linuxfoundation.org') ||
      request.url.includes('graphql') ||
      request.url.includes('linuxfoundation-dev.auth0.com')
    );
  }
}
