
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MenteeAPISegments } from 'src/app/models/mentee.enum';
import { MenteeMilestoneListRequest } from 'src/app/models/menteeApplication';
import { User } from 'src/app/user';
import { environment } from 'src/environments/environment';
import { APIService } from '../../API.service';
@Injectable({
    providedIn: 'root'
  })
  export class MenteeController {

    
  readonly MenteeEndpoint = 'https://0sz2cejc5f.execute-api.us-east-1.amazonaws.com/dev/mentorship-service/mentee/';

  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });

  options = {
    headers: this.httpHeaders
  };

  constructor(private http: HttpClient, private api: APIService) { }


getMenteeMilestones(queryParams: MenteeMilestoneListRequest) {
    const token = localStorage.getItem('token');
    this.httpHeaders.append('Authorization', `Bearer ${token}`);
    const endpointExtras = `?userID=${queryParams.userID}&programID=${queryParams.programID}`
    return this.http.get(`${this.MenteeEndpoint}${MenteeAPISegments.ListMileStones}${endpointExtras}`).pipe(map((res:any) => {
      const milestones = res.response.data.listMenteeProgramMilestones.milestones;
      return milestones;
    }));
  }
} 