import { ActivatedRoute, Router } from '@angular/router';
import { MentorService } from './../../../core/services/mentor.service';
import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { ProgramController } from 'src/app/core/controllers/program.controller';
import { ProjectController } from 'src/app/core/controllers/project.controller';
import { forkJoin, from } from 'rxjs';
import * as _ from 'lodash';
import { APIService } from 'src/app/API.service';
import { MentorReviewStatus } from 'src/app/core/constants';
import { mergeMap } from 'rxjs/operators';
import { Program } from 'src/app/models/program';

@Component({
  selector: 'lfx-mentor-review-application',
  templateUrl: './mentor-review-application.component.html',
  styleUrls: ['./mentor-review-application.component.scss']
})
export class MentorReviewApplicationComponent implements OnInit, AfterViewInit {

  program!: Program;
  @ViewChild('taskNameTemplate') taskNameTemplate!: TemplateRef<any>;
  @ViewChild('descriptionTemplate') descriptionTemplate!: TemplateRef<any>;
  @ViewChild('actionsTemplate') actionsTemplate!: TemplateRef<any>;
  menteeApplication = {
    firstName: 'Sameh',
    lastName: 'Mohamed',
    imageUrl: '',
    status: 'pending'
  }
  columns: any[] = [];
  isRecommended = true;
  breadcrumbs = [
    {
      name: "programs",
      url: '/programs'
    },
    {
      name: '',
      url: '/program/overview/'
    },
    {
      name: "applications",
      url: ""
    },
    {
      name: "mentee Application",
      url: ''
    }
  ];
  myPrograms = new Array<any>();
  mentorReview: any;
  feedbackText: string = '';
  overallFeedback: string = '';
  mentorReviewId: string = '';
  isLoading = false;

  constructor(
    private route: Router,
    private programController: ProgramController,
    private projectController: ProjectController,
    private activatedRoute: ActivatedRoute,
    private api: APIService
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.mentorReviewId = this.activatedRoute.snapshot.params?.mentorReviewId;
    this.programController.getMentorReviewById(this.mentorReviewId).pipe(mergeMap(res => {
      this.mentorReview = res;
      return this.programController.getProgramByIdGraphql(res.menteeApplication.program.id);
    })).subscribe(res => {
      this.program = res;
      this.mapProgram();
      this.breadcrumbs[2].url = `/program/${this.program.id}/applications`;
      this.isLoading = false;
    });

    // this.breadcrumbs[2].url = `/program/${this.program.id}/applications`;
    // this.breadcrumbs[3].url = `/program/${this.program.id}/review/1`;
  }

  private mapProgram() {
    this.breadcrumbs[1] = {
      name: this.program.programName,
      url: '/program/overview/' + this.program.id
    };
    this.program = this.programController.mapProgram(this.program);
    this.program.project = this.projectController.getProjectById(this.program.projectID!);
    if (this.program.projectID && !this.program.project) {
      this.projectController.getAllProjectsByIDs([this.program.projectID!]).subscribe(res => {
        this.program.project = res[0];
      });
    }
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.columns = [
      {
        title: 'Prerequisites Name',
        width: '36%',
        isCenter: false,
        template: this.taskNameTemplate
      },
      {
        title: 'Description',
        width: '46%',
        isCenter: false,
        template: this.descriptionTemplate
      },
      {
        title: 'Actions',
        width: '18%',
        isCenter: true,
        template: this.actionsTemplate
      }
    ];
  }

  submitReview() {
    from(this.api.UpdateMentorReview({
      id: this.mentorReviewId,
      feedback: this.overallFeedback,
      status: this.isRecommended ? MentorReviewStatus.accepted : MentorReviewStatus.declined
    })).subscribe(res => {
      this.route.navigateByUrl('/');
    });
  }

  downloadFile(submitFile: string) {
    window.open(submitFile, '_blank')
  }

  submitFeedBack(comments: any) {
    this.updateTaskComment(comments);
  }

  async updateTaskComment(comments: any) {
    let commentNew = {
      commentBy: "mentor",
      commentText: this.feedbackText,
      createdAt: new Date().getTime()
    }
    let allComments = comments?.comments ? _.cloneDeep(comments?.comments) : [];
    allComments.push(commentNew);
    await this.api.UpdatePreRequisiteTask({
      id: comments.id,
      comments: allComments
    }).then((res) => {
      comments.comments = allComments;
      this.feedbackText = '';
      //this.route.navigate([`/program/${this.program.id}/review/${this.mentorReviewId}`]);
      // this.route.navigate(['/mentors']); //this.program.id & mentorReviewId
    });
  }

}
