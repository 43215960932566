import { FormArray } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
export class Utilities {


    checkNumberInRange(e: any, max: number, min: number) {
        const charCode = typeof e.which === "undefined" ? e.keyCode : e.which;
        const charStr = String.fromCharCode(charCode);
        const currentValue =
            charCode === 8
                ? e.currentTarget.value.substring(0, e.currentTarget.value.length - 1)
                : e.currentTarget.value + charStr;
        // const regex = /^[1-9][0-9]?[0]?$/;
        // const x = regex.test(currentValue);
        const y = charCode === 8 ? true : /[0-9]/.test(charStr);
        if (!y || +currentValue > +max || +currentValue < +min) {
            e.preventDefault();
        }
    }

    checkPasteNumberInRange(e: any, max: number, min: number) {
        const paste = e.clipboardData.getData("text");
        const regex = new RegExp(
            `/^[0-9]{${(min + "").length},${(max + "").length - 1}}[0]?$/`
        );
        // const x = regex.test(currentValue);
        const isValid = regex.test(paste);
        if (!isValid || +paste > +max || +paste < +min) {
            e.preventDefault();
        }
    }

    getNameInitials(admin: any) {
        return admin?.firstName?.charAt(0).toUpperCase() + '' + admin?.lastName?.charAt(0).toUpperCase()
    }

    isDateInRange(from: any, to: any, date: any) {
        from = new Date(from);
        to = new Date(to);
        date = new Date(date);
        return date.getTime() < to.getTime() && date.getTime() > from.getTime();
    }

    clearFormArray = (formArray: FormArray) => {
        while (formArray.length !== 0) {
            formArray.removeAt(0)
        }
    }

    uuidV4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    get organization() {
        return this.uuidV4();
    }

    convertDateToNgbDate(date: Date): any {
        if (date) {
            const ngbDate: NgbDateStruct = {
                day: date.getDate(),
                month: date.getMonth() + 1,
                year: date.getFullYear()
            };
            return ngbDate;
        }
        return undefined;
    }
}
