import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ProgramService {
    private _program: any;

    set program(program: any) {
        this._program = program;
    }

    get program() {
        return this._program;
    }


}