<div class="mentor-card-container card">
  <div class="mentor-card-header card-header">
    <img class="mb-3-2"
      [src]="mentor.imageUrl ? mentor.imageUrl :'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/'+mentor.firstName.toLowerCase().charAt(0) +'.png'">
    <span class="weight-bold text-primary cursor-pointer" (click)="navigateToMentorDetail()">{{mentor.name}}</span>
  </div>
  <div class="mentor-card-body card-body">
    <div class="col-12 info-section-container d-flex justify-content-between px-0 mb-3-2">
      <div class="col-6">
        <!-- Mentors section template -->
        <ng-template [ngTemplateOutlet]="programsSection"></ng-template>
      </div>
      <div class="col-6">
        <!-- Current mentees section template -->
        <ng-template [ngTemplateOutlet]="roleSection"></ng-template>
      </div>
    </div>
    <div class="col-12 d-flex justify-content-between px-0 mb-3-2">
      <div class="col-6">
        <!-- Funding to date section template -->
        <ng-template [ngTemplateOutlet]="menteeSection"></ng-template>
      </div>
      <div class="col-6">
        <!-- Graduated mentees section template -->
        <ng-template [ngTemplateOutlet]="contactSection"></ng-template>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center py-4">
    <button type="button" class="btn btn-outline-primary custom-btn">View Profile</button>
  </div>
</div>


<!-- Programs section template -->
<ng-template #programsSection>
  <div class="bold-title">
    Program
  </div>
  <div class="d-flex flex-column w-100 ">
    <span class="text-primary weight-400 size-12 mb-2 w-100 mw-100 cursor-pointer" lfxEllipsis
      *ngFor="let program of mentor?.programs | slice: 0:2" (click)="viewProgram(program.program)">
      {{program.program.programName}}
    </span>
    <div *ngIf="mentor.programs && mentor.programs.length > 2" class="text-primary weight-400 size-12"
      (mouseleave)="closePopover()">
      <span [ngbPopover]="programListViewerTemplate" #programList="ngbPopover" [autoClose]="false"
        (mouseenter)="openPopover(programList)" [placement]="['bottom', 'top']" triggers="manual"
        popoverClass="custom-popover" class="cursor-default">
        +{{ mentor.programs.length - 2}}
      </span>
    </div>
  </div>
</ng-template>

<!-- Role section template -->
<ng-template #roleSection>
  <div class="bold-title">
    Role
  </div>
  <span class="role-name weight-400 size-12 mb-2 w-100 mw-100" lfxEllipsis>
    {{mentor.role}}
  </span>
</ng-template>

<!-- Contact section template -->
<ng-template #contactSection>
  <div class="bold-title">
    Contact
  </div>
  <div class="d-flex align-items-center">
    <img class="mr-2 cursor-pointer" src="assets/ico_msg.svg" alt="">
    <img class="ml-1 cursor-pointer" src="assets/ico_mail.svg" alt="">
  </div>
</ng-template>

<!-- Mentee section template -->
<ng-template #menteeSection>
  <div class="bold-title">
    Current Mentees
  </div>
  <div class="icons-container">
    <ng-container *ngFor="let mentee of mentor?.mentees | slice: 0:3">
      <img class="icon"
        [src]="mentee.imageUrl ? mentee.imageUrl : 'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/'+mentee.firstName.toLowerCase().charAt(0)+'.png'"
        [title]="mentee.firstName + ' ' + mentee.lastName" />

    </ng-container>
    <div *ngIf="mentor.mentees && mentor.mentees.length > 3" class="icon-default" (mouseleave)="closePopover()">
      <span [ngbPopover]="menteeListViewerTemplate" #menteeList="ngbPopover" (mouseenter)="openPopover(menteeList)"
        [placement]="['bottom', 'top']" triggers="manual" popoverClass="custom-popover" class="cursor-default">
        +{{ mentor.mentees.length - 3}}
      </span>
    </div>
  </div>
</ng-template>


<ng-template #programListViewerTemplate>
  <div class="selected-items-popover-item text-primary weight-400 size-12 cursor-pointer ellipsis"
    *ngFor="let program of mentor.programs | slice: 2" (click)="viewProgram(program.program)">
    {{ program.program.programName }}
  </div>
</ng-template>

<ng-template #menteeListViewerTemplate>
  <div class="selected-items-popover-item ellipsis" *ngFor="let mentee of mentor.mentees | slice: 3">
    {{ mentee.firstName + ' ' + mentee.lastName }}
  </div>
</ng-template>