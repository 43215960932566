import { MentorService } from './../../../core/services/mentor.service';
import { TaskController } from './../../../core/controllers/task.controller';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CreateMilestonePopupComponent } from './../../components/create-milestone-popup/create-milestone-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { APIService } from 'src/app/API.service';
import * as _ from 'lodash';
import { MenteeController } from 'src/app/core/controllers/mentee.controller';
import { MenteeApplicationStatus, MENTEE_ID } from 'src/app/core/constants';
import { Program } from 'src/app/models/program';
import { ProgramController } from 'src/app/core/controllers/program.controller';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lfx-task-management',
  templateUrl: './task-management.component.html',
  styleUrls: ['./task-management.component.scss']
})
export class TaskManagementComponent implements OnInit {
  milestones = new Array();

  todo = new Array<any>();
  doing = new Array<any>();
  done = new Array<any>();

  breadcrumbs = [
    {
      name: "programs",
      url: '/programs'
    },
    {
      name: "Milestone",
      url: ''
    }
  ];
  isLoading: boolean = false;
  constructor(
    private modalService: NgbModal,
    private api: APIService,
    private taskController: TaskController,
  ) { }

  ngOnInit() {
    this.getAllMilestones();
  }

  private getAllMilestones() {
    this.taskController.getAllMilestones().subscribe(res => {
      const { items } = res;
      this.milestones = (items as Array<any>).map(milestone => {
        milestone.dueDate = new Date(milestone.dueDate);
        milestone.program.mentees = milestone.program.mentees.items.filter((mentee: any) => mentee.applicationStatus === MenteeApplicationStatus.accepted).map((mentee: any) => mentee.mentee);
        milestone.milestoneTasks = milestone.tasks?.items.map((task: any) => {
          task.dueDate = new Date(task.dueDate);
          task.assignedToMentees.items = task.assignedToMentees.items.map((mentee: any) => {
            delete mentee.mentee.id;
            return { ...mentee, ...mentee.mentee }
          });
          return task;
        });
        return milestone;
      });
      this.initBoard();
    });
  }


  private initBoard() {
    this.todo = [];
    this.doing = [];
    this.done = [];
    this.milestones.forEach(milestone => {
      milestone.completedCount = this.countCompletedTasks(milestone);
      switch (milestone.status) {
        case 'todo':
          this.todo.push(milestone);
          break;

        case 'doing':
          this.doing.push(milestone);
          break;

        case 'done':
          this.done.push(milestone);
          break;
      }
    })
  }
  private countCompletedTasks(milestone: any) {
    if (milestone.milestoneTasks && milestone.milestoneTasks.length) {
      return milestone.milestoneTasks?.filter((task: any) => task?.status === 'completed').length;
    }
    return 0;
  }

  createNewMilestone() {
    const dialogRef = this.modalService.open(CreateMilestonePopupComponent, {
      size: "xl",
      centered: true
    });

    dialogRef.closed.subscribe(milestone => {
      milestone.milestoneTasks = [];
      this.milestones.push(milestone);
      this.initBoard();
    })
  }


  drop(event: CdkDragDrop<any[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      const milestone = _.cloneDeep(event.previousContainer.data[event.previousIndex]);
      this.updateMilestoneStatus(milestone, event.container.id);
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  private async updateMilestoneStatus(milestone: any, newStatus: string) {
    milestone.status = newStatus;
    milestone.dueDate = milestone.dueDate.getTime();
    // delete milestone.program;
    // delete milestone.milestoneTasks;
    const request = {
      id: milestone.id,
      status: newStatus
    };
    await this.api.UpdateMilestone(request);
  }

}
