<div class="modal-header align-items-center">
  <h4 class="gray-1 weight-600 size-20" id="modal-basic-title">Milestone: {{milestone.milestoneName}}</h4>
  <i class="fa fa-times close-btn" aria-hidden="true" (click)="activeModal.dismiss('Cross click')"></i>
</div>
<div class="modal-body flex-unset d-flex flex-column p-0">
  <div class="milestone-details-row d-flex justify-content-between w-100">
    <div class="d-flex align-items-center">
      <span class="info-text weight-600 size-14">#Task: {{' ' + milestone.milestoneTasks?.length}}</span>
      <span class="info-text weight-600 size-14">Created: {{' '+ milestone.createdAt | date : 'dd-MM-yyyy'}}</span>
      <span class="info-text weight-600 size-14">Due Date: {{' '+ milestone.dueDate | date : 'dd-MM-yyyy'}}</span>
    </div>
    <div class="d-flex align-items-center pr-1">
      <button type="button" class="btn btn-outline-primary mr-2">Clone and Edit</button>
      <button type="button" class="btn btn-outline-primary mr-3">Share</button>
    </div>
  </div>
  <div class="d-flex content-container">
    <lfx-create-new-task class="col-4 px-0" [milestone]="milestone"></lfx-create-new-task>
    <div class="milestone-content d-flex flex-column col-8">
      <span class="text-dark weight-600 size-18 mb-3">Milestone Description <i
          class="fas fa-pencil-alt p-2 text-primary" (click)="editDescription()"></i></span>
      <quill-view-html *ngIf="!isEditMilestone" class="d-flex text-gray-200 mb-4-2" [content]="milestone.description"
        theme="snow">
      </quill-view-html>
      <quill-editor *ngIf="isEditMilestone" [(ngModel)]="updatedDescription"
        [styles]="{height: '150px', color: '#000', fontSize: '14px', fontFamily: 'Open Sans'}"
        name="milestone-description" id="milestone-description">
        <div quill-editor-toolbar>
          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
          </span>
          <span class="ql-formats">
            <select class="ql-align">
              <option selected></option>
              <option value="center"></option>
              <option value="right"></option>
              <option value="justify"></option>
            </select>
          </span>
          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
          </span>
          <span class="ql-formats">
            <button class="ql-link"></button>
          </span>
        </div>
      </quill-editor>
      <div class="actions d-flex justify-content-end my-3" *ngIf="isEditMilestone">
        <button type="button" class="btn btn-primary" [disabled]="!updatedDescription.length"
          (click)="updateMilestoneDescription()">Update</button>
      </div>
      <span class="text-dark weight-600 size-18 mb-3">Task Details</span>
      <ng-container *ngIf="!milestone.milestoneTasks?.length; else milestoneTasksTemplate">
        <div class="d-flex w-100 px-3 py-2 task-container">
          Create Task Using Task Actions
        </div>
      </ng-container>
      <ng-container *ngTemplateOutlet="milestoneTasksSubmissionsTemplate"></ng-container>
      <ng-container *ngIf="!generalView" [ngTemplateOutlet]="milestoneTasksEvaluationTemplate"></ng-container>

    </div>
  </div>
</div>

<ng-template #milestoneTasksTemplate>
  <ngb-accordion [closeOthers]="true">
    <ngb-panel [id]="'task-panel-'+task.id" *ngFor="let task of milestone.milestoneTasks;let rowIndex = index">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="panel-header">
          <div class="DoneTasks" *ngIf="done || generalView">
            <input type="checkbox" [id]="'txt-taskName'+rowIndex" class="chkBox" *ngIf="!generalView"
              (click)="readOnlyCheckBox()" checked>
            <span class="size-14 weight-600 gray-1 taskName" [ngClass]="{'strikeThrough': done}"
              [id]="'taskName'+rowIndex">
              {{task.taskName}}
            </span>
          </div>

          <div class="DoneTasks" *ngIf="!done && !generalView">
            <div *ngIf="task.taskDetail.status == taskStatus.completed">
              <input type="checkbox" [id]="'txt-taskName'+rowIndex" class="chkBoxes" *ngIf="!generalView"
                (click)="readOnlyCheckBox()" checked> &nbsp;
              <span class="size-14 weight-600 gray-1 taskName strikeThrough" [id]="'taskName'+rowIndex">
                {{task.taskName}}
              </span>
            </div>
            <div *ngIf="task.taskDetail.status !== taskStatus.completed">
              <input type="checkbox" [id]="'txt-taskName'+rowIndex" class="chkBoxes" *ngIf="!generalView"
                (click)="updateTaskCompleted(rowIndex, task)" (change)="$event ? selection.toggle(task) : null"
                [checked]="selection.isSelected(task)"> &nbsp;
              <span class="size-14 weight-600 gray-1 taskName" [id]="'taskName'+rowIndex">
                {{task.taskName}}
              </span>
            </div>
          </div>

          <span class="due-date weight-600 size-12">
            <i class="far fa-clock"></i>
            {{'Due Date '+ (task.dueDate | date : 'dd-MM-yyyy')}}
          </span>

          <img *ngIf="!generalView"
            [src]="currentMentee.imageUrl ? currentMentee.imageUrl: 'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/'+currentMentee.firstName.toLowerCase().charAt(0)+'.png'"
            [title]="currentMentee.firstName + ' ' + currentMentee.lastName" alt="" class="mentee-image"
            [ngClass]="{'completed-mentee': task.taskDetail.status === taskStatus.completed}">

          <button class="btn btn-link p-0 gray-1" ngbPanelToggle>
            <i class="fas fa-chevron-right" *ngIf="!opened"></i>
            <i class="fas fa-chevron-up" *ngIf="opened"></i>
          </button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="d-flex w-100 task" *ngIf="generalView">
          <div class="d-flex flex-column col-7">
            <div class="section-header text-dark size-16 weight-600 my-3">
              Description
            </div>
            <quill-view-html class="d-flex w-100" [content]="task.description" theme="snow">
            </quill-view-html>
          </div>
          <div class="assign-mentees card col-5 px-0">
            <span class="card-header text-dark weight-600 size-14">
              Assigned Mentees
            </span>
            <div class="card-body mentees-container">
              <img *ngFor="let mentee of task.assignedToMentees.items"
                [src]="mentee.imageUrl ? mentee.imageUrl: 'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/'+mentee.firstName.toLowerCase().charAt(0)+'.png'"
                [title]="mentee.firstName + ' ' + mentee.lastName" alt="" class="mentee-image"
                [ngClass]="{'completed-mentee': mentee.status === taskStatus.completed}">
            </div>
          </div>
        </div>
        <div class="d-flex flex-column w-100 task" *ngIf="!generalView">

          <div [id]="'lfx-pre-panel-'+rowIndex" class="max-height" [ngClass]="{'active': activeIndex === rowIndex }">
            <div class="section-header text-dark size-16 weight-600 my-3">
              Description
            </div>
            <quill-view-html class="d-flex pl-3" [content]="task.description" theme="snow"></quill-view-html>
            <div class="preview" [id]="'txt-read'+ rowIndex"></div>
            <div class="section-header text-dark size-16 weight-600 my-3">
              Comments
            </div>
            <div class="comments-section my-4 py-2" *ngIf="task.taskDetail.comments.length">
              <div class="comments-body text-dark size-14 px-3 py-2 d-flex flex-column"
                *ngFor="let comment of task.taskDetail.comments">
                <div class="d-flex align-items-center">
                  <img class="actor-image"
                    [src]="comment?.actor?.image ? comment?.actor?.image :
                  'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/'+comment?.actor?.name.toLowerCase().charAt(0)+'.png'">
                  <span class="text-primary weight-500">{{comment?.actor?.name}}</span>
                  <span class="date-text size-12 weight-600">
                    <i class="far fa-clock mr-2"></i>
                    {{comment.createdAt | timeago: 'live'}}
                  </span>
                </div>
                <span class="comment-text mt-3">
                  {{comment.commentText}}
                </span>
              </div>
            </div>
            <div class="d-flex flex-column my-2">
              <textarea [id]="'txt-input-task'+ rowIndex" rows="4" class="form-control mb-3 mt-3 w-100"
                [placeholder]="'start typing for reply'" #commentInput></textarea>
              <div class="d-flex w-100 justify-content-end">
                <div class="d-flex">
                  <button type="button" class="btn btn-outline-primary py-1 px-3 mx-1 ml-2">Cancel</button>
                  <button type="button" class="btn btn-primary py-1 px-3 mx-1 ml-2"
                    (click)="addComment(task, commentInput.value)">Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</ng-template>

<ng-template #milestoneTasksSubmissionsTemplate>
  <ngb-accordion class="mt-auto submission-activity">
    <ngb-panel>
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="panel-header">
          <span class="size-14 weight-600 gray-1">
            Submission Activity
          </span>
          <button class="btn btn-link p-0 gray-1" ngbPanelToggle>
            <i class="fas fa-chevron-right" *ngIf="!opened"></i>
            <i class="fas fa-chevron-up" *ngIf="opened"></i>
          </button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <!-- <div class="d-flex flex-column w-100 task">
          <span class="text-dark size-14 weight-600 mb-2">Yesterday</span>
          <div class="activity d-flex align-items-center w-100 mt-1 mb-3" *ngFor="let activity of activities">
            <img
              [src]="activity.actionBy.imageUrl ? activity.actionBy.imageUrl:
            'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/'+activity.actionBy.firstName.toLowerCase().charAt(0)+'.png'">
            <div class="d-flex flex-column flex-grow-1">
              <div class="d-flex align-items-center">
                <span class="date-text size-12 weight-600">
                  <i class="far fa-calendar-alt mr-2"></i>
                  {{activity.actionDate | date : 'd-M-yyyy'}}
                </span>
                <span class="date-text size-12 weight-600">
                  <i class="far fa-clock mr-2"></i>
                  {{getHoursFrom(activity.actionDate) + ' hrs'}}
                </span>
              </div>
              <span class="text-dark size-12 weight-600">
                {{activity.action.name+' "'}}
                <span class="text-primary">{{activity.action.taskName}}</span>
                {{'" '+activity.action.description}}
              </span>
            </div>
          </div>
        </div> -->
        <div class="d-flex flex-column w-100 task">
          <span class="text-dark size-14 weight-600 mb-2">Yesterday</span>
          <div class="activity d-flex align-items-center w-100 mt-1 mb-3" *ngFor="let activity of submissionActivities">

            <img [src]="activity.actor.image ? activity.actor.image :
            'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/'+activity.actor.name.toLowerCase().charAt(0)+'.png'">
            <div class="d-flex flex-column flex-grow-1">
              <div class="d-flex align-items-center">
                <span class="date-text size-12 weight-600">
                  <i class="far fa-calendar-alt mr-2"></i>
                  {{activity.createdAt | date : 'd-M-yyyy'}}
                </span>
                <span class="date-text size-12 weight-600">
                  <i class="far fa-clock mr-2"></i>
                  {{activity.createdAt | timeago: 'live'}}
                </span>
              </div>
              <span class="text-dark size-12 weight-600">
                {{activity.actionType}}ed
                <span class="text-primary">
                  {{activity.menteeTask.task.taskName}}
                </span>
                {{activity.action}}
              </span>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</ng-template>

<ng-template #milestoneTasksEvaluationTemplate>
  <ngb-accordion class="evaluation">
    <ngb-panel>
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="panel-header">
          <span class="size-14 weight-600 gray-1">
            Evaluation
          </span>
          <button class="btn btn-link p-0 gray-1" ngbPanelToggle>
            <i class="fas fa-chevron-right" *ngIf="!opened"></i>
            <i class="fas fa-chevron-up" *ngIf="opened"></i>
          </button>

        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="d-flex">
          <span class="size-14 text-dark weight-600">Evaluation Status: </span>
          <div class="slider-toggle">
            <span class="pl-2 size-16 evaluationStatus">Fail</span>
            <span class="pl-1 pr-1">
              <label class="switch">
                <input type="checkbox" (click)="acceptOrDecline()" [(ngModel)]="checkBoxValue"
                  [ngModelOptions]="{standalone: true}" checked>
                <span class="slider round"></span>
              </label>
            </span>
            <span class="size-16 evaluationStatus">Pass</span>
          </div>
        </div>
        <div class="my-2">
          <span class="size-14 text-dark weight-600">Add Mentors feedback to Program Admin here</span>
          <textarea [id]="'txt-input-task'" rows="4" class="form-control mb-3 mt-3 w-100 txtArea"
            [placeholder]="'Give Feedback'" [(ngModel)]="feedbackForAdmin">
            <i class="fa fa-paperclip" aria-hidden="true"></i>
          </textarea>
        </div>
        <div class="d-flex align-items-end justify-content-end pr-1">
          <button type="button" class="btn btn-outline-primary mr-2 btnCancel">Cancel</button>
          <button type="button" class="btn btn-primary mr-3" *ngIf="done" disabled>Submit Evaluation</button>
          <button type="button" class="btn btn-primary mr-3" *ngIf="!done"
            (click)="UpdateMilestoneStatusForMentee(milestone.milestoneTasks)">Submit Evaluation</button>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</ng-template>