import { ProgramController } from './../../../core/controllers/program.controller';
import { ProjectController } from 'src/app/core/controllers/project.controller';
import { APIService } from 'src/app/API.service';
import { ProgramService } from './../../../core/services/program.service';
import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Utilities } from 'src/app/core/utilities';
import { Program } from 'src/app/models/program';
import { TableColumnConfig } from 'src/app/core/models/table-column-config';
import { TableConfig } from 'src/app/core/models/table-config';
import * as _ from 'lodash';
import { Term } from 'src/app/models/term';

@Component({
  selector: 'lfx-program-overview',
  templateUrl: './program-overview.component.html',
  styleUrls: ['./program-overview.component.scss'],
})
export class ProgramOverviewComponent implements OnInit, AfterViewInit {

  @Input() program!: Program;
  @Input() withBreadcrumbs = true;
  @Output() editProgramTab = new EventEmitter();
  @ViewChild('termNameTemplate') termNameTemplate!: TemplateRef<any>;
  @ViewChild('dateTemplate') dateTemplate!: TemplateRef<any>;
  @ViewChild('usernameTemplate') usernameTemplate!: TemplateRef<any>;
  @ViewChild('statusTemplate') statusTemplate!: TemplateRef<any>;
  @ViewChild('actionsTemplate') actionsTemplate!: TemplateRef<any>;
  @ViewChild('contactTemplate') contactTemplate!: TemplateRef<any>;
  @ViewChild('descriptionTemplate') descriptionTemplate!: TemplateRef<any>;
  @ViewChild('prerequisitesActionsTemplate') prerequisitesActionsTemplate!: TemplateRef<any>;
  availableTerms = new Array<Term>();
  pastTerms = new Array<Term>();
  utilities = new Utilities();

  config: TableConfig = {
    xPadding: '30px',
    colXPadding: '20px',
    columns: new Array<TableColumnConfig>()
  };
  termConfig!: TableConfig;
  adminConfig!: TableConfig;
  mentorConfig!: TableConfig;
  prerequisitesConfig!: TableConfig;
  showAllLearnObjectives = false;
  isLoading = false;
  isExistProgram = false;
  activeTermTab = 'availableTerms';

  breadcrumbs = [
    {
      name: "programs",
      url: '/programs'
    },
    {
      name: "program overview",
      url: ''
    }
  ];

  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private programService: ProgramService,
    private api: APIService,
    private projectController: ProjectController,
    private programController: ProgramController
  ) { }
  ngOnInit() {
    const programID = this.activatedRoute.snapshot.paramMap.get('programId');
    if (programID) {
      this.getProgram(programID);
      this.isExistProgram = true;
    } else if (this.programService.program) {
      this.isExistProgram = false;
      this.program = this.programService.program;
    } else if (this.program) {
      this.isExistProgram = false;
    }
  }

  getProgram(programId: string) {
    this.isLoading = true;
    this.programController.getProgramByIdGraphql(programId).subscribe((program: any) => {
      this.program = program;
      this.program.project = this.projectController.getProjectById(program.projectID);
      this.filterTerms();
      if (this.program.projectID && !this.program.project) {
        this.projectController.getAllProjectsByIDs([this.program.projectID]).subscribe(res => {
          this.program.project = res[0];
          this.isLoading = false;
        })
      } else {
        this.isLoading = false;
      }
    }, error => {
      this.isLoading = false;
    });
  }

  filterTerms() {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    this.program.terms.forEach(term => {
      if (this.utilities.isDateInRange(term.applicationAcceptanceStartDate, term.termEndDate, today)) {
        this.availableTerms.push(term);
      } else {
        this.pastTerms.push(term);
      }
    });
  }

  changeTermTab(tab: string) {
    if (tab === this.activeTermTab) {
      return;
    }
    this.activeTermTab = tab;
    this.program.terms = tab === 'availableTerms' ? this.availableTerms : this.pastTerms;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.fillTermsConfig();
      this.fillAdminsConfig();
      this.fillMentorsConfig();
      this.fillPrerequisitesConfig();
    }, 0);
  }

  private fillTermsConfig() {
    this.termConfig = Object.assign({}, this.config);
    this.termConfig.columns = [
      {
        title: 'Term Name',
        key: 'termName',
        width: '31%',
        template: this.termNameTemplate,
      },
      {
        title: 'Start Date-End Date',
        key: 'term',
        width: '34%',
        template: this.dateTemplate,
      },
      {
        title: 'Application Acceptance Period',
        key: 'applicationAcceptance',
        width: '35%',
        template: this.dateTemplate,
      }
    ];
  }

  private fillAdminsConfig() {
    this.adminConfig = Object.assign({}, this.config);
    this.adminConfig.columns = [
      {
        title: 'Name',
        key: 'name',
        width: '33%',
        template: this.usernameTemplate,
      },
      {
        title: 'Role',
        key: 'role',
        width: '26%',
        template: this.termNameTemplate,
      },
      {
        title: 'Invitation Status',
        key: 'invitationStatus',
        width: '26%',
        template: this.statusTemplate,
      },
      {
        title: 'Contact',
        isCenter: true,
        key: 'Admin',
        width: '15%',
        template: this.contactTemplate,
      },
    ];
  }

  private fillMentorsConfig() {
    this.mentorConfig = Object.assign({}, this.config);
    this.mentorConfig.columns = [
      {
        title: 'Name',
        key: 'name',
        width: '33%',
        template: this.usernameTemplate,
      },
      {
        title: 'Role',
        key: 'role',
        width: '26%',
        template: this.termNameTemplate,
      },
      {
        title: 'Invitation Status',
        key: 'invitationStatus',
        width: '26%',
        template: this.statusTemplate,
      },
      {
        title: 'Contact',
        isCenter: true,
        key: 'Mentor',
        width: '15%',
        template: this.contactTemplate,
      },
    ];
  }
  private fillPrerequisitesConfig() {
    this.prerequisitesConfig = Object.assign({}, this.config);
    this.prerequisitesConfig.columns = [
      {
        title: 'Prerequisites Name',
        key: 'prerequisiteName',
        width: '41%',
        template: this.termNameTemplate,
      },
      {
        title: 'Description',
        key: 'description',
        width: '59%',
        template: this.descriptionTemplate,
      },
      // {
      //   title: 'Actions',
      //   isCenter: true,
      //   key: 'action',
      //   width: '10%',
      //   template: this.prerequisitesActionsTemplate,
      // },
    ];
  }

  scrollToElement(elementId: string) {
    const elementRef = document.getElementById(elementId) as HTMLElement;
    if (elementRef) {
      elementRef.scrollIntoView({ behavior: 'smooth' });
    }
  }

  BackToHome() {
    this.route.navigate(['/']);
  }

  editProgram(tabIndex: number) {
    if (this.isExistProgram) {
      this.programService.program = this.program;
      this.route.navigate(['/program/update', this.program.id], { queryParams: { tabIndex } });
    } else {
      this.editProgramTab.emit(tabIndex);
    }
  }
}
