import { Utilities } from 'src/app/core/utilities';
import { Component, Input, OnInit } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { MenteeApplicationStatus, ProgramStatus } from 'src/app/core/constants';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Program } from 'src/app/models/program';

@Component({
  selector: 'lfx-program-detail-card',
  templateUrl: './program-detail-card.component.html',
  styleUrls: ['./program-detail-card.component.scss']
})
export class ProgramDetailCardComponent implements OnInit {

  @Input() program!: Program;
  programMentees = new Array<any>();
  activePopover!: NgbPopover;
  programStatus = ProgramStatus;
  utilities = new Utilities();
  constructor(private route: Router) { }

  ngOnInit() {
    setTimeout(() => {
      this.programMentees = _.cloneDeep(this.program.mentees?.filter((mentee: any) => mentee.applicationStatus === MenteeApplicationStatus.accepted)) as any;
    }, 100);
  }

  navigateToProgramDetail() {
    this.route.navigateByUrl('program/overview/' + this.program.id);
  }

  openPopover(popover: NgbPopover) {
    this.activePopover = popover;
    if (this.activePopover && !this.activePopover.isOpen()) {
      this.activePopover.open();
    }
  }

  closePopover() {
    if (this.activePopover && this.activePopover.isOpen()) {
      this.activePopover.close();
    }
  }


}
