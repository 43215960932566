import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, TemplateRef, OnChanges, SimpleChanges } from '@angular/core';
import { fadeInTop, fadeOutTop } from 'src/app/global-animations';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'lfx-auto-complete-dropdown',
  templateUrl: './auto-complete-dropdown.component.html',
  styleUrls: ['./auto-complete-dropdown.component.scss'],
  animations: [
    fadeInTop,
    fadeOutTop
  ]
})
export class AutoCompleteDropdownComponent implements OnInit, OnChanges {

  @Input() items!: Array<any>;
  @Input() dropdownPlaceholder: string = '';
  @Input() dropDownId: string = '';
  @Input() isDisabled: boolean = false;
  @Input() template: TemplateRef<any> | undefined;
  @Input() headerHeight = '40px';
  @Input() parentForm!: FormGroup;
  @Input() controlName: string = '';
  @Input() searchFieldName: string = '';
  @Input() showSearchIcon = false;
  @Input() searchFun!: (filterText: string) => Observable<any>;
  @ViewChild('searchInput') searchInput!: ElementRef;

  noDataFound: boolean = false;
  isDropdownOpened: boolean = false;
  searchText = '';
  dropDownPosition: string = '';
  viewItems = new Array();
  isLoading = false;

  get selectedItem() {
    if (this.parentForm.get(this.controlName)) {
      return this.parentForm.controls[this.controlName].value ? this.parentForm.controls[this.controlName].value : undefined;
    } else {
      return undefined;
    }
  }

  searchInputChange = new Subject();
  constructor() {
  }

  ngOnInit() {
    if (!this.items) {
      this.items = [];
    }
    this.isDropdownOpened = false;
    this.searchInputChange.pipe(debounceTime(500)).subscribe(() => {
      this.applyFilter(this.searchText);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.items) {
      this.viewItems = _.cloneDeep(this.items);
    }
  }

  openDropDown() {
    if (this.isDisabled) {
      return;
    }
    this.isDropdownOpened = true;
    this.focusOnSearchArea();
  }

  selectItem(item?: any) {
    if (item) {
      this.parentForm.controls[this.controlName].setValue(item);
    } else {
      this.parentForm.controls[this.controlName].setValue('');
    }
    this.searchText = '';
    this.viewItems = _.cloneDeep(this.items);
    setTimeout(() => {
      this.isDropdownOpened = false;
    }, 0);
  }

  /**
   * @description function that used to check user click outside of dropdown to close dropdown
   * @param $event action on windows
   */
  onClickOutSideDropdown($event: any) {
    if ($event.path) {
      if ($event.path[0].className === 'add-new-element-text' ||
        $event.path[0].className === 'close-icon-container' ||
        $event.target.className === 'dropdown-icon') {
        return;
      }
    } else {
      if ($event.target.className === 'add-new-element-text' ||
        $event.target.className === 'close-icon-container' ||
        $event.target.className === 'dropdown-icon') {
        return;
      }
    }
    if (this.dropDownId) {
      if ($event.target['id'] === this.dropDownId || ($event.target['parentElement'] && $event.target['parentElement']['id'] === this.dropDownId) || ($event.target['parentElement'] && $event.target['parentElement']['parentElement'] && $event.target['parentElement']['parentElement']['id'] === this.dropDownId)) {
        return;
      }
    }
    this.isDropdownOpened = false;
    this.viewItems = _.cloneDeep(this.items);
    this.searchText = '';
    this.noDataFound = false;
  }

  /**
   * @description function that used to check postion of dropdown and get open up or down
   * @param event windows event
   */
  getDropDownPosition(event: any) {
    const windowsHeight = window.innerHeight;
    const dropdownY = event.clientY;
    const dropdownHeight = 230;
    const differentHeight = windowsHeight - dropdownY;
    if (differentHeight < dropdownHeight) {
      this.dropDownPosition = 'up';
    } else {
      this.dropDownPosition = 'down';
    }

  }
  /**
   * @description function that used to focus on search input in dropdown
   */
  focusOnSearchArea() {
    setTimeout(() => {
      if (this.searchInput) {
        this.searchInput.nativeElement.focus();
      }
    }, 10);
  }

  /**
   * @description function that used to search in dropdown list
   * @param searchText search value
   */
  private applyFilter(searchText: string) {
    let items = [];
    if (this.searchFun) {
      this.isLoading = true;
      this.searchFun(searchText).subscribe((res) => {
        this.isLoading = false;
        items = res;
        this.viewItems = _.cloneDeep(items);
        if (items.length === 0) {
          this.noDataFound = true;
        } else {
          this.noDataFound = false;
        }
      });
      return;
    }
    if (this.searchFieldName) {
      items = this.items.filter(item => item[this.searchFieldName].toLowerCase().indexOf(searchText.toLowerCase()) > -1);
    } else {
      items = this.items.filter(item => item.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
    }
    this.viewItems = _.cloneDeep(items);
    if (items.length === 0) {
      this.noDataFound = true;
    } else {
      this.noDataFound = false;
    }
  }

}
