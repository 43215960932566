<div class="mentorship-card-container">
  <div class="mentorship-card-header">
    <span class="mentorship-name ellipsis">{{ mentorship.name }}</span>
    <span class="mentorship-creation-date">
      <i class="far fa-calendar-alt"></i>
      {{ mentorship.creationDate | date: "EEEE, MMM dd" }}
    </span>
  </div>
  <span class="mentorship-description">
    {{ mentorship.description }}
  </span>
  <button class="donate-btn">Donate</button>
</div>