<div class="dashboard-container px-80">
  <lfx-breadcrumbs class="pl-2" [breadcrumbs]="breadcrumbs"></lfx-breadcrumbs>
  <div class="d-flex align-items-center px-3 flex-column-sm">
    <lfx-card-number-view class="col-lg-3 col-sm-12 flex-unset mb-3 mr-3 px-0" [cardValue]="20"
      [cardText]="'Ongoing Mentorships'">
    </lfx-card-number-view>
    <lfx-card-number-view class="col-lg-3 col-sm-12 flex-unset mb-3 mr-3 px-0" [cardValue]="46"
      [cardText]="'Completed Mentorships'">
    </lfx-card-number-view>
    <lfx-card-number-view class="col-lg-3 col-sm-12 flex-unset mb-3 mr-3 px-0" [cardValue]="26"
      [cardText]="'Participating Mentees'">
    </lfx-card-number-view>
    <lfx-card-number-view class="col-lg-3 col-sm-12 flex-unset mb-3 px-0" [cardValue]="05"
      [cardText]="'Participating Mentors'">
    </lfx-card-number-view>
  </div>
  <div class="d-flex dashboard-content h-100">

    <div class="h-100 col-lg-9 col-sm-12">
      <div class="program-table card w-100">
        <div class="card-header d-flex align-items-center justify-content-between">
          <span class="card-header-title size-18 weight-600">My Mentorships</span>
          <div class="actions">
            <button type="button" class="btn btn-outline-primary px-3 py-1 mr-2">Propose a Program</button>
            <button type="button" class="btn btn-outline-primary px-3 py-1" (click)="viewAllProgram()">View All</button>
          </div>
        </div>
        <div class="card-body">
          <app-common-table [tableConfiguration]="tableConfig" [tableRecords]="myPrograms"></app-common-table>
        </div>
      </div>
      <div class="card w-100 h-40 mt-3">
        <div class="card-header d-flex align-items-center justify-content-between">
          <span class="card-header-title size-18 weight-600">Review Assigned Applications</span>
        </div>
        <div class="card-body">
          <app-common-table [tableConfiguration]="applicationsTableConfig" [tableRecords]="mentorReview">
          </app-common-table>
        </div>
      </div>
      <div class="spacer"></div>
    </div>

    <div class="col-lg-3 col-sm-12">
      <div class="other-mentorships-container">
        <div class="other-header">Other Mentorships</div>
        <app-mentorship-dashboard-card *ngFor="let mentorship of otherMentorships" [mentorship]="mentorship">
        </app-mentorship-dashboard-card>
      </div>
      <app-image-carousel></app-image-carousel>
    </div>
  </div>
</div>

<ng-template #projectNameTemplate let-element>
  <span class="text-black weight-600">
    {{element.rowData?.program?.project?.projectName}}
  </span>
</ng-template>

<ng-template #programTemplate let-element>
  <span class="d-flex align-items-center w-100 mw-100 cursor-pointer">
    <span class="text-primary position-relative" (click)="previewProject(element.rowData)">
      {{element.rowData?.program?.programName}}
      <i class="far fa-check-circle position-absolute text-success" ngbPopover="You have accept the invitation"
        container="body" triggers="mouseenter:mouseleave"
        *ngIf="element.rowData?.invitationStatus?.toLowerCase() === programStatus.accepted"></i>
      <i class="far fa-question-circle position-absolute text-secondary"
        ngbPopover="You haven’t responded to your invitation, and their availability to respond" container="body"
        triggers="mouseenter:mouseleave"
        *ngIf="element.rowData?.invitationStatus?.toLowerCase() === programStatus.pending"></i>
    </span>
  </span>
</ng-template>

<ng-template #programTemplateMentee let-element>
  <span class="d-flex align-items-center w-100 mw-100 cursor-pointer">
    <span class="text-primary position-relative"
      (click)="openProgramOverview(element.rowData?.menteeApplication?.programID)">
      {{element.rowData?.menteeApplication?.program?.programName}}
    </span>
  </span>
</ng-template>

<ng-template #statusTemplate let-element>
  <span class="status mw-100 w-100 d-flex align-items-center justify-content-center" lfxEllipsis>
    {{element.rowData?.program?.status | titlecase}}
  </span>
</ng-template>

<ng-template #usernameTemplate let-element>
  <div class="d-flex align-items-center w-100">
    <img class="user-image mr-4" style="height: 25px;width: 25px;"
      [src]="element.rowData.menteeApplication.mentee.imageUrl ? element.rowData.menteeApplication.mentee.imageUrl : 'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/'+element.rowData?.menteeApplication.mentee?.firstName?.toLowerCase().charAt(0)+'.png'"
      alt="">
    <span class="user-name text-primary" lfxEllipsis>
      {{element.rowData.menteeApplication.mentee.firstName + ' ' +
      element.rowData.menteeApplication.mentee.lastName}}</span>
  </div>
</ng-template>

<ng-template #dateTemplate let-element>
  <span class="test-dark mw-100 w-100 d-flex justify-content-center" lfxEllipsis>
    {{element.rowData.menteeApplication?.applicationDate | date}}
  </span>
</ng-template>

<ng-template #actionsTemplate let-element>
  <div class="d-flex justify-content-center align-items-center w-100">
    <button type="button" class="btn btn-outline-primary px-3 py-1"
      (click)="reviewApplication(element.rowData)">Review</button>
  </div>
</ng-template>