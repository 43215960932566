<ng-container *ngIf="!isLoading else loadingTemplate">
  <div class="program-application-container px-80">
    <lfx-breadcrumbs [breadcrumbs]="breadcrumbs"></lfx-breadcrumbs>
    <lfx-program-overview-header [program]="program"></lfx-program-overview-header>

    <div class="card flex-grow-1 mb-5">
      <div class="card-header d-flex pl-0 pb-0">
        <div class="tab" (click)="ShowTabs('applicationList')" [ngClass]="{'active': activeTabIndex === 1}">
          Application List
        </div>
        <div class="tab" (click)="ShowTabs('acceptedMentee')" [ngClass]="{'active': activeTabIndex === 2}">
          Accepted Mentee
        </div>
        <div class="tab-spacer"></div>
        <div class="actions">
          <lfx-input-search [filterFun]="filterFun" class="mr-3" (filter)="applyNameFilter($event)"
            [template]="usernameTemplate"></lfx-input-search>
          <button type="button" class="btn btn-primary default-border-radius mr-3" (click)="openFilterDialog()">
            <i class="fa fa-filter" aria-hidden="true"></i>
          </button>
          <button type="button" class="btn btn-primary custom-btn" (click)="contactAll()">Contact All</button>
        </div>
      </div>
      <div class="card-body" *ngIf="activeTabIndex === 1">
        <ng-container *ngIf="!isLoadMore; else loadingTemplate">
          <div class="selection-row d-flex align-items-center mb-3-2">
            <div class="selected-elements size-14">
              <span class="text-dark-gray weight-600 mr-2">{{selection.selected?.length}}</span>
              Mentees are selected
            </div>
            <div class="selection-actions d-flex flex-grow-1 align-items-center">
              <button type="button" class="btn btn-outline-primary py-1 px-3 mx-3" [disabled]="!selection.hasValue()"
                (click)="selection.hasValue() ? declineMentees(): false">
                Decline Selected
              </button>
              <button type="button" class="btn btn-outline-primary py-1 px-3 ml-auto" [disabled]="!selection.hasValue()"
                (click)="selection.hasValue() ? acceptMentees() : false">
                Accept Selected
              </button>
            </div>
          </div>
          <app-common-table class="d-flex flex-grow-1" [tableConfiguration]="tableConfig"
            [tableRecords]="menteeApplicationsPagination.items" (toggleSelection)="toggleSelection()"
            [isAllSelected]="isAllSelected">
          </app-common-table>

          <lfx-pagination [paginationConfig]="menteeApplicationsPagination"
            (paginationChange)="updatePaginationForMenteeApplications()" class="pt-4 mt-auto">
          </lfx-pagination>
        </ng-container>
      </div>
      <div class="card-body" *ngIf="activeTabIndex === 2">
        <ng-container *ngIf="!isLoadMore; else loadingTemplate">

          <app-common-table class="d-flex flex-grow-1" [tableConfiguration]="tableConfig_Mentee"
            [tableRecords]="acceptedMenteesPagination.items" (toggleSelection)="toggleSelection()"
            [isAllSelected]="isAllSelected">
          </app-common-table>

          <lfx-pagination [paginationConfig]="acceptedMenteesPagination"
            (paginationChange)="updatePaginationForAcceptedMentees()" class="pt-4 mt-auto">
          </lfx-pagination>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loadingTemplate>
  <lfx-loading></lfx-loading>
</ng-template>

<ng-template #usernameTemplate let-element>
  <div class="d-flex align-items-center w-100 m-2 mb-0">
    <img class="user-image mr-2 border-radius-25" style="height: 50px;width: 50px;"
      [src]="element.imageUrl ? element.imageUrl : 'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/'+element.firstName.toLowerCase().charAt(0)+'.png'"
      alt="">
    <span class="custom-user-name text-primary cursor-pointer" lfxEllipsis>{{element.firstName + ' ' +
      element.lastName}}</span>
  </div>
</ng-template>


<ng-template #checkboxTemplate let-element>
  <div class="custom-control custom-checkbox py-2">
    <input type="checkbox" class="custom-control-input" [id]="'customCheck1'+element.rowIndex"
      (change)="$event ? selection.toggle(element.rowData) : null" [checked]="selection.isSelected(element.rowData)">
    <label class="custom-control-label cursor-pointer" [for]="'customCheck1'+element.rowIndex"></label>
  </div>
</ng-template>

<ng-template #nameTemplate let-element>
  <div class="d-flex align-items-center w-100">
    <img class="user-image mr-4"
      [src]="element.rowData.mentee.imageUrl ? element.rowData.mentee.imageUrl :'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/'+element.rowData.mentee.firstName.toLowerCase().charAt(0)+'.png'"
      alt="">
    <span class="user-name" lfxEllipsis>{{element.rowData.mentee['firstName'] + ' ' +
      element.rowData.mentee['lastName']}}</span>
  </div>
</ng-template>

<ng-template #dateTemplate let-element>
  <span class="date-text mw-100 w-100 py-2" lfxEllipsis>
    {{element.rowData[element.columnConfig.key] | date:'EEE, MMM d yyyy'}}
  </span>
</ng-template>

<ng-template #statusTemplate let-element>
  <span class="status mw-100 w-100 py-2" lfxEllipsis
    [ngClass]="{
      'pending-status': element.rowData[element.columnConfig.key].toLowerCase() === menteeApplicationStatus.pending.toLowerCase(),
      'accepted-status': element.rowData[element.columnConfig.key].toLowerCase() === menteeApplicationStatus.accepted.toLowerCase(),
      'declined-status': element.rowData[element.columnConfig.key].toLowerCase() === menteeApplicationStatus.declined.toLowerCase()}">
    {{element.rowData[element.columnConfig.key] | titlecase}}
  </span>
</ng-template>

<ng-template #numberOfAppTemplate let-element>
  <span class="text-dark-gray text-center mw-100 w-100 d-flex align-items-center justify-content-center py-2"
    (click)="element.rowData.mentee.otherApplications?.length ? openMenteeOtherApplications(element.rowData.mentee): false"
    [ngClass]="{'text-primary': element.rowData.mentee.otherApplications?.length,'cursor-pointer': element.rowData.mentee.otherApplications?.length}"
    lfxEllipsis>
    {{element.rowData.mentee.otherApplications?.length ? element.rowData.mentee.otherApplications?.length : 'N/A'}}
  </span>
</ng-template>

<ng-template #mentorReviewTemplate let-element>
  <div class="mw-100 w-100 py-2">
    <div *ngIf="element.rowData.mentorReviews.items.length">
      <span class="text-green">
        Yes
      </span>
      <span class="text-dark-gray">
        {{element.rowData.acceptedReviewsCount + '/' + element.rowData.mentorReviews.items.length}}
      </span>
    </div>
    <span *ngIf="!element.rowData.mentorReviews.items.length" class="status pending-status">
      Mentor Not Assigned
    </span>
  </div>
</ng-template>

<ng-template #actionTemplate let-element>
  <div class="w-100 mw-100 d-flex justify-content-center py-2">
    <button type="button" class="btn btn-outline-primary py-1 px-3"
      (click)="navigateToReviewApplication(program, element.rowData.id)">
      Review Application
    </button>
  </div>
</ng-template>

<ng-template #review_progress let-element>
  <div class="w-100 mw-100 d-flex justify-content-center py-2">
    <button type="button" class="btn btn-link py-1 px-3 mr-1"
      (click)="navigateToMenteeProgress(program, element.rowData.id)">
      View
    </button>
  </div>
</ng-template>

<ng-template #actionTemplate_Mentee let-element>
  <div class="w-100 mw-100 d-flex justify-content-center py-2">
    <button type="button" class="btn btn-outline-primary py-1 px-3 mr-1 ml-2">
      Message
    </button>
    <button type="button" class="btn btn-outline-primary py-1 px-3 ml-2">
      Email
    </button>
  </div>
</ng-template>

<ng-template #progressBar_Mentee let-element>
  <ngb-progressbar type="primary" class="w-100" height="15px" [value]="element.rowData?.progress" [showValue]="true">
  </ngb-progressbar>
</ng-template>