import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'lfx-filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrls: ['./filter-popup.component.scss']
})
export class FilterPopupComponent implements OnInit {

  @Input() parentForm!: FormGroup;
  @Input() tabsConfig!: Array<any>;
  @ViewChild('instance', { static: true }) instance!: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  activeTabIndex = 0;
  componentForm!: FormGroup;
  dummyForm = new FormGroup({
    item: new FormControl('', Validators.required)
  });
  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.componentForm = _.cloneDeep(this.parentForm);
  }

  changeActiveTab(tabIndex: number) {
    this.activeTabIndex = tabIndex;
  }

  search: any = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => {
      return this.instance ? !this.instance.isPopupOpen() : true;
    }));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => {
        return this.tabsConfig[this.activeTabIndex].search(term);
      })
    );
  }

  getSelectedItems(controlName: string) {
    return this.componentForm.get(controlName) as FormArray;
  }

  addItem(itemInput: HTMLInputElement) {
    if (!itemInput.value.length || this.isAlreadyAdded(itemInput.value)) {
      return
    }
    this.getSelectedItems(this.tabsConfig[this.activeTabIndex].controlName).push(new FormControl(itemInput.value));
    itemInput.value = '';
  }

  private isAlreadyAdded(value: string) {
    return this.getSelectedItems(this.tabsConfig[this.activeTabIndex].controlName).controls.filter((item) => item.value === value).length;
  }

  addListItem() {
    const newItem = this.dummyForm.value.item;
    this.getSelectedItems(this.tabsConfig[this.activeTabIndex].controlName).push(new FormControl(newItem));
    this.dummyForm.reset();
  }

  removeItem(itemIndex: number) {
    this.getSelectedItems(this.tabsConfig[this.activeTabIndex].controlName).removeAt(itemIndex);
  }

  cancel() {
    this.activeModal.dismiss();
  }

  applyFilter() {
    this.parentForm = _.cloneDeep(this.componentForm);
    this.activeModal.close(this.componentForm);
  }


}
