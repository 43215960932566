import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'genericSearch'
})
export class GenericSearchPipe implements PipeTransform {

  transform(items: any[], searchText: string, searchAbleField?: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    return items.filter((e) => {
      if (searchAbleField && e[searchAbleField]) {
        return e[searchAbleField].toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      } else if (!searchAbleField && typeof e === 'string') {
        return e.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      } else {
        return false;
      }
    });
  }
}


