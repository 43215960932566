<div class="search-dropdown-container">
  <div class="search-box" *ngIf="showSearchInput">
    <input type="text" placeholder="Enter name..." />
    <i class="fas fa-search"></i>
  </div>
  <div class="dropdown-items-container">
    <span class="search-dropdown-item ellipsis" *ngFor="let item of items" (click)="selectItem(item)">
      {{ item | titlecase }}
    </span>
  </div>
</div>