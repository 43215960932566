<ng-container *ngIf="!isLoading else loadingTemplate">
  <div class="task-management-container flex-wrap px-80 ">
    <lfx-breadcrumbs [breadcrumbs]="breadcrumbs"></lfx-breadcrumbs>
    <lfx-program-overview-header [program]="program"></lfx-program-overview-header>

    <div class="info-tile mt-3">
      <div class="row info-inner-tile">
        <div class="col-sm-2 col-md-1 col-lg-1 py-4">
          <img class="user-image mr-4"
            [src]="mentee?.imageUrl ? mentee?.imageUrl :'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/'+mentee?.firstName.toLowerCase().charAt(0) +'.png'"
            alt="">
        </div>
        <div class="col-sm-10 col-md-4 col-lg-4 col-xl-2 d-flex align-items-center">
          <h3 class="menteeName">{{mentee?.firstName + ' ' + mentee?.lastName}}</h3>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-2 m-auto">
          <h6>Applied Date</h6>
          <h3 class="appliedDate">
            {{mentee?.dateAccepted | date}}
          </h3>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-3 m-auto">
          <h6>Program Progress <span class="appliedDate">
              {{done.length}}/{{todo.length + doing.length + done.length}} Milestones
            </span></h6>

          <div class="d-flex flex-column">
            <div class="w-100">
              <ngb-progressbar type="success" height="15px" class="mb-2" [value]="milestoneProgramProgress">
                {{milestoneProgramProgress}} %</ngb-progressbar>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 d-flex align-items-center actions">
          <button type="button" class="btn btn-outline-primary px-3 py-1 mr-2">Back</button>
          <button type="button" class="btn btn-outline-primary px-3 py-1 mr-2">Message</button>
          <button type="button" class="btn btn-outline-primary px-3 py-1"> Email</button>
        </div>
      </div>
    </div>
    <div class="d-flex task-management-container flex-wrap">
      <div class="col-lg-4 col-md-12 col-sm-12 mb-4-2">
        <div class="card">
          <h2 class="card-header">
            <span class="header-text size-20 weight-600">
              To Do1
            </span>
            <span class="list-size size-18 weight-600">
              {{todo.length}}
            </span>
          </h2>
          <button class="create-milestone-btn weight-600 mx-4" (click)="createNewMilestone()">
            <i class="fa fa-plus" aria-hidden="true"></i>
            Create Milestone
          </button>
          <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="todo" id="todo"
            [cdkDropListConnectedTo]="[doingList,doneList]" class="list card-body" (cdkDropListDropped)="drop($event)">
            <lfx-milestone-card [milestone]="milestone" [generalView]="false" [currentMentee]="mentee"
              class="milestone-card d-flex mb-4-2" *ngFor="let milestone of todo" cdkDrag>
            </lfx-milestone-card>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-12 col-sm-12 mb-4-2">
        <div class="card">
          <h2 class="card-header">
            <span class="header-text size-20 weight-600"> Doing </span>
            <span class="list-size size-18 weight-600">
              {{doing.length}}
            </span>
          </h2>

          <div cdkDropList #doingList="cdkDropList" [cdkDropListData]="doing" id="doing"
            [cdkDropListConnectedTo]="[doneList,todoList]" class="list card-body" (cdkDropListDropped)="drop($event)">
            <lfx-milestone-card [milestone]="milestone" [generalView]="false" [currentMentee]="mentee"
              class="milestone-card d-flex mb-4-2" *ngFor="let milestone of doing" cdkDrag>
            </lfx-milestone-card>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-12 col-sm-12 mb-4-2">
        <div class="card">
          <h2 class="card-header">
            <span class="header-text size-20 weight-600">Done</span>
            <span class="list-size size-18 weight-600">
              {{done.length}}
            </span>
          </h2>

          <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="done" id="done"
            [cdkDropListConnectedTo]="[doingList,todoList]" class="list card-body" (cdkDropListDropped)="drop($event)">
            <lfx-milestone-card [milestone]="milestone" [generalView]="false" [currentMentee]="mentee"
              class="milestone-card d-flex mb-4-2" *ngFor="let milestone of done" cdkDrag>
            </lfx-milestone-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loadingTemplate>
  <lfx-loading></lfx-loading>
</ng-template>