import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { MenteeApplicationStatus } from 'src/app/core/constants';
import { Mentee } from 'src/app/models/mentee';
import { Mentor } from 'src/app/models/mentor';
import { Program } from 'src/app/models/program';

@Component({
  selector: 'lfx-mentee-details-card',
  templateUrl: './mentee-details-card.component.html',
  styleUrls: ['./mentee-details-card.component.scss']
})
export class MenteeDetailsCardComponent implements OnInit {

  activePopover!: NgbPopover;
  @Input() mentee!: Mentee;
  menteeStatus = MenteeApplicationStatus;
  constructor(
    private route: Router
  ) { }

  ngOnInit() {
    this.mentee.mentors = [];
    this.mentee.menteeApplications?.forEach(app => {
      this.mentee.mentors = _.unionWith(this.mentee.mentors, app.program.mentors, (a: Mentor, b: Mentor) => {
        return a.id === b.id;
      });
    });
  }

  navigateToMenteeDetail(): void {
    // TODO: navigate to mentor details page
  }

  viewProgram(program: Program) {
    this.route.navigateByUrl('/program/overview/' + program.id)
  }

  openPopover(popover: NgbPopover) {
    this.activePopover = popover;
    if (this.activePopover && !this.activePopover.isOpen()) {
      this.activePopover.open();
    }
  }

  closePopover() {
    if (this.activePopover && this.activePopover.isOpen()) {
      this.activePopover.close();
    }
  }

}
