import { Program } from './../../../API.service';
import { MentorService } from './../../../core/services/mentor.service';
import { ViewProgramPopupComponent } from './../../../mentorship/component/view-program-popup/view-program-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectController } from 'src/app/core/controllers/project.controller';
import { ProgramController } from './../../../core/controllers/program.controller';
import { Utilities } from 'src/app/core/utilities';
import { APIService, MentorProgram } from 'src/app/API.service';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { TableColumnConfig } from 'src/app/core/models/table-column-config';
import { TableConfig } from 'src/app/core/models/table-config';
import { MenteeApplicationStatus, MentorReviewStatus, ProgramStatus } from 'src/app/core/constants';
import { forkJoin, from } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {

  @ViewChild('projectNameTemplate') projectNameTemplate!: TemplateRef<any>;
  @ViewChild('programTemplate') programTemplate!: TemplateRef<any>;
  @ViewChild('statusTemplate') statusTemplate!: TemplateRef<any>;

  @ViewChild('usernameTemplate') usernameTemplate!: TemplateRef<any>;
  @ViewChild('dateTemplate') dateTemplate!: TemplateRef<any>;
  @ViewChild('programTemplateMentee') programTemplateMentee!: TemplateRef<any>;
  @ViewChild('actionsTemplate') actionsTemplate!: TemplateRef<any>;
  programStatus = ProgramStatus;
  utilities = new Utilities();
  otherMentorships = [
    {
      name: 'AI Modeling',
      creationDate: new Date(2017, 6, 13),
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non arcu a sapien cursus efficitur non et odio."
    }
    , {
      name: 'Distributed Data Processing',
      creationDate: new Date(2017, 6, 13),
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non arcu a sapien cursus efficitur non et odio."
    },
    {
      name: 'Deep Learning with BigDL',
      creationDate: new Date(2017, 6, 13),
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non arcu a sapien cursus efficitur non et odio."
    }
  ];
  myPrograms = new Array<MentorProgram>();
  myApplications = new Array<any>();

  tableConfig: TableConfig = {
    xPadding: '20px',
    colXPadding: '20px',
    columns: new Array<TableColumnConfig>(),
    isLoading: true,
    error: false
  }

  applicationsTableConfig: TableConfig = {
    xPadding: '20px',
    colXPadding: '20px',
    columns: new Array<TableColumnConfig>(),
    isLoading: true,
    error: false
  }

  breadcrumbs = [{
    name: "mentor dashboard",
    url: ""
  }];
  mentorId: any = '';
  mentorReview = new Array<any>();
  isLoading = false;

  constructor(
    private route: Router,
    private api: APIService,
    private programController: ProgramController,
    private projectController: ProjectController,
    private modalService: NgbModal,
    private mentorService: MentorService
  ) { }

  ngOnInit() {   
    this.myApplications = [
      {
        firstName: 'Sameh',
        lastName: 'Mohamed',
        program: {
          programName: 'Test',
        },
        appliedDate: new Date()
      },
      {
        firstName: 'Sameh',
        lastName: 'Mohamed',
        program: {
          programName: 'Test',
        },
        appliedDate: new Date()
      }
    ];
    this.mentorId = this.mentorService.activeMentor?.id;
    this.applicationsTableConfig.isLoading = true;
    this.programController.getMentorReviewsByMentorID(this.mentorId!).subscribe((res: any) => {
      this.mentorReview = res.items.filter((review: any) => review.status === MentorReviewStatus.pending && review.menteeApplication.applicationStatus === MenteeApplicationStatus.pending);
      this.applicationsTableConfig.isLoading = false;
    });
  }

  ngAfterViewInit(): void {
    this.tableConfig.columns.push(...[
      {
        title: 'Project',
        width: '33.33%',
        template: this.projectNameTemplate
      },
      {
        title: 'Program Name',
        width: '33.33%',
        template: this.programTemplate,
      },
      {
        title: 'Status',
        width: '33.33%',
        template: this.statusTemplate,
        isCenter: true,
        key: 'status'
      }
    ]);
    this.applicationsTableConfig.columns.push(...[
      {
        title: 'Mentee Name',
        width: '33.33%',
        template: this.usernameTemplate
      },
      {
        title: 'Program Name',
        width: '33.33%',
        template: this.programTemplateMentee,
      },
      {
        title: 'Applied Date',
        width: '23.33%',
        template: this.dateTemplate,
        isCenter: true,
        key: 'appliedDate'
      },
      {
        title: 'Actions',
        isCenter: true,
        key: 'action',
        width: '10%',
        template: this.actionsTemplate,
      },
    ]);
    this.getPrograms();
  }

  getPrograms(withoutPopup?: boolean) {
    this.tableConfig.isLoading = true;
    forkJoin([this.projectController.getAllProjects()])
      .subscribe(res => {
        this.projectController.projects = res[0];
        this.myPrograms = this.mentorService.activeMentor?.programs?.items?.filter(program => program?.invitationStatus !== ProgramStatus.declined)
          .map((item: any) => {
            if (item?.program?.projectID) {
              item.program['project'] = this.projectController.getProjectById(item?.program.projectID)
            }
            return item;
          }).filter(program => program.program.status === ProgramStatus.accepted) as any;
        if (this.pendingPrograms()?.length && !withoutPopup) {
          this.openViewProgramDialog(false, this.pendingPrograms()[0].program);
        }
        this.tableConfig.isLoading = false;
      });

  }

  openViewProgramDialog(showProgramDetails: boolean, program: Program) {
    const dialogRef = this.modalService.open(ViewProgramPopupComponent, {
      centered: !showProgramDetails,
      size: showProgramDetails ? 'xl' : 'md',
      modalDialogClass: showProgramDetails ? 'modal-max-height' : '',
    });
    dialogRef.componentInstance.program = program;
    dialogRef.componentInstance.viewProgram = showProgramDetails;
    dialogRef.closed.subscribe(res => {
      if (res === 'updateStatus') {
        this.getPrograms(true);
      } else {
        this.openViewProgramDialog(true, program);
      }
    })
  }

  private pendingPrograms() {
    return this.myPrograms!.filter(program => program?.invitationStatus === ProgramStatus.pending);
  }

  previewProject(mentorProgram: MentorProgram) {
    if (mentorProgram.invitationStatus === this.programStatus.accepted) {
      this.openProgramOverview(mentorProgram.programID);
    } else {
      this.openViewProgramDialog(false, mentorProgram.program);
    }
  }

  openProgramOverview(programID: string) {
    this.route.navigate(['/program/overview', programID]);
  }

  viewAllProgram() {
    this.route.navigate(['/programs']);
  }

  reviewApplication(mentorReview: any) {
    this.route.navigate([`/program/${mentorReview.menteeApplication.programID}/review/${mentorReview.id}`]);
  }

}
