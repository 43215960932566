import { FileUploadService } from './../../../core/services/file-upload.service';
import { CreateTaskInput } from './../../../API.service';
import { SelectionModel } from './../../../core/models/selection.model';
import { Utilities } from 'src/app/core/utilities';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { APIService } from 'src/app/API.service';
import { forkJoin, Observable } from 'rxjs';
import { TaskController } from 'src/app/core/controllers/task.controller';
import * as _ from 'lodash';
import { Term } from 'src/app/models/term';

@Component({
  selector: 'lfx-create-new-task',
  templateUrl: './create-new-task.component.html',
  styleUrls: ['./create-new-task.component.scss']
})
export class CreateNewTaskComponent implements OnInit {

  @Input() milestone!: any;
  taskForm = new FormGroup({
    name: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    dueDate: new FormControl('', Validators.required),
    attachments: new FormArray([]),
    mentees: new FormArray([], [Validators.required, Validators.minLength(1)]),
    uploadRequired: new FormControl(false)
  });
  utilities = new Utilities();
  today = new Date();
  minTaskDueDate = new Date();
  maxTaskDueDate = new Date();
  get mentees() {
    return this.taskForm.get('mentees') as FormArray;
  }

  get attachments() {
    return this.taskForm.get('attachments') as FormArray;
  }

  selection = new SelectionModel(new Array(), this.mentees);
  constructor(
    private api: APIService,
    private taskController: TaskController,
    private fileUploadService: FileUploadService
  ) { }

  ngOnInit() {
    this.setDueDateBoundary();
  }

  private setDueDateBoundary() {
    this.minTaskDueDate = new Date(this.milestone.program.terms.find((term: Term) => term.termName === this.milestone.termName)?.termStartDate);
    this.maxTaskDueDate = new Date(this.milestone.dueDate);
  }

  uploadFile(event: any) {
    const files: FileList = event.files;
    if (files.length === 1) {
      this.attachments.push(new FormControl(files[0]));
      event.value = '';
    }
  }

  removeFile(fileIndex: number) {
    this.attachments.removeAt(fileIndex);
  }

  toggleSelectAllMentees() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.milestone.program.mentees.forEach((mentee: any) => {
        this.selection.select(mentee);
      });
    }
  }

  isAllSelected() {
    return this.selection.selected.length === this.milestone.program.mentees.length;
  }

  createNewTask() {
    this.saveFiles((files) => {
      const task = this.taskForm.value;
      const input: CreateTaskInput = {
        milestoneID: this.milestone.id,
        description: task.description,
        taskName: task.name,
        attachments: files.map(file => {
          return {
            attachmentURL: file.fileUrl,
            attachmentText: file.fileName
          };
        }),
        dueDate: task.dueDate.getTime(),
        uploadRequired: task.uploadRequired,
      };
      const assignToMenteeInput = this.selection.selected.map((mentee: any) => mentee.id);
      this.taskController.createTask({ input, assignToMenteeInput: assignToMenteeInput }).subscribe((res: any) => {
        res.dueDate = new Date(res.dueDate);
        res.assignedToMentees = {
          items: _.cloneDeep(this.selection.selected)
        }
        this.milestone.milestoneTasks.push(res);
        this.selection.clear();
        this.taskForm.reset();
        this.attachments.reset();
        this.attachments.removeAt(0);
        this.taskForm.get('uploadRequired')?.setValue(false);
      });
    });
  }

  private saveFiles(callback: (files: { fileName: string, fileUrl: string }[]) => void) {
    let files = this.attachments.value;
    if (files.length === 0) {
      callback([]);
    }
    const fileObservables: Observable<string>[] = [];
    files.forEach((file: File) => {
      fileObservables.push(this.fileUploadService.uploadFile(file));
    });

    forkJoin(fileObservables).subscribe(res => {
      const responseFiles: { fileName: string, fileUrl: string }[] = [];
      res.forEach((fileUrl, index) => {
        responseFiles.push({
          fileName: files[index].name,
          fileUrl
        });
      });
      callback(responseFiles);
    });
  }

  clearTaskCreation() {
    this.taskForm.reset();
    this.selection.clear();
    this.utilities.clearFormArray(this.taskForm.get('attachments') as FormArray);
    this.utilities.clearFormArray(this.taskForm.get('mentees') as FormArray);
  }

  clearDate(formControlName: string) {
    this.taskForm.get(formControlName)?.reset();
  }

}
