import { TableConfig } from 'src/app/core/models/table-config';
import { TableColumnConfig } from 'src/app/core/models/table-column-config';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Utilities } from 'src/app/core/utilities';
import { Component, Input, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MenteeApplicationStatus } from 'src/app/core/constants';

@Component({
  selector: 'lfx-mentee-other-application-popup',
  templateUrl: './mentee-other-application-popup.component.html',
  styleUrls: ['./mentee-other-application-popup.component.scss']
})
export class MenteeOtherApplicationPopupComponent implements OnInit {

  @Input() applications = [
    {
      program: {
        programName: "AI Modeling"
      },
      applicationDate: new Date(),
      applicationStatus: MenteeApplicationStatus.pending,
    },
    {
      program: {
        programName: "Distributed Data Processing"
      },
      applicationDate: new Date(),
      applicationStatus: MenteeApplicationStatus.pending,
    },
    {
      program: {
        programName: "Intel® Embrue Algorithms"
      },
      applicationDate: new Date(),
      applicationStatus: MenteeApplicationStatus.pending,
    }
  ];
  @ViewChild('programNameTemplate') programNameTemplate!: TemplateRef<any>;
  @ViewChild('statusTemplate') statusTemplate!: TemplateRef<any>;
  @ViewChild('dateTemplate') dateTemplate!: TemplateRef<any>;
  @ViewChild('actionTemplate') actionTemplate!: TemplateRef<any>;
  utilities = new Utilities();
  menteeApplicationStatus = MenteeApplicationStatus;
  tableConfig: TableConfig = {
    xPadding: '20px',
    colXPadding: '20px',
    columns: new Array<TableColumnConfig>(),
    isLoading: false,
    error: false
  };
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.tableConfig.columns = [
      {
        title: 'Program Name',
        width: "40%",
        template: this.programNameTemplate,
      },
      {
        title: 'Application Date',
        key: 'applicationDate',
        width: "24.5%",
        template: this.dateTemplate,
      },
      {
        title: 'Status',
        key: 'applicationStatus',
        width: "20.5%",
        template: this.statusTemplate,
      },
      {
        title: 'Contact Admin',
        isCenter: true,
        width: '15%',
        template: this.actionTemplate,
      }
    ]
  }


}
