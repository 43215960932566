import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GenericSearchPipe } from 'src/app/core/pipes/generic-search.pipe';
import { forkJoin, from, of } from 'rxjs';
import * as _ from 'lodash';
import { PaginationConfig, TableConfig } from 'src/app/core/models/table-config';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterPopupComponent } from 'src/app/shared/filter-popup/filter-popup.component';
import { FormArray, FormGroup } from '@angular/forms';
import { ProjectController } from 'src/app/core/controllers/project.controller';
import { TableColumnConfig } from 'src/app/core/models/table-column-config';
import { APIService } from 'src/app/API.service';
import { ProgramController } from 'src/app/core/controllers/program.controller';
import { Mentor } from 'src/app/models/mentor';

@Component({
  selector: 'lfx-mentors-page',
  templateUrl: './mentors-page.component.html',
  styleUrls: ['./mentors-page.component.scss']
})
export class MentorsPageComponent implements OnInit {


  @ViewChild('mentorNameTemplate') mentorNameTemplate!: TemplateRef<any>;
  @ViewChild('projectTemplate') projectTemplate!: TemplateRef<any>;
  @ViewChild('programTemplate') programTemplate!: TemplateRef<any>;
  @ViewChild('statusTemplate') statusTemplate!: TemplateRef<any>;
  @ViewChild('actionTemplate') actionTemplate!: TemplateRef<any>;
  isLoading = false;
  isLoadMore = false;
  genericSearchPipe = new GenericSearchPipe();
  pagination = new PaginationConfig();
  tableConfig: TableConfig = {
    xPadding: '20px',
    colXPadding: '20px',
    columns: new Array<TableColumnConfig>(),
    isLoading: true,
    error: false
  };

  allMentors = new Array<Mentor>();
  mentors = new Array<Mentor>();
  showAll = -1;
  tableView = true;
  filter: any = {};
  filterForm = new FormGroup({
    project: new FormArray([]),
    program: new FormArray([])
  });
  breadcrumbs = [
    {
      name: "Mentors",
      url: '/mentors'
    }
  ];

  constructor(
    private modalService: NgbModal,
    private projectController: ProjectController,
    private programController: ProgramController,
    private api: APIService
  ) { }

  ngOnInit(): void {
    const getAllMentors = from(this.api.ListMentors());
    this.isLoading = true;
    forkJoin([getAllMentors, this.projectController.getAllProjects()])
      .subscribe(res => {
        this.projectController.projects = res[1];
        const { items } = res[0];
        this.tableConfig.isLoading = false;
        this.allMentors = (items as Array<any>)?.map(item => {
          item.name = item.firstName + ' ' + item.lastName;
          item?.programs?.items?.map((program: any) => {
            program.program = this.programController.mapProgram(program.program);
            if (program.program.projectID) {
              program.program['project'] = this.projectController.getProjectById(program.program.projectID)
            }
          });
          item.programs = item.programs.items;
          return item;
        });
        this.mentors = _.cloneDeep(this.allMentors);
        this.generatePages(this.allMentors);
        this.isLoading = false;
      });
  }

  ngAfterViewInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
    this.tableConfig.columns = [
      {
        title: 'Name',
        width: '21.5%',
        template: this.mentorNameTemplate
      },
      {
        title: 'Project',
        width: '14.5%',
        template: this.projectTemplate,
      },
      {
        title: 'Program Name',
        width: '29%',
        template: this.programTemplate,
      },
      {
        title: 'Role',
        width: '21%',
        template: this.statusTemplate,
        isCenter: true,
        key: 'status'
      },
      {
        title: 'Contact',
        width: '14%',
        template: this.actionTemplate,
        isCenter: true,
      }
    ];
  }

  openFilterDialog() {
    const dialogRef = this.modalService.open(FilterPopupComponent, {
      size: "lg",
      centered: true
    });
    const instance = dialogRef.componentInstance;
    instance.parentForm = this.filterForm;
    instance.tabsConfig = [
      {
        title: "Project",
        infoTitle: "Select Project",
        type: "searchableList",
        items: this.projectController.projects,
        controlName: "project",
        viewName: 'projectName'
      },
      {
        title: "Program",
        infoTitle: "Select program",
        type: "searchableList",
        items: this.getAllUsedPrograms(),
        controlName: "program",
        viewName: 'programName'
      },
    ];

    dialogRef.closed.subscribe(result => {
      this.filterForm = result;
      this.generateFilterFromFilterForm();
      this.applyNameFilter(this.filter.name);
    });
  }

  private getAllUsedPrograms() {
    let programs = new Array();
    this.allMentors.forEach(mentor => {
      programs.push(...mentor.programs?.map(program => program.program)!);
    });
    return programs;
  }

  private generateFilterFromFilterForm() {
    const filterFormValue = this.filterForm.value;
    const nameFilter = this.filter.name;
    this.filter = {};
    this.filter.name = nameFilter;
    if (filterFormValue.project.length) {
      this.filter.projects = filterFormValue.project.map((project: any) => project.projectID);
    }
    if (filterFormValue.program.length) {
      this.filter.programs = filterFormValue.program.map((program: any) => program.id);
    }
  }

  applyNameFilter(filteredText: string) {
    this.isLoadMore = true;
    this.filter.name = filteredText;
    this.mentors = this.applyFilter();
    this.generatePages(this.mentors);
    this.isLoadMore = false;
    this.isLoading = false;
  }

  private applyFilter() {
    let filterResult = _.cloneDeep(this.allMentors);
    if (this.filter.name) {
      filterResult = this.genericSearchPipe.transform(filterResult, this.filter.name, 'name');
    }
    if (this.filter.projects) {
      filterResult = filterResult.filter(mentor => mentor.programs?.map(program => program.program.projectID).some(projectID => this.filter.projects.includes(projectID)));
    }
    if (this.filter.programs) {
      filterResult = filterResult.filter(mentor => mentor.programs?.map(program => program.program.id).some(programID => this.filter.programs.includes(programID)));
    }
    return filterResult;
  }

  filterFun = (filterText: string, appliedFilter?: any) => {
    const searchResult = this.genericSearchPipe.transform(this.allMentors, filterText, 'name');
    return of({ items: searchResult.slice(0, 5), nextToken: searchResult.length > 5 })
  }

  private generatePages(mentors: Array<any>) {
    this.pagination.numberOfPages = Math.ceil(mentors.length / this.pagination.numberOfElementsPerPage);
    this.pagination.activePageIndex = 1;
    this.updatePagination();
  }

  updatePagination() {
    const from = (this.pagination.activePageIndex - 1) * this.pagination.numberOfElementsPerPage;
    const to = this.pagination.activePageIndex * this.pagination.numberOfElementsPerPage;
    this.pagination.items = this.mentors.slice(from, to > this.mentors.length ? undefined : to);
  }

  showProjects(vals: any) {
    if (this.showAll === vals) {
      this.showAll = -1;
    } else {
      this.showAll = vals;
    }
  }

}
