import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { delay, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProjectController {
  constructor(private http: HttpClient) {}

  projects = new Array<any>();

  getAllProjects() {
    if (this.projects.length > 1) {
      return of(this.projects).pipe(delay(1));
    }
    return this.http
      .get(
        'https://bu3bulspo5.execute-api.us-east-1.amazonaws.com/dev/project-service/projects?size=3500&entitytype=Series LLC&projectType=Project'
      )
      .pipe(
        map((res: any) =>
          res.Data.map((item: any) => {
            return {
              projectID: item.ID,
              projectName: item.Name,
              projectLogo: item.ProjectLogo,
            };
          })
        )
      );
  }

  getAllProjectsByIDs(Ids: Array<String>) {
    return this.http
      .get(
        'https://bu3bulspo5.execute-api.us-east-1.amazonaws.com/dev/project-service/search?id=' +
          Ids.toString()
      )
      .pipe(
        map((res: any) =>
          res.Data.map((item: any) => {
            return {
              projectID: item.ID,
              projectName: item.Name,
              projectLogo: item.ProjectLogo,
            };
          })
        )
      );
  }

  getProjectById(projectId: string) {
    const project = this.projects.find(
      (project) => project.projectID === projectId
    );
    return project;
  }
}
