import { MentorReviewStatus, MenteeApplicationStatus, taskStatus } from './../../../core/constants';
import { LoadingService } from './../../../core/services/loading.service';
import { ProgramController } from 'src/app/core/controllers/program.controller';
import { MenteeOtherApplicationPopupComponent } from './../../component/mentee-other-application-popup/mentee-other-application-popup.component';
//import { AssignMentorsPopupComponent } from './../../component/assign-mentors-popup/assign-mentors-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableColumnConfig } from 'src/app/core/models/table-column-config';
import { MenteeApplication } from './../../../models/menteeApplication';
import { TableConfig } from 'src/app/core/models/table-config';
import { Utilities } from 'src/app/core/utilities';
import { PaginationConfig } from './../../../core/models/table-config';
import { ProjectController } from 'src/app/core/controllers/project.controller';
import { ProgramService } from './../../../core/services/program.service';
import { ActivatedRoute } from '@angular/router';
import { Program } from 'src/app/models/program';
import { Component, OnInit, AfterViewChecked, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { APIService, GetProgramQuery, ListMenteeApplicationsQuery } from 'src/app/API.service';
import { forkJoin, from, of } from 'rxjs';
import { SelectionModel } from 'src/app/core/models/selection.model';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { ApplicationController } from 'src/app/core/controllers/application.controller';
import { MentorService } from 'src/app/core/services/mentor.service';
import { MenteeController } from 'src/app/core/controllers/mentee.controller';
import { GenericSearchPipe } from 'src/app/core/pipes/generic-search.pipe';

@Component({
  selector: 'lfx-program-mentee-application',
  templateUrl: './program-mentee-application.component.html',
  styleUrls: ['./program-mentee-application.component.scss']
})
export class ProgramMenteeApplicationComponent implements OnInit, AfterViewInit {

  @ViewChild('checkboxTemplate') checkboxTemplate!: TemplateRef<any>;
  @ViewChild('nameTemplate') nameTemplate!: TemplateRef<any>;
  @ViewChild('statusTemplate') statusTemplate!: TemplateRef<any>;
  @ViewChild('dateTemplate') dateTemplate!: TemplateRef<any>;
  @ViewChild('mentorReviewTemplate') mentorReviewTemplate!: TemplateRef<any>;
  @ViewChild('numberOfAppTemplate') numberOfAppTemplate!: TemplateRef<any>;
  @ViewChild('actionTemplate') actionTemplate!: TemplateRef<any>;
  @ViewChild('actionTemplate_Mentee') actionTemplate_Mentee!: TemplateRef<any>;
  @ViewChild('review_progress') review_progress!: TemplateRef<any>;
  @ViewChild('progressBar_Mentee') progressBar_Mentee!: TemplateRef<any>
  menteeApplicationStatus = MenteeApplicationStatus;
  genericSearchPipe = new GenericSearchPipe();
  isLoading = false;
  isLoadMore = false;
  program!: Program;
  menteeApplications = new Array<any>();
  acceptedMentees = new Array<any>();
  utilities = new Utilities();
  filter: any = {};
  menteeApplicationsPagination = new PaginationConfig();
  acceptedMenteesPagination = new PaginationConfig();
  selection = new SelectionModel<any>(new Array());
  tableConfig: TableConfig = {
    xPadding: '20px',
    colXPadding: '20px',
    columns: new Array<TableColumnConfig>(),
    isLoading: true,
    error: false
  };
  tableConfig_Mentee: TableConfig = {
    xPadding: '20px',
    colXPadding: '20px',
    columns: new Array<TableColumnConfig>(),
    isLoading: true,
    error: false
  };
  breadcrumbs = [
    {
      name: "programs",
      url: '/programs'
    },
    {
      name: '',
      url: '/program/overview/'
    },
    {
      name: "applications",
      url: ""
    }
  ];
  activeTabIndex = 1;
  constructor(
    private activatedRoute: ActivatedRoute,
    private api: APIService,
    private programController: ProgramController,
    private projectController: ProjectController,
    private modalService: NgbModal,
    private route: Router,
    private menteeController: MenteeController,
    private applicationController: ApplicationController,
    private mentorService: MentorService
  ) {

  }

  ngOnInit() {
    this.menteeApplicationsPagination.numberOfElementsPerPage = 8;
    this.acceptedMenteesPagination.numberOfElementsPerPage = 8;
    const programID = this.activatedRoute.snapshot.paramMap.get('programId');
    const activeTab = this.activatedRoute.snapshot.queryParamMap.get('tabIndex');
    if (programID) {
      this.getMenteeApplications(programID);
    }
    if (activeTab && (activeTab === "1" || activeTab === "2")) {
      this.activeTabIndex = +activeTab;
    }
  }

  ngAfterViewInit(): void {
    this.tableConfig.columns = [
      {
        title: '',
        width: '4%',
        isSelection: true,
        template: this.checkboxTemplate
      },
      {
        title: 'Mentee Name',
        width: "22.5%",
        template: this.nameTemplate,
      },
      {
        title: 'Applied Date',
        key: 'applicationDate',
        width: "12.5%",
        template: this.dateTemplate,
      },
      {
        title: 'Application Status',
        key: 'applicationStatus',
        width: "14.5%",
        template: this.statusTemplate,
      },
      {
        title: 'Mentors Review',
        width: "17.5%",
        template: this.mentorReviewTemplate,
      },
      {
        title: 'Other Applications',
        width: "15.5%",
        template: this.numberOfAppTemplate,
      },
      {
        title: 'Review Application',
        isCenter: true,
        width: '13.5%',
        template: this.actionTemplate,
      }
    ];
    this.tableConfig_Mentee.columns = [
      {
        title: 'Mentee Name',
        width: "22.5%",
        template: this.nameTemplate,
      },
      {
        title: 'Applied Date',
        key: 'applicationDate',
        width: "12.5%",
        template: this.dateTemplate,
      },
      {
        title: 'Progress',
        key: 'progress',
        width: "25.5%",
        template: this.progressBar_Mentee,
      },
      {
        title: 'Due Date',
        key: 'applicationDate',
        width: "12.5%",
        template: this.dateTemplate,
      },
      {
        title: 'Review Progress',
        width: '10%',
        isCenter: true,
        template: this.review_progress
      },
      {
        title: 'Actions',
        width: "15.5%",
        isCenter: true,
        template: this.actionTemplate_Mentee,
      }
    ]
  }

  private getMenteeApplications(programId: string) {
    this.isLoading = true;
    const getProgram = this.programController.getProgramByIdGraphql(programId);
    const getMenteeApplications = from(this.api.ListMenteeApplications({ programID: { eq: programId } }));
    forkJoin([getProgram, getMenteeApplications]).subscribe(([program, menteeApplications]) => {
      this.program = program;
      this.mapProgram();
      menteeApplications.items = menteeApplications.items?.map((app: any) => {
        app.mentee.name = app?.mentee.firstName + ' ' + app?.mentee.lastName;
        return app;
      }) as any;
      this.mapMenteeApplications(programId, menteeApplications);
    }, error => {
      this.tableConfig.isLoading = false;
      this.tableConfig_Mentee.isLoading = false;
      this.isLoading = false;
    });
  }

  private mapMenteeApplications(programId: string, menteeApplications: ListMenteeApplicationsQuery) {
    this.menteeApplications = menteeApplications.items?.filter((mentee) => {
      return (mentee?.applicationStatus.toLowerCase() === MenteeApplicationStatus.submitted || mentee?.applicationStatus.toLowerCase() === MenteeApplicationStatus.pending)
        && mentee.mentorReviews?.items?.find(review => review?.mentorID === this.mentorService.activeMentor?.id);
    }) as any;

    this.acceptedMentees = menteeApplications.items?.filter((mentee) => {
      return mentee?.applicationStatus.toLowerCase() === MenteeApplicationStatus.accepted;
    }) as any;

    this.acceptedMentees.forEach(app => {
      this.getMenteeMilestones(app.mentee.id, (progress) => {
        app.progress = progress;
      });
    });

    this.menteeApplications.forEach(app => {
      app.mentee.otherApplications = this.generateOtherApplications(app.mentee, programId);
      app.acceptedReviewsCount = app.mentorReviews.items.filter((review: any) => review.status == MentorReviewStatus.accepted).length;
    });
    this.stopLoading();
  }

  private getMenteeMilestones(menteeID: string, callback: (progress: number) => void) {
    this.menteeController.getMenteeMilestones({
      userID: menteeID,
      programID: this.program.id
    }).subscribe((milestones: Array<any>) => {
      const done = [];
      milestones = milestones.filter(milestone => milestone.milestoneTasks && milestone.milestoneTasks.length);
      milestones.forEach((milestone, i) => {
        const isDone = milestone.milestoneTasks?.every((item: any) => item.taskDetail.status === taskStatus.completed);
        if (isDone) {
          done.push(milestone);
        }
      });
      const menteeProgress = Math.round((done.length / milestones.length) * 100);
      callback(menteeProgress);
    });
  }


  private generateOtherApplications(mentee: any, programId: string) {
    return mentee.menteeApplications.items.filter((application: any) => application.programID !== programId && application.applicationStatus !== MenteeApplicationStatus.draft);
  }

  private mapProgram() {
    this.breadcrumbs[1] = {
      name: this.program.programName,
      url: '/program/overview/' + this.program.id
    };
    this.program = this.programController.mapProgram(this.program);
    this.program.project = this.projectController.getProjectById(this.program.projectID!);
    if (this.program.projectID && !this.program.project) {
      this.projectController.getAllProjectsByIDs([this.program.projectID!]).subscribe(res => {
        this.program.project = res[0];
      });
    }
  }

  private stopLoading() {
    this.tableConfig.isLoading = false;
    this.tableConfig_Mentee.isLoading = false;
    this.isLoading = false;
    if (this.menteeApplications.length || this.acceptedMentees.length) {
      this.generatePagesForMenteeApplications();
      this.generatePagesForAcceptedMentees();
    }
  }

  openMenteeOtherApplications(mentee: any) {
    const modalRef = this.modalService.open(MenteeOtherApplicationPopupComponent, {
      size: 'xl',
      centered: true
    });
    modalRef.componentInstance.applications = mentee.otherApplications;
  }

  declineMentees() {
    const menteeApplicationIds = this.selection.selected.map(app => app.id + this.mentorService.activeMentor?.id);
    this.applicationController.updateMentorReviewsStatus(menteeApplicationIds, MenteeApplicationStatus.declined).subscribe(res => {
      this.selection.selected.forEach(app => {
        app.mentorReviews.items.find((review: any) => review?.mentorID === this.mentorService.activeMentor?.id).status = MenteeApplicationStatus.declined;
        app.acceptedReviewsCount = app.mentorReviews.items.filter((review: any) => review.status == MentorReviewStatus.accepted).length;
      });
    });
  }

  acceptMentees() {
    const menteeApplicationIds = this.selection.selected.map(app => app.id + this.mentorService.activeMentor?.id);
    this.applicationController.updateMentorReviewsStatus(menteeApplicationIds, MenteeApplicationStatus.accepted).subscribe(res => {
      this.selection.selected.forEach(app => {
        app.mentorReviews.items.find((review: any) => review?.mentorID === this.mentorService.activeMentor?.id).status = MenteeApplicationStatus.accepted;
        app.acceptedReviewsCount = app.mentorReviews.items.filter((review: any) => review.status == MentorReviewStatus.accepted).length;
      });
    });
  }

  openFilterDialog() {

  }

  getAllProgramApplications() {

  }

  applyNameFilter(filteredText: string) {
    this.isLoadMore = true;
    this.filter.menteeName = filteredText;
    this.isLoadMore = false;
    this.isLoading = false;
  }

  filterFun = (filterText: string, appliedFilter?: any) => {
    const list = this.activeTabIndex === 1 ? this.menteeApplications.map(app => app.mentee) : this.acceptedMentees.map(app => app.mentee)
    const searchResult = this.genericSearchPipe.transform(list, filterText, 'name');
    return of({ items: searchResult.slice(0, 5), nextToken: searchResult.length > 5 })
  }

  private generatePagesForAcceptedMentees() {
    this.acceptedMenteesPagination.numberOfPages = Math.ceil(this.acceptedMentees.length / this.acceptedMenteesPagination.numberOfElementsPerPage);
    this.acceptedMenteesPagination.activePageIndex = 1;
    this.updatePaginationForAcceptedMentees();
  }

  updatePaginationForAcceptedMentees() {
    const from = (this.acceptedMenteesPagination.activePageIndex - 1) * this.acceptedMenteesPagination.numberOfElementsPerPage;
    const to = this.acceptedMenteesPagination.activePageIndex * this.acceptedMenteesPagination.numberOfElementsPerPage;
    this.acceptedMenteesPagination.items = this.acceptedMentees.slice(from, to > this.acceptedMentees.length ? undefined : to);
  }

  private generatePagesForMenteeApplications() {
    this.menteeApplicationsPagination.numberOfPages = Math.ceil(this.menteeApplications.length / this.menteeApplicationsPagination.numberOfElementsPerPage);
    this.menteeApplicationsPagination.activePageIndex = 1;
    this.updatePaginationForMenteeApplications();
  }

  updatePaginationForMenteeApplications() {
    const from = (this.menteeApplicationsPagination.activePageIndex - 1) * this.menteeApplicationsPagination.numberOfElementsPerPage;
    const to = this.menteeApplicationsPagination.activePageIndex * this.menteeApplicationsPagination.numberOfElementsPerPage;
    this.menteeApplicationsPagination.items = this.menteeApplications.slice(from, to > this.menteeApplications.length ? undefined : to);
  }

  contactAll() {

  }

  isAllSelected = () => {
    return this.selection.selected.length === this.menteeApplicationsPagination.items.length && this.menteeApplicationsPagination.items.length !== 0;
  }

  toggleSelection() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.menteeApplicationsPagination.items.forEach(item => this.selection.select(item));
  }

  navigateToReviewApplication(program: Program, applicationId: string) {
    this.route.navigate([`/program/${program.id}/review/${applicationId + this.mentorService.activeMentor?.id}`]);
  }

  ShowTabs(tab: string) {
    if (tab == 'acceptedMentee') {
      this.activeTabIndex = 2;
    } else {
      this.activeTabIndex = 1
    }
  }

  navigateToMenteeProgress(program: Program, applicationId: string) {
    this.route.navigate([`program/${program.id}/menteeApplicationId/${applicationId}`]);
  }

}