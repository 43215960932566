import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIService } from 'src/app/API.service';

@Injectable({
    providedIn: 'root'
})
export class ApplicationController {
    httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    });

    options = {
        headers: this.httpHeaders
    };

    constructor(private http: HttpClient, private api: APIService) { }

    assignMentorsToApplication(request: { applicationIDsList: Array<string>, mentorIDsList: Array<string> }) {
        return this.http.post('https://0sz2cejc5f.execute-api.us-east-1.amazonaws.com/dev/mentorship-service/admin/create-mentor-reviews', request, this.options);
    }

    updateMentorReviewsStatus(mentorReviewIds: Array<string>, status: string) {
        const request = {
            mentorReviewsIDsList: mentorReviewIds,
            status
        };
        return this.http.post('https://0sz2cejc5f.execute-api.us-east-1.amazonaws.com/dev/mentorship-service/mentor/update-mentor-reviews-status', request, this.options);
    }

}