import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableConfig } from 'src/app/core/models/table-config';

@Component({
  selector: 'app-common-table',
  templateUrl: './common-table.component.html',
  styleUrls: ['./common-table.component.scss']
})
export class CommonTableComponent implements OnInit {

  @Input() tableConfiguration!: TableConfig;
  @Input() tableRecords?: Array<any> = [];
  @Output() toggleSelection = new EventEmitter();
  @Input() isAllSelected!: () => boolean;
  constructor() { }

  ngOnInit() {
  }

  toggleAllSelection() {
    this.toggleSelection.emit();
  }


}
