import { Injectable } from '@angular/core';
import { Mentor } from 'src/app/API.service';

@Injectable({
    providedIn: 'root'
})
export class MentorService {
    activeMentor?: Mentor;
    constructor() { }

    getMentorProgramByProgramId(programId: string) {
        return this.activeMentor?.programs?.items?.find(program => {
            return program?.programID === programId;
        });
    }

}