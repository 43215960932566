import { MentorService } from './../../../core/services/mentor.service';
import { TaskController } from './../../../core/controllers/task.controller';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CreateMilestonePopupComponent } from './../../components/create-milestone-popup/create-milestone-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { APIService, GetMenteeApplicationQuery, GetMilestoneQuery, Mentee, Milestone } from 'src/app/API.service';
import { forkJoin, from } from 'rxjs';
import * as _ from 'lodash';
import { MenteeApplicationStatus } from 'src/app/core/constants';
import { MENTEE_ID, taskStatus } from 'src/app/core/constants';
import { MenteeController } from 'src/app/core/controllers/mentee.controller';
import { Program } from 'src/app/models/program';
import { ProgramController } from 'src/app/core/controllers/program.controller';
import { ActivatedRoute } from '@angular/router';
import { ProjectController } from 'src/app/core/controllers/project.controller';

@Component({
  selector: 'lfx-mentee-task-management',
  templateUrl: './mentee-task-management.component.html',
  styleUrls: ['./mentee-task-management.component.scss']
})
export class MenteeTaskManagementComponent implements OnInit {
  milestones = new Array();

  todo = new Array<any>();
  doing = new Array<any>();
  done = new Array<any>();
  milestoneCount: number = 0;
  milestoneProgramProgress = 0;

  breadcrumbs = [
    {
      name: "programs",
      url: '/programs'
    },
    {
      name: "Milestone",
      url: ''
    }
  ];
  isLoading: boolean = false;
  program!: Program;
  //@Input() program!: Program;
  programID: string | undefined = '';
  mentee: any;
  constructor(
    private modalService: NgbModal,
    private api: APIService,
    private menteeController: MenteeController,
    private programController: ProgramController,
    private activatedRoute: ActivatedRoute,
    private projectController: ProjectController
  ) { }

  ngOnInit() {
    const programID1 = this.activatedRoute.snapshot.paramMap.get('programId');
    const applicationID = this.activatedRoute.snapshot.paramMap.get('menteeApplicationId');
    if (programID1 && applicationID) {
      const getProgram = this.programController.getProgramByIdGraphql(programID1);
      const getApplication = from(this.api.GetMenteeApplication(applicationID));
      const getMilestones = this.menteeController.getMenteeMilestones({ userID: MENTEE_ID, programID: programID1 });
      this.isLoading = true;
      forkJoin([getProgram, getApplication, this.projectController.getAllProjects()]).subscribe(res => {
        this.handleGetProgramResponse(res[0]);
        this.handleGetMenteeApplication(res[1]);
        getMilestones.subscribe(milestones => {
          this.handleGetAllMilestones(milestones);
        }, error => {
          this.milestones = [];
        })
        this.isLoading = false;
      });
    }
  }

  private handleGetProgramResponse(program: any) {
    this.program = program;
    if (this.program.projectID) {
      this.program['project'] = this.projectController.getProjectById(this.program.projectID)
    }
    this.breadcrumbs.splice(1, 0, {
      name: this.program.programName,
      url: `/program/overview/${this.program.id}`
    });
    return this.program;
  }

  private handleGetMenteeApplication(menteeDetails: GetMenteeApplicationQuery) {
    this.mentee = menteeDetails?.mentee;
  }
  private handleGetAllMilestones(milestones: Array<any>) {
    this.milestones = milestones.filter(milestone => milestone.milestoneTasks && milestone.milestoneTasks.length);
    this.initBoard();
    this.getProgramProgress(this.milestones);
  }

  private initBoard() {
    this.todo = [];
    this.doing = [];
    this.done = [];
    const milestoneProgram = _.cloneDeep(this.program);
    milestoneProgram.mentees = milestoneProgram.mentees.filter((mentee: any) => mentee.applicationStatus === MenteeApplicationStatus.accepted);
    this.milestones.forEach((milestone, i) => {
      milestone.program = _.cloneDeep(milestoneProgram);
      milestone.completedCount = this.countCompletedTasks(milestone);
      milestone.milestoneTasks.forEach((task: any) => {
        task.taskDetail.comments = task.taskDetail.comments?.map((comment: any) => {
          const actorDetails = comment?.commentBy?.split("|");
          if (actorDetails && actorDetails.length > 1) {
            comment.actor = {
              type: actorDetails[0],
              name: actorDetails[1],
              image: actorDetails[2]
            };
          } else if (comment) {
            comment.actor = {
              type: comment?.commentBy,
              name: '',
              image: ''
            };
          }
          return comment;
        });
      });
      const isDone = milestone.milestoneTasks?.every((item: any) => item.taskDetail.status === taskStatus.completed);
      const isDoing = milestone.milestoneTasks?.some((item: any) => item.taskDetail.status === taskStatus.submitted);
      const isToDo = milestone.milestoneTasks?.every((item: any) => !item.taskDetail.status || item.taskDetail.status === taskStatus.pending);
      if (isDone) {
        this.done.push(milestone);
      } else if (isDoing) {
        this.doing.push(milestone);
      } else if (isToDo) {
        this.todo.push(milestone);
      }
    });
  }
  private countCompletedTasks(milestone: any) {
    if (milestone.milestoneTasks && milestone.milestoneTasks.length) {
      return milestone.milestoneTasks?.filter((task: any) => task?.taskDetail?.status === 'completed').length;
    }
    return 0;
  }

  getProgramProgress(milestones: any) {
    this.milestoneCount = milestones.length;
    let progress = 0;
    let tasksProgress = 0;
    this.milestoneProgramProgress = Math.round((this.done.length / (milestones.length)) * 100);
  }

  createNewMilestone() {
    const dialogRef = this.modalService.open(CreateMilestonePopupComponent, {
      size: "xl",
      centered: true
    });

    dialogRef.closed.subscribe(milestone => {
      milestone.tasks = [];
      this.milestones.push(milestone);
      this.initBoard();
    })
  }


  drop(event: CdkDragDrop<any[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      const milestone = _.cloneDeep(event.previousContainer.data[event.previousIndex]);
      this.updateMilestoneStatus(milestone, event.container.id);
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  private async updateMilestoneStatus(milestone: any, newStatus: string) {
    milestone.status = newStatus;
    milestone.dueDate = milestone.dueDate.getTime();
    delete milestone.program;
    delete milestone.tasks;
    const request = {
      id: milestone.id,
      status: newStatus
    };
    await this.api.UpdateMilestone(request);
  }

}
