import { MentorService } from './../../../core/services/mentor.service';
import { MentorInvitationStatus, ProgramStatus } from './../../../core/constants';
import { ProgramController } from './../../../core/controllers/program.controller';
import { ProjectController } from 'src/app/core/controllers/project.controller';
import { PaginationConfig, TableConfig } from './../../../core/models/table-config';
import { SkillsService } from './../../../core/services/skills.service';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { Utilities } from 'src/app/core/utilities';
import { Program } from 'src/app/models/program';
import { APIService } from 'src/app/API.service';
import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { from, of } from 'rxjs';
import { Router } from '@angular/router';
import { FilterPopupComponent } from 'src/app/shared/filter-popup/filter-popup.component';
import { GenericSearchPipe } from 'src/app/core/pipes/generic-search.pipe';
import * as _ from 'lodash';
import { TableColumnConfig } from 'src/app/core/models/table-column-config';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'lfx-program-list-view',
  templateUrl: './program-list-view.component.html',
  styleUrls: ['./program-list-view.component.scss']
})
export class ProgramListViewComponent implements OnInit, AfterViewInit {

  isLoading = false;
  isLoadMore = false;
  allPrograms = new Array<Program>();
  programs = new Array<Program>();
  utilities = new Utilities();
  filter: any = {};
  pagination = new PaginationConfig();
  @ViewChild('projectNameTemplate') projectNameTemplate!: TemplateRef<any>;
  @ViewChild('programTemplate') programTemplate!: TemplateRef<any>;
  @ViewChild('departmentTemplate') departmentTemplate!: TemplateRef<any>;
  @ViewChild('statusTemplate') statusTemplate!: TemplateRef<any>;
  @ViewChild('programActionsTemplate') programActionsTemplate!: TemplateRef<any>;
  @ViewChild('becameMentorTemplate') becameMentorTemplate!: TemplateRef<any>;
  @ViewChild('skillsTemplate') skillsTemplate!: TemplateRef<any>;
  myProgramsColumns = new Array<TableColumnConfig>()
  allProgramsColumns = new Array<TableColumnConfig>()
  myPendingColumns = new Array<TableColumnConfig>()
  programStatus = ProgramStatus;
  activePopover!: NgbPopover;
  tableView = false;
  tableConfig = new TableConfig();

  filterForm = new FormGroup({
    project: new FormArray([]),
    department: new FormControl(''),
    status: new FormControl('')
  });

  genericSearchPipe = new GenericSearchPipe();
  activeTabName = '';

  breadcrumbs = [
    {
      name: "programs",
      url: ''
    }
  ];
  constructor(
    private api: APIService,
    private mentorService: MentorService,
    private route: Router,
    private modalService: NgbModal,
    private skillsService: SkillsService,
    private projectController: ProjectController,
    private programController: ProgramController,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.pagination.numberOfElementsPerPage = 8;
    this.getAllPrograms();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.myProgramsColumns = [
      {
        title: 'Project',
        width: '15.5%',
        template: this.projectNameTemplate
      },
      {
        title: 'Program Name',
        width: '25%',
        template: this.programTemplate
      },
      {
        title: 'Department',
        width: '22%',
        template: this.departmentTemplate
      },
      {
        title: 'Status',
        width: '13%',
        isCenter: true,
        template: this.statusTemplate
      },
      {
        title: 'Required Skills and Technologies',
        width: '24.5%',
        template: this.skillsTemplate
      }
    ];
    this.myPendingColumns = [
      {
        title: 'Project',
        width: '15.5%',
        template: this.projectNameTemplate
      },
      {
        title: 'Program Name',
        width: '25%',
        template: this.programTemplate
      },
      {
        title: 'Department',
        width: '18%',
        template: this.departmentTemplate
      },
      {
        title: 'Status',
        width: '13%',
        isCenter: true,
        template: this.statusTemplate
      },
      {
        title: 'Required Skills and Technologies',
        width: '20%',
        template: this.skillsTemplate
      },
      {
        title: 'Actions',
        width: '8.5%',
        isCenter: true,
        template: this.programActionsTemplate
      }
    ];
    this.allProgramsColumns = [
      {
        title: 'Project',
        width: '15.5%',
        template: this.projectNameTemplate
      },
      {
        title: 'Program Name',
        width: '25%',
        template: this.programTemplate
      },
      {
        title: 'Department',
        width: '18%',
        template: this.departmentTemplate
      },
      {
        title: 'Status',
        width: '13%',
        isCenter: true,
        template: this.statusTemplate
      },
      {
        title: 'Required Skills and Technologies',
        width: '20%',
        template: this.skillsTemplate
      },
      {
        title: 'Actions',
        width: '8.5%',
        isCenter: true,
        template: this.becameMentorTemplate
      }
    ];
  }

  getAllPrograms() {
    from(this.programController.getAllProgramsGraphql()).subscribe((res: any) => {
      let { items, nextToken } = res;
      items = (items as Array<any>)?.map(item => {
        if (item?.projectID) {
          item['project'] = this.projectController.getProjectById(item?.projectID)
        }
        item.path = 'program/overview/' + item.id;
        return item;
      }).filter(program => program.status !== ProgramStatus.declined && program.status !== ProgramStatus.draft);
      this.allPrograms = items;
      this.changeActiveTab('myProgram');
      this.isLoadMore = false;
      this.isLoading = false;
    });
  }

  changeActiveTab(newTabName: string) {
    if (this.activeTabName === newTabName) {
      return;
    }
    this.isLoadMore = true;
    this.activeTabName = newTabName;
    this.tableConfig.isLoading = true;
    this.applyNameFilter(this.filter.programName);
    setTimeout(() => {
      this.tableConfig.isLoading = false;
      this.isLoadMore = false;
    }, 300);
  }


  openFilterDialog() {
    const dialogRef = this.modalService.open(FilterPopupComponent, {
      size: "lg",
      centered: true
    });
    const instance = dialogRef.componentInstance;
    instance.parentForm = this.filterForm;
    instance.tabsConfig = [
      {
        title: "Project",
        infoTitle: "Select Project",
        type: "searchableList",
        items: this.projectController.projects,
        controlName: "project",
        viewName: 'projectName'
      },
      {
        title: "Department",
        infoTitle: "Enter department name",
        type: "text",
        controlName: "department",
        search: (text: string) => {
          return [''];
        }
      },
      {
        title: "Status",
        infoTitle: "Select Status",
        type: "radio",
        items: this.getStatusList(),
        controlName: "status",
        search: (text: string): string[] => {
          return [""];
        }
      }
    ];

    dialogRef.closed.subscribe(result => {
      this.filterForm = result;
      this.generateFilterFromFilterForm();
      this.applyNameFilter(this.filter.programName);
    });
  }

  private getStatusList() {
    return Object.values(this.programStatus);
  }

  private generateFilterFromFilterForm() {
    const filterFormValue = this.filterForm.value;
    const programNameFilter = this.filter.programName;
    this.filter = {};
    this.filter.programName = programNameFilter;
    if (filterFormValue.project.length) {
      this.filter.projects = filterFormValue.project.map((project: any) => project.projectID);
    }
    if (filterFormValue.department.length) {
      this.filter.department = filterFormValue.department;
    }
    if (filterFormValue.status.length) {
      this.filter.status = filterFormValue.status;
    }
  }

  applyNameFilter(filteredText: string) {
    this.isLoadMore = true;
    this.filter.programName = filteredText;
    this.getDataBaseOnTab();
    this.programs = this.applyFilter();
    this.generatePages(this.programs);
    this.isLoadMore = false;
    this.isLoading = false;
  }

  getDataBaseOnTab() {
    const activeMentorId = this.mentorService.activeMentor?.id;
    switch (this.activeTabName) {
      case 'myProgram':
        this.programs = this.allPrograms.filter(program => {
          return program.mentors.filter(mentor => mentor.id === activeMentorId).length > 0;
        });
        this.tableConfig.columns = this.myProgramsColumns;
        break;
      case 'myPending':
        this.programs = this.allPrograms.filter(program => {
          return program.mentors.filter(mentor => mentor.id === activeMentorId && mentor.invitationStatus === MentorInvitationStatus.pending).length > 0;; // program.organizationID === myOrganizationId;
        });
        this.tableConfig.columns = this.myPendingColumns;
        break;
      default:
        this.programs = _.cloneDeep(this.allPrograms);
        this.tableConfig.columns = this.allProgramsColumns;
        break;
    }
  }

  private applyFilter() {
    let filterResult = _.cloneDeep(this.programs);
    if (this.filter.programName) {
      filterResult = this.genericSearchPipe.transform(filterResult, this.filter.programName, 'programName');
    }
    if (this.filter.projects) {
      filterResult = filterResult.filter(program => this.filter.projects.includes(program.projectID));
    }
    if (this.filter.department) {
      filterResult = filterResult.filter(program => program.departmentName?.toLowerCase().indexOf(this.filter.department.toLowerCase())! > -1);
    }
    if (this.filter.status) {
      filterResult = filterResult.filter(program => this.filter.status.toLowerCase() === program.status.toLowerCase());
    }
    return filterResult;
  }

  filterFun = (filterText: string, appliedFilter?: any) => {
    const searchResult = this.genericSearchPipe.transform(this.allPrograms, filterText, 'programName');
    return of({ items: searchResult.slice(0, 5), nextToken: searchResult.length > 5 })
  }

  private generatePages(programList: Array<Program>) {
    this.pagination.numberOfPages = Math.ceil(programList.length / this.pagination.numberOfElementsPerPage);
    this.pagination.activePageIndex = 1;
    this.updatePagination();
  }

  updatePagination() {
    const from = (this.pagination.activePageIndex - 1) * this.pagination.numberOfElementsPerPage;
    const to = this.pagination.activePageIndex * this.pagination.numberOfElementsPerPage;
    this.pagination.items = this.programs.slice(from, to > this.programs.length ? undefined : to);
  }

  openPopover(popover: NgbPopover) {
    this.activePopover = popover;
    if (this.activePopover && !this.activePopover.isOpen()) {
      this.activePopover.open();
    }
  }

  closePopover() {
    if (this.activePopover && this.activePopover.isOpen()) {
      this.activePopover.close();
    }
  }

  createNewProgram() {
    this.route.navigateByUrl('/program/create');
  }

  previewProject(programID: string) {
    this.route.navigateByUrl('/program/overview/' + programID);
  }

  approveProgram(programID: string) {
    this.updateMentorProgramStatus(MentorInvitationStatus.accepted, programID);
  }

  declineProgram(programID: string) {
    this.updateMentorProgramStatus(MentorInvitationStatus.declined, programID);

  }

  updateMentorProgramStatus(status: string, programID: string) {
    this.loadingService.startLoading();
    this.api.UpdateMentorProgram({
      id: this.mentorService.getMentorProgramByProgramId(programID)!.id,
      invitationStatus: status,
      dateAccepted: new Date().getTime()
    }).then(res => {
      this.loadingService.endLoading();
      const program = this.allPrograms[this.allPrograms.findIndex(program => programID === program.id)];
      program.mentors[program.mentors.findIndex(mentor => mentor.id === this.mentorService.activeMentor?.id)].invitationStatus = status;
      const activeTab = this.activeTabName;
      this.activeTabName = '';
      this.changeActiveTab(activeTab);
    }).catch(_ => {
      this.loadingService.endLoading();
    });
  }

}
