<div class="modal-header align-items-center">
  <h4 class="modal-title weight-600 size-20" id="modal-basic-title">Other Applications</h4>
  <i class="fa fa-times close-btn" aria-hidden="true" (click)="activeModal.dismiss('Cross click')"></i>
</div>
<div class="modal-body flex-unset d-flex flex-column">
  <app-common-table [tableConfiguration]="tableConfig" [tableRecords]="applications">
  </app-common-table>

</div>


<ng-template #programNameTemplate let-element>
  <span class="text-primary weight-600">
    {{element.rowData.program.programName}}
  </span>
</ng-template>

<ng-template #dateTemplate let-element>
  <span class="date-text mw-100 w-100" lfxEllipsis>
    {{element.rowData[element.columnConfig.key] | date:'mediumDate'}}
  </span>
</ng-template>

<ng-template #statusTemplate let-element>
  <span class="status mw-100 w-100" lfxEllipsis
    [ngClass]="{
      'pending-status': element.rowData[element.columnConfig.key].toLowerCase() === menteeApplicationStatus.pending.toLowerCase(),
      'accepted-status': element.rowData[element.columnConfig.key].toLowerCase() === menteeApplicationStatus.accepted.toLowerCase(),
      'declined-status': element.rowData[element.columnConfig.key].toLowerCase() === menteeApplicationStatus.declined.toLowerCase()}">
    {{element.rowData[element.columnConfig.key] | titlecase}}
  </span>
</ng-template>


<ng-template #actionTemplate let-element>
  <div class="w-100 mw-100 d-flex justify-content-center">
    <button type="button" class="btn btn-outline-primary py-1 px-3 mr-2" lfxEllipsis>
      message
    </button>
    <button type="button" class="btn btn-outline-primary py-1 px-3" lfxEllipsis>
      email
    </button>
  </div>
</ng-template>