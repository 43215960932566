<div class="pagination-container">
  <div class="pages">
    <span class="text mr-3">Page</span>
    <input class="form-control mr-2 text-primary" name="" id="" [max]="paginationConfig.numberOfPages" type="number"
      min="1" (keyup.enter)="jumpTo()" [(ngModel)]="paginationConfig.activePageIndex">
    <span class="text mr-3">{{'of '+
      (paginationConfig.numberOfPages < 10 ? '0' + paginationConfig.numberOfPages : paginationConfig.numberOfPages) }}
        </span>
  </div>
  <div class="actions">
    <i class="fa fa-caret-left" aria-hidden="true" [ngClass]="{'disabled': paginationConfig.activePageIndex <= 1}"
      (click)="paginationConfig.activePageIndex > 1 ? loadPreviousPage(): false"></i>
    <i class="fa fa-caret-right" aria-hidden="true"
      [ngClass]="{'disabled': paginationConfig.activePageIndex >= paginationConfig.numberOfPages}"
      (click)="paginationConfig.activePageIndex < paginationConfig.numberOfPages ? loadNextPage(): false"></i>
  </div>

</div>