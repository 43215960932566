import { TaskManagementComponent } from './task-management/pages/task-management/task-management.component';
import { ProgramListViewComponent } from './mentorship/pages/program-list-view/program-list-view.component';
import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/pages/dashboard/dashboard.component';
import { ProgramOverviewComponent } from './mentorship/pages/program-overview/program-overview.component';
import { MentorListComponent } from './dashboard/pages/mentor-list/mentor-list.component';
import { MentorReviewApplicationComponent } from './mentorship/pages/mentor-review-application/mentor-review-application.component';
import { MenteeTaskManagementComponent } from './task-management/pages/mentee-task-management/mentee-task-management.component'
import { MentorsPageComponent } from './dashboard/pages/mentors-page/mentors-page.component';
import { MenteesPageComponent } from './dashboard/pages/mentees-page/mentees-page.component';
import { ProgramMenteeApplicationComponent } from './mentorship/pages/program-mentee-application/program-mentee-application.component';
import { AuthGuard } from '@auth0/auth0-angular';

const routes: Routes = [
  {
    component: DashboardComponent,
    path: '',
    canActivate: [AuthGuard],
  },
  {
    path: 'mentors',
    component: MentorsPageComponent,
  },
  {
    path: 'mentors-list',
    component: MentorListComponent,
  },
  {
    path: 'mentees',
    component: MenteesPageComponent
  },
  {
    path: 'programs',
    component: ProgramListViewComponent,
    canActivate: [AuthGuard],
  },
  {
    component: ProgramOverviewComponent,
    path: 'program/overview/:programId',
    canActivate: [AuthGuard],
  },
  {
    component: MentorReviewApplicationComponent,
    path: 'program/:programId/review/:mentorReviewId',
    canActivate: [AuthGuard],
  },
  {
    component: TaskManagementComponent,
    path: 'program/milestone',
    canActivate: [AuthGuard],
  },
  {
    component: ProgramMenteeApplicationComponent,
    path: 'program/:programId/applications',
    canActivate: [AuthGuard],
  },
  {
    component: MenteeTaskManagementComponent,
    path: 'program/:programId/menteeApplicationId/:menteeApplicationId',
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
