import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<Date> {

    readonly DELIMITER = '-';

    fromModel(value: Date | null): NgbDateStruct | null {
        if (value) {
            // let date = value.split(this.DELIMITER);
            return {
                day: value.getDate(),
                month: value.getMonth() + 1,
                year: value.getFullYear()
            };
        }
        return null;
    }

    toModel(date: NgbDateStruct | null): Date | null {
        if (date) {
            return new Date(`${date.month}-${date.day}-${date.year}`)
        }
        return null//date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
    }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

    readonly DELIMITER = '/';

    parse(value: string): NgbDateStruct | null {
        if (value) {
            let date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[2], 10)
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        return date ? ("00" + date.day).slice(-2) + this.DELIMITER + ("00" + date.month).slice(-2) + this.DELIMITER + ("00" + date.year).slice(-2) : '';
    }
}