import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GenericSearchPipe } from 'src/app/core/pipes/generic-search.pipe';
import { from, of } from 'rxjs';
import * as _ from 'lodash';
import { PaginationConfig, TableConfig } from 'src/app/core/models/table-config';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterPopupComponent } from 'src/app/shared/filter-popup/filter-popup.component';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { TableColumnConfig } from 'src/app/core/models/table-column-config';
import { MenteeApplicationStatus, ProgramStatus } from './../../../core/constants';
import { APIService } from 'src/app/API.service';
import { Mentee } from 'src/app/models/mentee';

@Component({
  selector: 'lfx-mentees-page',
  templateUrl: './mentees-page.component.html',
  styleUrls: ['./mentees-page.component.scss']
})
export class MenteesPageComponent implements OnInit {

  @ViewChild('menteeNameTemplate') menteeNameTemplate!: TemplateRef<any>;
  @ViewChild('programTemplate') programTemplate!: TemplateRef<any>;
  @ViewChild('statusTemplate') statusTemplate!: TemplateRef<any>;
  @ViewChild('actionTemplate') actionTemplate!: TemplateRef<any>;

  breadcrumbs = [
    {
      name: "Mentees",
      url: '/mentees'
    }
  ];
  isLoadMore = true;
  menteeStatus = MenteeApplicationStatus;
  genericSearchPipe = new GenericSearchPipe();
  tableConfig: TableConfig = {
    xPadding: '20px',
    colXPadding: '20px',
    columns: new Array<TableColumnConfig>(),
    isLoading: true,
    error: false
  };
  filter: any = {};
  pagination = new PaginationConfig();
  filterForm = new FormGroup({
    program: new FormArray([])
  });

  allMentees = new Array<Mentee>();
  mentees = new Array<Mentee>();
  showAll = -1;
  tableView = true;


  constructor(private modalService: NgbModal,
    private api: APIService) { }

  ngOnInit(): void {
    this.isLoadMore = true;
    this.tableConfig.isLoading = true;
    from(this.api.ListMentees()).subscribe(res => {
      this.allMentees = res.items?.map((mentee: any) => {
        mentee.name = mentee.firstName + mentee.lastName;
        mentee.menteeApplications = mentee.menteeApplications.items.map((app: any) => {
          app.program.mentors = app.program.mentors.items.map((item: any) => {
            const mentor: any = _.cloneDeep(item!.mentor);
            mentor.invitationStatus = item?.invitationStatus
            mentor.emailMessage = item?.emailMessage
            mentor.dateAccepted = item?.dateAccepted
            mentor.name = mentor.firstName + ' ' + mentor.lastName;
            return mentor;
          });
          return app;
        });
        mentee.status = mentee?.menteeApplications[mentee?.menteeApplications.length - 1].applicationStatus;
        return mentee;
      }) as any;
      this.mentees = _.cloneDeep(this.allMentees);
      this.generatePages(this.mentees);
      this.isLoadMore = false;
      this.tableConfig.isLoading = false;
    });
  }

  ngAfterViewInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
    this.tableConfig.columns = [
      {
        title: 'Name',
        width: '26%',
        template: this.menteeNameTemplate
      },
      {
        title: 'Program Name',
        width: '35%',
        template: this.programTemplate,
      },
      {
        title: 'Status',
        width: '25%',
        template: this.statusTemplate,
        isCenter: true,
        key: 'status'
      },
      {
        title: 'Contact',
        width: '14%',
        template: this.actionTemplate,
        isCenter: true,
      }
    ];
  }

  openFilterDialog() {
    const dialogRef = this.modalService.open(FilterPopupComponent, {
      size: "lg",
      centered: true
    });
    const instance = dialogRef.componentInstance;
    instance.parentForm = this.filterForm;
    instance.tabsConfig = [
      {
        title: "Program",
        infoTitle: "Select program",
        type: "searchableList",
        items: this.getAllUsedPrograms(),
        controlName: "program",
        viewName: 'programName'
      },
    ];

    dialogRef.closed.subscribe(result => {
      this.filterForm = result;
      this.generateFilterFromFilterForm();
      this.applyNameFilter(this.filter.name);
    });
  }

  private getAllUsedPrograms() {
    let programs = new Array();
    this.allMentees.forEach(mentee => {
      programs.push(...mentee.menteeApplications?.map(app => app.program)!);
    });
    return programs;
  }

  private generateFilterFromFilterForm() {
    const filterFormValue = this.filterForm.value;
    const nameFilter = this.filter.name;
    this.filter = {};
    this.filter.name = nameFilter;
    if (filterFormValue.program.length) {
      this.filter.programs = filterFormValue.program.map((program: any) => program.programName);
    }
  }

  applyNameFilter(filteredText: string) {
    this.isLoadMore = true;
    this.filter.name = filteredText;
    this.mentees = this.applyFilter();
    this.generatePages(this.mentees);
    this.isLoadMore = false;
  }

  private applyFilter() {
    let filterResult = _.cloneDeep(this.allMentees);
    if (this.filter.name) {
      filterResult = this.genericSearchPipe.transform(filterResult, this.filter.name, 'name');
    }
    if (this.filter.programs) {
      filterResult = filterResult.filter(mentee => mentee.menteeApplications?.map(app => app.program.programName).some(programName => this.filter.programs.includes(programName)));
    }
    return filterResult;
  }

  filterFun = (filterText: string, appliedFilter?: any) => {
    const searchResult = this.genericSearchPipe.transform(this.allMentees, filterText, 'name');
    return of({ items: searchResult.slice(0, 5), nextToken: searchResult.length > 5 })
  }

  private generatePages(mentees: Array<Mentee>) {
    this.pagination.numberOfPages = Math.ceil(mentees.length / this.pagination.numberOfElementsPerPage);
    this.pagination.activePageIndex = 1;
    this.updatePagination();
  }

  updatePagination() {
    const from = (this.pagination.activePageIndex - 1) * this.pagination.numberOfElementsPerPage;
    const to = this.pagination.activePageIndex * this.pagination.numberOfElementsPerPage;
    this.pagination.items = this.mentees.slice(from, to > this.mentees.length ? undefined : to);
  }

  showProjects(vals: any) {
    if (this.showAll === vals) {
      this.showAll = -1;
    } else {
      this.showAll = vals;
    }
  }

}
