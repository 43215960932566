<div class="modal-header align-items-center">
  <h4 class="modal-title weight-600 size-20" id="modal-basic-title">Create MileStone</h4>
</div>
<div class="modal-body flex-unset d-flex flex-column p-0">
  <form class="form-container d-flex flex-column p-4" [formGroup]="milestoneForm">
    <div class="d-flex align-items-center">
      <div class="col-3 pl-0 mb-3">
        <label class="input-title text-muted" for="milestone-name">Name <span class="text-danger">*</span></label>
        <input type="text" formControlName="name" [placeholder]="'Enter program name'" class="form-control"
          name="milestone-name">
      </div>
      <div class="col-3 pr-0 mb-3">
        <label class="input-title text-muted" for="program-name">Program Name <span class="text-danger">*</span></label>
        <lfx-auto-complete-dropdown [dropDownId]="'program-name'" [parentForm]="milestoneForm" [controlName]="'program'"
          name="program-name" class="d-flex w-100" [dropdownPlaceholder]="'Select program'" [items]="programs"
          [searchFieldName]="'programName'">
        </lfx-auto-complete-dropdown>
      </div>
      <div class="col-3 pr-0 mb-3">
        <label class="input-title text-muted" for="program-term">Program Term <span class="text-danger">*</span></label>
        <lfx-auto-complete-dropdown [dropDownId]="'program-term'" [parentForm]="milestoneForm" [controlName]="'term'"
          [isDisabled]="program?.value === ''" name="program-term" class="d-flex w-100"
          [dropdownPlaceholder]="'Select term'" [items]="program?.value?.terms" [searchFieldName]="'termName'">
        </lfx-auto-complete-dropdown>
      </div>
      <div class="form-group pr-0 col-3">
        <label for="due-date">Due Date</label>
        <input name="dp" formControlName="dueDate" [minDate]="utilities.convertDateToNgbDate(minMilestoneDueDate)"
          [maxDate]="utilities.convertDateToNgbDate(maxMilestoneDueDate)" ngbDatepicker #dueDate="ngbDatepicker"
          [placement]="['bottom', 'top']" class="form-control date-input" readonly name="due-date"
          (click)="dueDate.toggle()" id="due-date" placeholder="DD/MM/YY">
        <i class="fas fa-calendar-alt" (click)="dueDate.toggle()" *ngIf="!milestoneForm.get('dueDate')?.value"></i>
        <i class="fas fa-times" (click)="clearDate('dueDate')" *ngIf="milestoneForm.get('dueDate')?.value"></i>

      </div>
    </div>
    <div class="form-group">
      <label for="milestone-description">Milestone Description <span class="text-danger">*</span></label>
      <quill-editor formControlName="description" [styles]="{height: '200px'}" name="milestone-description"
        id="milestone-description">
        <div quill-editor-toolbar>
          <span class="ql-formats">
            <select class="ql-size">
              <option value="small"></option>
              <option selected></option>
              <option value="large"></option>
              <option value="huge"></option>
            </select>
          </span>
          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
          </span>
          <span class="ql-formats">
            <select class="ql-color"></select>
            <select class="ql-background"></select>
          </span>
          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <select class="ql-align">
              <option selected></option>
              <option value="center"></option>
              <option value="right"></option>
              <option value="justify"></option>
            </select>
          </span>
          <span class="ql-formats">
            <button class="ql-link"></button>
            <button class="ql-image"></button>
          </span>
        </div>
      </quill-editor>
    </div>
  </form>
  <div class="d-flex align-items-center mt-auto mb-4 mr-3">
    <button type="button" class="btn btn-outline-secondary custom-btn ml-auto"
      (click)="activeModal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary custom-btn ml-3" [disabled]="milestoneForm.invalid"
      (click)="createMilestone()">Create</button>
  </div>
</div>