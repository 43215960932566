<div class="card-container" (click)="milestone.milestoneTasks?.length ? createNewTask() : false">
  <div class="d-flex align-items-center justify-content-between">
    <span class="milestone-name weight-600 size-12">{{'milestone: ' + milestone.milestoneName}}</span>
    <span class="due-date size-12" *ngIf="!isDueDatePassed">
      Due on {{milestone.dueDate | date: 'MMM d'}}
      (<span class="remain-days">{{getRemainDays()+' Days Left'}}</span>)
    </span>
    <span class="remain-days size-12" *ngIf="isDueDatePassed">
      Due date ended on {{milestone.dueDate | date: 'MMM d'}}
    </span>
  </div>
  <quill-view-html class="d-flex description mt-2 mb-5" [content]="milestone.description" theme="snow">
  </quill-view-html>
  <div class="w-100" *ngIf="!generalView">
    <ngb-progressbar type="success" class="mb-2" height="15px" [value]="milestone.progress" [showValue]="true">
    </ngb-progressbar>
  </div>
  <ng-container *ngIf="!milestone.milestoneTasks?.length; else milestoneProgress">
    <button type="button" class="btn btn-primary custom-btn d-flex ml-auto" (click)="createNewTask()">Add Task</button>
  </ng-container>
  <ng-template #milestoneProgress>
    <!-- <ngb-progressbar type="primary" class="mb-2" [value]="75"></ngb-progressbar> -->
    <div class="d-flex justify-content-end align-items-center completed-tasks-count">
      <span class="size-12 weight-600">{{milestone.completedCount + ' of '+ milestone.milestoneTasks.length}}</span>
      <span>&nbsp;Tasks Completed</span>
    </div>
  </ng-template>

</div>