import { TableColumnConfig } from "./table-column-config";

export class TableConfig {
    xPadding: string = '30px';
    colXPadding: string = '20px';
    columns: Array<TableColumnConfig> = new Array<TableColumnConfig>();
    isLoading?: boolean = false;
    error?: boolean = false;

    constructor() {

    }
}

export class PaginationConfig {
    numberOfPages: number;
    numberOfElementsPerPage: number;
    activePageIndex: number;
    items: Array<any>;

    constructor() {
        this.numberOfPages = 1;
        this.numberOfElementsPerPage = 12;
        this.activePageIndex = 1;
        this.items = new Array();
    }
}