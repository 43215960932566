import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APIService } from './../../../API.service';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { mentees } from '../../mentees';
import { taskStatus, MilestoneActivityActionType } from 'src/app/core/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from 'src/app/core/models/selection.model';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { MentorService } from 'src/app/core/services/mentor.service';
import { from, interval } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'lfx-milestone-details',
  templateUrl: './milestone-details.component.html',
  styleUrls: ['./milestone-details.component.scss']
})
export class MilestoneDetailsComponent implements OnInit {

  @Input() milestone!: any;
  @Input() currentMentee: any;
  @Input() generalView!: boolean;
  selection = new SelectionModel(new Array());
  isEditMilestone = false;
  updatedDescription = '';
  submissionActivities = new Array();
  allTaskCompletes: boolean = false;
  taskStatus = taskStatus;
  activeIndex: number = -1;
  checkBoxValue: any = true;
  feedbackForAdmin = '';
  finalDecision = 'Pass';
  done = false;
  doing = false;
  todo = false;
  refreshCom = false;
  constructor(
    private api: APIService,
    public activeModal: NgbActiveModal,
    private mentorService: MentorService,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    this.done = this.milestone.milestoneTasks?.every((item: any) => item.taskDetail?.status === taskStatus.completed);
    this.todo = this.milestone.milestoneTasks?.every((item: any) => !item.taskDetail?.status || item.taskDetail?.status === taskStatus.pending);
    this.doing = this.milestone.milestoneTasks?.some((item: any) => item.taskDetail?.status === taskStatus.completed);
    const menteeTaskIds = new Array<string>();
    this.milestone.milestoneTasks.forEach((task: any) => {
      menteeTaskIds.push(task.taskDetail?.menteeTaskID);
    });
    this.getListMilestoneActionLogs(this.milestone.id, menteeTaskIds);
  }

  getListMilestoneActionLogs(milestoneId: any, menteeTaskIds: Array<string>) {
    const filter: any = { milestoneID: { eq: milestoneId }, or: new Array() };
    menteeTaskIds.forEach(id => filter.or.push({ menteeTaskID: { eq: id } }));
    if (this.generalView) {
      delete filter.or;
    }
    from(this.api.ListMilestoneActionLogs(filter, 1000)).subscribe(res => {
      this.submissionActivities = _.orderBy(res.items as any, 'createdAt', 'asc')
      this.submissionActivities.map(activity => {
        const actorDetails = activity.actor.split("|");
        if (actorDetails.length > 1) {
          activity.actor = {
            type: actorDetails[0],
            name: actorDetails[1],
            image: actorDetails[2]
          };
        } else {
          activity.actor = {
            type: actorDetails[0],
            name: '',
            image: ''
          };
        }
      });
    });
  }

  editDescription() {
    this.isEditMilestone = true;
    this.updatedDescription = this.milestone.description;
  }

  updateMilestoneDescription() {
    this.api.UpdateMilestone({
      id: this.milestone.id,
      description: this.updatedDescription
    }).then(res => {
      this.milestone.description = this.updatedDescription;
      this.updatedDescription = '';
      this.isEditMilestone = false;
    });
  }

  getHoursFrom(timestamp: any) {
    const date = new Date(timestamp);
    const diff = moment().diff(moment(date.getTime()), 'hours');
    return diff;
  }

  toggleSection(ev: any, index: number) {
    const target = ev.target;
    target.classList.toggle("active");
    this.activeIndex = index; // close
    const panel: any = document.querySelector(`#lfx-pre-panel-${index}`);
    if (panel?.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

  cancel(index: number) {
    const input: any = document.querySelector(`#txt-input${index}`);
    const div: any = document.querySelector(`#txt-read${index}`);
    div.style.display = 'none';
    input.style.display = '';
    input.value = div.innerText;
  }

  addComment(task: any, commentText: string) {
    const mentor = `mentor|${this.mentorService.activeMentor!.firstName + ' ' + this.mentorService.activeMentor!.lastName}|${this.mentorService.activeMentor!.imageUrl}`;
    let comment = {
      commentBy: mentor,
      commentText,
      createdAt: new Date().getTime()
    };
    let oldComments = _.cloneDeep(task.taskDetail.comments);
    let allComments = [];
    if (oldComments !== null) {
      oldComments.forEach((comment: any) => {
        delete comment.actor;
      });
      allComments.push(...oldComments);
    }
    allComments.push(comment);
    this.UpdateMenteeTaskComment(task, allComments);
    this.updateMilestoneActivityLogs(task, commentText, MilestoneActivityActionType.comment);
  }

  updateTaskCompleted(index: number, row: any) {
    const input: any = document.querySelector(`#txt-taskName${index}`);
    this.allTaskCompletes = !this.allTaskCompletes;
    if (this.allTaskCompletes) {
      (document.querySelector(`#taskName${index}`) as HTMLElement).style.textDecoration = "line-through";
      row.taskDetail.status = taskStatus.completed;
      row.taskDetail.progress = 100;
    } else {
      (document.querySelector(`#taskName${index}`) as HTMLElement).style.textDecoration = "none";
      row.taskDetail.status = taskStatus.submitted;
      row.taskDetail.progress = 0;
    }
    this.UpdateMenteeTaskStatus(row);
  }

  async UpdateMenteeTaskStatus(row: any) {
    await this.api.UpdateMenteeTask({
      id: row.taskDetail.menteeTaskID,
      status: row.taskDetail.status,
      progress: row.taskDetail.progress
    }).then((res) => {

    });
  }

  async UpdateMenteeTaskComment(row: any, comments: any) {
    await this.api.UpdateMenteeTask({
      id: row.taskDetail.menteeTaskID,
      status: row.taskDetail.status,
      progress: row.taskDetail.progress,
      comments: comments
    }).then((res) => {
      row.taskDetail.comments = res.comments?.map((comment: any) => {
        const actorDetails = comment?.commentBy?.split("|");
        if (actorDetails && actorDetails.length > 1) {
          comment.actor = {
            type: actorDetails[0],
            name: actorDetails[1],
            image: actorDetails[2]
          };
        } else {
          comment.actor = {
            type: actorDetails[0],
            name: '',
            image: ''
          };
        }
        return comment;
      });
    });
  }

  acceptOrDecline() {
    if (this.checkBoxValue) {
      this.finalDecision = "Fail";
      this.checkBoxValue = false;
    } else {
      this.finalDecision = "Pass";
      this.checkBoxValue = true;
    }
  }

  UpdateMilestoneStatusForMentee(milestone: any) {
    milestone.forEach((task: any) => {
      this.updateTaskStatus(task);
      if (this.finalDecision == 'Pass') {
        task.taskDetail.status = taskStatus.completed;
        task.taskDetail.progress = 100;
      } else {
        task.taskDetail.status = taskStatus.declined;
        task.taskDetail.progress = 0;
      }
    });
    this.updateMilestoneFeedbackForAdmin();
  }

  updateTaskStatus(task: any) {
    this.loadingService.startLoading();
    this.api.UpdateMenteeTask({
      id: task.taskDetail.menteeTaskID,
      status: taskStatus.completed,
      progress: task.taskDetail.progress
    }).then((res) => {
      this.loadingService.endLoading();
      console.log("res for updating milestone status to completed for all tasks", res);
    });
  }

  updateMilestoneFeedbackForAdmin() {
    this.loadingService.startLoading();
    this.api.UpdateMilestone({
      id: this.milestone.id,
      evaluation: [{
        status: this.finalDecision === 'Pass' ? taskStatus.completed : taskStatus.declined,
        feedBackToProgramAdmin: this.feedbackForAdmin,
        menteeID: this.currentMentee.id,
        createdAt: new Date().getTime()
      }]
    }).then((res) => {
      this.loadingService.endLoading();
      console.log("feedback from mentor to admin for the mentee task milestone:", res);
    });
  }

  updateMilestoneActivityLogs(milestone: any, actionVal: string, activityType: string) {
    this.loadingService.startLoading();
    const mentor = `mentor|${this.mentorService.activeMentor!.firstName + ' ' + this.mentorService.activeMentor!.lastName}|${this.mentorService.activeMentor!.imageUrl}`;
    this.api.CreateMilestoneActionLog({
      milestoneID: milestone.milestoneID,
      menteeTaskID: milestone.taskDetail.menteeTaskID,
      createdAt: new Date().getTime(),
      actor: mentor,
      actorID: this.mentorService.activeMentor!.id,
      action: actionVal,
      actionType: activityType
    }).then((activity: any) => {
      const actorDetails = activity.actor!.split("|");
      if (actorDetails.length > 1) {
        activity.actor = {
          type: actorDetails[0],
          name: actorDetails[1],
          image: actorDetails[2]
        };
      } else {
        activity.actor = {
          type: actorDetails[0],
          name: '',
          image: ''
        };
      }
      this.submissionActivities.push(activity as any);
      this.loadingService.endLoading();
    });
  }

  readOnlyCheckBox() {
    return false;
  }

}
