import { FormArray, FormControl } from '@angular/forms';
export class SelectionModel<T> {
    selected: T[] = new Array();
    selectedFormArray?: FormArray;
    constructor(initiallySelectedValues: T[], form?: FormArray) {
        this.selected = initiallySelectedValues;
        if (form) {
            this.selectedFormArray = new FormArray(initiallySelectedValues as any);
            this.selectedFormArray = form;
        }
    }

    select(value: T): void {
        if (this.isSelected(value)) {
            return;
        }
        this.selected.push(value);
        if (this.selectedFormArray) {
            this.selectedFormArray?.push(new FormControl(value));
        }
    };
    /**
     * Deselects a value or an array of values.
     */
    deselect(value: T): void {
        if (!this.isSelected(value)) {
            return;
        }
        const elementIndex = this.selected.findIndex(item => item === value);
        this.selected.splice(elementIndex, 1);
        if (this.selectedFormArray) {
            this.selectedFormArray?.removeAt(elementIndex);
        }
    }
    /**
     * Toggles a value between selected and deselected.
     */
    toggle(value: T): void {
        if (this.isSelected(value)) {
            this.deselect(value);
            return;
        }
        this.select(value);
    }
    /**
     * Clears all of the selected values.
     */
    clear(): void {
        this.selected = [];
        if (this.selectedFormArray) {
            this.selectedFormArray.reset();
            this.selectedFormArray.removeAt(0);
        }
    }
    /**
     * Determines whether a value is selected.
     */
    isSelected(value: T): boolean {
        return !!this.selected.filter(item => item === value).length;
    }
    /**
     * Determines whether the model does not have a value.
     */
    isEmpty(): boolean {
        return !this.selected.length
    }
    /**
     * Determines whether the model has a value.
     */
    hasValue(): boolean {
        return !!this.selected.length
    }

}