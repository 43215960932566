<ng-container *ngIf="!isLoading; else loadingTemplate">
  <div class="program-list-container px-80">
    <lfx-breadcrumbs [breadcrumbs]="breadcrumbs"></lfx-breadcrumbs>
    <div class="d-flex align-items-center flex-column-sm">
      <lfx-card-number-view class="col-lg-3 col-sm-12 flex-unset mb-3 mr-3 px-0" [cardValue]="0"
        [cardText]="'Ongoing Mentorships'">
      </lfx-card-number-view>
      <lfx-card-number-view class="col-lg-3 col-sm-12 flex-unset mb-3 mr-3 px-0" [cardValue]="0"
        [cardText]="'Completed Mentorships'">
      </lfx-card-number-view>
      <lfx-card-number-view class="col-lg-3 col-sm-12 flex-unset mb-3 mr-3 px-0" [cardValue]="0"
        [cardText]="'Participating Mentees'">
      </lfx-card-number-view>
      <lfx-card-number-view class="col-lg-3 col-sm-12 flex-unset mb-3 px-0" [cardValue]="0"
        [cardText]="'Participating Mentors'">
      </lfx-card-number-view>
    </div>

    <div class="card flex-grow-1 mb-5">
      <div class="card-header d-flex pl-0 pb-0">
        <div class="tab" [ngClass]="{'active': activeTabName === 'myProgram'}" (click)="changeActiveTab('myProgram')">
          My Program
        </div>
        <div class="tab" [ngClass]="{'active': activeTabName === 'allProgram'}" (click)="changeActiveTab('allProgram')">
          All Programs
        </div>
        <div class="tab" [ngClass]="{'active': activeTabName === 'myPending'}" (click)="changeActiveTab('myPending')">
          My Pending Invitation
        </div>
        <div class="tab-spacer"></div>
        <div class="actions">
          <lfx-input-search [filterFun]="filterFun" class="mr-3" (filter)="applyNameFilter($event)"></lfx-input-search>
          <button type="button" class="btn btn-primary default-border-radius mr-3" (click)="openFilterDialog()">
            <i class="fa fa-filter" aria-hidden="true"></i>
          </button>
          <button type="button" class="btn btn-primary custom-btn">Propose a Program</button>
          <div class="switch-view">
            <i class="fas fa-th-list" [ngClass]="{'active': tableView}" (click)="tableView = true"></i>
            <i class="fas fa-th-large" [ngClass]="{'active': !tableView}" (click)="tableView = false"></i>
          </div>
        </div>
      </div>
      <div class="card-body">
        <ng-container *ngIf="!isLoadMore && pagination.items!.length > 0; else loadingTemplate">
          <div class="grid-container" *ngIf="tableView === false">
            <lfx-program-detail-card *ngFor="let program of pagination.items" [program]="program">
            </lfx-program-detail-card>
          </div>
          <app-common-table *ngIf="tableView === true" [tableConfiguration]="tableConfig"
            [tableRecords]="pagination.items"></app-common-table>
          <lfx-pagination [paginationConfig]="pagination" (paginationChange)="updatePagination()" class="pt-4 mt-auto">
          </lfx-pagination>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>


<ng-template #loadingTemplate>
  <div *ngIf="!isLoadMore && !isLoading"
    class="size-20 weight-bold d-flex flex-grow-1 w-100 align-items-center justify-content-center">
    No records found
  </div>
  <lfx-loading *ngIf="isLoadMore || isLoading" class="flex-grow-1"></lfx-loading>
</ng-template>


<ng-template #projectNameTemplate let-element>
  <span class="text-black weight-600" lfxEllipsis>
    {{element.rowData?.project?.projectName}}
  </span>
</ng-template>

<ng-template #departmentTemplate let-element>
  <span class="text-gray-1" lfxEllipsis>
    {{element.rowData?.departmentName}}
  </span>
</ng-template>

<ng-template #becameMentorTemplate let-element>
  <div class="d-flex justify-content-center">
    <button type="button" class="btn btn-outline-primary" lfxEllipsis>Became a Mentor</button>
  </div>
</ng-template>

<ng-template #reminderTemplate let-element>
  <div class="d-flex justify-content-center w-100 h-100" (mouseleave)="closePopover()">
    <i class="fas fa-envelope text-primary" [ngbPopover]="reminderEmailsTemplate" #emailPopover="ngbPopover"
      (mouseenter)="openPopover(emailPopover)" [placement]="['bottom', 'top']" triggers="manual"></i>
    <ng-template #reminderEmailsTemplate>
      <span class="reminder-text"><i class="fas fa-info-circle mr-2"></i> Program Invitation to Mentor</span>
      <span class="reminder-text"><i class="fas fa-info-circle mr-2"></i> Program Invitation to Co-Admin</span>
      <span class="reminder-text"><i class="fas fa-info-circle mr-2"></i> Review Mentee Application</span>
      <span class="reminder-text"><i class="fas fa-info-circle mr-2"></i> Review Program Submissions</span>
    </ng-template>
  </div>

</ng-template>


<ng-template #programActionsTemplate let-element>
  <div class="d-flex justify-content-center align-items-center w-100">
    <i class="fas fa-check text-success mr-3 cursor-pointer size-20" (click)="approveProgram(element.rowData.id)"></i>
    <i class="fas fa-times text-danger ml-1 cursor-pointer size-20" (click)="declineProgram(element.rowData.id)"></i>
  </div>
</ng-template>

<ng-template #skillsTemplate let-element>
  <div class="d-flex justify-content-start pl-3 flex-wrap">
    <div class="skill mr-2 " lfxEllipsis
      *ngFor="let skill of element.rowData.programDetail?.requiredSkillsAndTechnologies | slice: 0:3; index as i">
      {{ skill }}
    </div>
    <small class="skill"
      *ngIf="element.rowData.programDetail?.requiredSkillsAndTechnologies && element.rowData.programDetail.requiredSkillsAndTechnologies.length > 3"
      (mouseleave)="closePopover()">
      <span [ngbPopover]="listViewerTemplate" #skillsList="ngbPopover" (mouseenter)="openPopover(skillsList)"
        [placement]="['bottom', 'top']" triggers="manual" popoverClass="custom-popover">
        +{{ element.rowData.programDetail.requiredSkillsAndTechnologies.length - 3 }}
      </span>
    </small>
  </div>
  <ng-template #listViewerTemplate>
    <div class="selected-items-popover-item ellipsis"
      *ngFor="let skill of element.rowData.programDetail?.requiredSkillsAndTechnologies | slice: 3">
      {{ skill }}
    </div>
  </ng-template>
</ng-template>

<ng-template #programTemplate let-element>
  <span class="d-flex align-items-center text-primary w-100 mw-100 cursor-pointer">
    <span (click)="previewProject(element.rowData.id)" lfxEllipsis>{{element.rowData.programName}}</span>
  </span>
</ng-template>


<ng-template #statusTemplate let-element>
  <span class="status mw-100 w-100 d-flex align-items-center justify-content-center" lfxEllipsis [ngClass]="{
    'inprogress-status': element.rowData.status.toLowerCase() === programStatus.pending.toLowerCase(),
    'completed-status': element.rowData.status.toLowerCase() === programStatus.completed.toLowerCase(),
    'declined-status': element.rowData.status.toLowerCase() === programStatus.declined.toLowerCase(),
    'accepted-status': element.rowData.status.toLowerCase() === programStatus.acceptingApplication.toLowerCase() || element.rowData.status.toLowerCase() === programStatus.accepted.toLowerCase()
  }">
    {{element.rowData.status | titlecase}}
  </span>
</ng-template>