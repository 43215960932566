<div class="review-page-container px-80" *ngIf="!isLoading; else loadingTemplate">
  <lfx-breadcrumbs [breadcrumbs]="breadcrumbs"></lfx-breadcrumbs>
  <lfx-program-overview-header [program]="program"></lfx-program-overview-header>
  <div class="info-tile">
    <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8 d-flex align-items-center">
      <img class="user-image mr-4"
        [src]="mentorReview.menteeApplication.mentee?.imageUrl ? mentorReview.menteeApplication.mentee?.imageUrl :'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/'+mentorReview.menteeApplication.mentee?.firstName.toLowerCase().charAt(0) +'.png'"
        alt="">
      <div class="d-flex flex-column text-light">
        <span>Name</span>
        <span class="size-28 weight-600">
          {{mentorReview.menteeApplication.mentee?.firstName + ' ' + mentorReview.menteeApplication.mentee?.lastName}}
        </span>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-end col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <button type="button" class="btn btn-light text-primary px-3 py-1 mr-2"><i class="fa fa-user"
          aria-hidden="true"></i>
        &nbsp;View Profile</button>
      <button type="button" class="btn btn-light text-primary px-3 py-1"><i class="fa fa-envelope"
          aria-hidden="true"></i>
        &nbsp; Contact</button>
    </div>
    <div class="dark-layer">
    </div>
  </div>
  <span class="text-gray-header size-24 weight-bold mb-3">Status</span>
  <div class="card-header py-4 px-3">
    <div class="d-flex align-items-center card-content py-4 px-3">
      <span class="size-20 text-dark mr-5">Application Status:</span>
      <span class="status pending-status size-18">{{mentorReview?.menteeApplication.applicationStatus}}</span>
    </div>
  </div>

  <div class="card my-4">
    <div class="card-header text-gray-header size-24 weight-bold p-3">
      Review Submissions
    </div>
    <div class="card-body">
      <ng-container
        *ngTemplateOutlet="reviewTable;context:{tableRecords:  mentorReview.menteeApplication.preRequisites.items}">
      </ng-container>
    </div>
  </div>
  <span class="text-gray-header size-24 weight-bold mb-3">Acceptance Recommendation</span>
  <div class="card-header py-4 px-3">
    <div class="d-flex flex-column card-content py-4 px-3">
      <span class="size-20 weight-600 text-dark mb-3-2">Make Recommendation Decision</span>
      <div class="d-flex align-items-center">
        <span class="mr-3 text-gray-header" for="customSwitch1" (click)="isRecommended = !isRecommended">
          Not Recommended
        </span>
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="customSwitch1" [(ngModel)]="isRecommended">
          <label class="custom-control-label text-gray-header pl-2" for="customSwitch1">Recommended</label>
        </div>
      </div>
      <span class="text-dark mt-4 mb-2">Provide Feedback For Program Admin</span>
      <textarea class="form-control w-70 mb-4-2" [(ngModel)]="overallFeedback" name="" id="" rows="5"
        placeholder="Enter feedback"></textarea>
      <div class="d-flex align-items-center justify-content-end">
        <button type="button" class="btn btn-outline-secondary custom-btn mr-2">Cancel</button>
        <button type="button" class="btn btn-primary custom-btn ml-1" [disabled]="!overallFeedback.length"
          (click)="submitReview()">
          Submit
        </button>
      </div>
    </div>
  </div>
  <div class="spacer"></div>
</div>

<ng-template #loadingTemplate>
  <lfx-loading></lfx-loading>
</ng-template>


<ng-template #reviewTable let-tableRecords="tableRecords">
  <div class="common-table-container">
    <div class="table-header">
      <span class="table-header-column" lfxEllipsis [ngStyle]="{'minWidth': column.width,'maxWidth': column.width, 'padding-left': (first || last) ? '0' : '32px',
         'padding-right': (first || last) ? '0' :  '32px'}" [ngClass]="{'text-center': column.isCenter}"
        *ngFor="let column of columns; let first = first; let last = last; let index = index">
        {{column.title}}
      </span>
    </div>
    <div class="table-body">
      <ngb-accordion #a="ngbAccordion" [closeOthers]="true">
        <ngb-panel *ngFor="let row of tableRecords; let rowIndex = index">
          <ng-template ngbPanelHeader let-opened="opened">
            <button ngbPanelToggle class="normal-element table-row">
              <div class="table-body-column d-flex" [ngStyle]="{'minWidth': column.width,'maxWidth': column.width,
                'padding-left': (first || last) ? '0' : '32px',
                 'padding-right': (first || last) ? '0' : '32px'}"
                *ngFor="let column of columns; let first = first; let last = last; let index =index">
                <ng-container
                  *ngTemplateOutlet="column.template; context: {$implicit: {columnConfig: column, rowData: row, rowIndex: rowIndex}}">
                </ng-container>
              </div>
            </button>
          </ng-template>

          <ng-template ngbPanelContent>
            <ng-container *ngTemplateOutlet="commentsTemplate;context:{comments:row}"></ng-container>

          </ng-template>
        </ngb-panel>

      </ngb-accordion>
    </div>
  </div>
</ng-template>

<!-- Templates -->
<ng-template #taskNameTemplate let-element>
  <span class="task-name mw-100 w-100" lfxEllipsis>
    {{ element.rowData.prerequisiteName}}
  </span>
</ng-template>

<ng-template #descriptionTemplate let-element>
  <span class="text-gray-200 mw-100 w-100">
    {{ element.rowData.description }}
  </span>
</ng-template>

<ng-template #actionsTemplate let-element>
  <div class="d-flex justify-content-center w-100">
    <button type="button" class="btn btn-outline-primary size-12 weight-bold mr-2">Give Feedback</button>
    <button type="button" class="btn btn-outline-primary size-12 weight-bold ml-1"
      (click)="$event.stopPropagation();downloadFile(element.rowData.submitFile)">View</button>
    <!-- <button type="button" class="btn btn-outline-primary">Visit URL</button> -->
  </div>
</ng-template>

<ng-template #commentsTemplate let-comments="comments">
  <div class="comments-container">
    <div class="comment" *ngFor="let comment of comments.comments">
      <!--*ngFor="let comment of comments" -->
      <span class="commented-by">
        {{comment.commentBy+' '+(comment.commentBy === 'Mentee'? 'Comments':'Feedback')}}
        <!-- {{comment.commentBy+' '+(comment.commentBy === 'Mentee'? 'Comments':'Feedback')}} -->
      </span>
      <span class="comment-text">{{comment.commentText}}</span>
      <!-- <span class="comment-text">{{comment.commentText}}</span> -->
    </div>
    <button type="button" class="btn btn-outline-primary ml-auto comment-btn mt-3">Leave Feedback</button>
    <div class="add-task-comment mt-1 d-flex flex-column">
      <span class="size-14 text-dark my-2" for="leave-feedback">Leave Feedback</span>
      <textarea class="form-control mb-3-2" name="leave-feedback" id="leave-feedback" rows="3"
        placeholder="Enter your feedback" [(ngModel)]="feedbackText"></textarea>
      <div class="action d-flex align-items-center justify-content-end">
        <button type="button" class="btn btn-outline-secondary custom-btn mr-2">Cancel</button>
        <button type="button" class="btn btn-primary custom-btn ml-1" [disabled]="!feedbackText.length"
          (click)="submitFeedBack(comments)">Submit</button>
      </div>
    </div>
  </div>
</ng-template>