import { animate, style, transition, trigger } from "@angular/animations";

export let fadeInTop = trigger('fadeInTop', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-40px)' }),
        animate(
            '500ms ease-out',
            style({ opacity: 1, transform: 'translateY(0px)' })
        )
    ])
]);

export let fadeOutTop = trigger('fadeOutTop', [
    transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0px)' }),
        animate(
            '250ms ease-out',
            style({ opacity: 0, transform: 'translateY(-40px)' })
        )
    ])
]);
