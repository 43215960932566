import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    private loadingSubject = new Subject<boolean>();
    loadingObservable: Observable<boolean>;

    constructor() {
        this.loadingObservable = this.loadingSubject.asObservable();
    }

    startLoading() {
        this.loadingSubject.next(true);
    }

    endLoading() {
        this.loadingSubject.next(false);
    }
}