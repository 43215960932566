<div class="wrap" (clickOutside)="showList = false">
  <input class="input-search" type="text" placeholder="Search" [(ngModel)]="listFilter" (keyup.enter)="onSearchEnter()"
    (ngModelChange)="listFilterListener$.next($event)" />
  <div class="wrap-search">
    <app-icon-loader *ngIf="isLoading"></app-icon-loader>
    <span i18n *ngIf="listFilter && !isLoading" class="clear-search" (click)="clearSearch()">&times;</span>
    <img *ngIf="!isLoading && !listFilter" class="search-icon" src="/assets/icon-search.svg" />
  </div>
  <div class="filtered-project-list" [hidden]="!showList">
    <ng-container *ngIf="filteredList$ | async as items; else notFound">
      <ng-container *ngFor="let item of items; let last = last">
        <ng-container *ngIf="template; else defaultTemplate">
          <ng-container *ngTemplateOutlet="template; context: {$implicit: item}"></ng-container>
        </ng-container>
        <ng-template #defaultTemplate>
          <a [ngClass]="{ last: last, 'with-link': item.path }" class="fpl-item" (click)="navigateTo(item)">
            <img
              [src]="item.programDetail?.programLogoURL ? item.programDetail?.programLogoURL : '/assets/no_logo_uploaded.svg'"
              alt="" />

            <div class="fpl-name">
              <span *ngIf="item.programName">
                {{ item.programName }}
              </span>
              <span *ngIf="item.companyName">
                {{ item.companyName }}
              </span>
            </div>
          </a>
        </ng-template>
      </ng-container>
    </ng-container>
    <ng-template #notFound>
      <div *ngIf="listFilter" class="fpl-item last">
        <div i18n class="fpl-name">
          Items not found
        </div>
      </div>
    </ng-template>
  </div>
</div>