<div class="modal-header align-items-center">
  <h4 class="modal-title weight-600 size-20" id="modal-basic-title">Filter</h4>
  <i class="fa fa-times close-btn" aria-hidden="true" (click)="activeModal.dismiss('Cross click')"></i>
</div>
<div class="modal-body flex-unset d-flex p-0">

  <div class="col-4 p-0 tabs-container">
    <div class="tab weight-600 cursor-pointer" [ngClass]="{'active': tabIndex === activeTabIndex}"
      *ngFor="let tab of tabsConfig; let tabIndex = index" (click)="changeActiveTab(tabIndex)">
      {{tab.title}}
    </div>
  </div>
  <div class="col-8 p-3 d-flex flex-column filter-tab-container">
    <div class="weight-600 size-14 text-dark mb-2">{{tabsConfig[activeTabIndex].infoTitle}}</div>
    <ng-container [ngSwitch]="tabsConfig[activeTabIndex].type">
      <ng-container *ngSwitchCase="'autoCompleteList'">
        <ng-container *ngTemplateOutlet="autoCompleteListTemplate;context: {tab: tabsConfig[activeTabIndex]}">
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'text'">
        <ng-container *ngTemplateOutlet="textTemplate;context: {tab: tabsConfig[activeTabIndex]}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'radio'">
        <ng-container *ngTemplateOutlet="radioTemplate;context: {tab: tabsConfig[activeTabIndex]}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'searchableList'">
        <ng-container *ngTemplateOutlet="searchableListTemplate;context: {tab: tabsConfig[activeTabIndex]}">
        </ng-container>
      </ng-container>
    </ng-container>

    <div class="d-flex align-items-center mt-auto">
      <button type="button" class="btn btn-outline-secondary custom-btn ml-auto" (click)="cancel()">Cancel</button>
      <button type="button" class="btn btn-primary custom-btn ml-3" (click)="applyFilter()">Apply</button>
    </div>
  </div>
</div>

<ng-template #autoCompleteListTemplate let-tab="tab">
  <div class="form-card d-flex flex-column mb-3">
    <input id="typeahead-focus" #itemInput placeholder="Enter value" type="text" class="form-control mb-4-2"
      [ngbTypeahead]="search" (focus)="focus$.next($any($event).target.value)"
      (click)="click$.next($any($event).target.value)" #instance="ngbTypeahead" />
    <button type="button" class="btn btn-outline-primary ml-auto custom-btn"
      [ngClass]="{'mb-3': getSelectedItems(tab.controlName).length}" (click)="addItem(itemInput)"
      [disabled]="!itemInput.value.length">Add</button>
    <div class="d-flex flex-wrap">
      <div class="select-item mb-1 d-flex justify-content-between align-items-center"
        *ngFor="let item of getSelectedItems(tab.controlName).value; let index = index">
        {{item}}
        <i class="fas fa-times" (click)="removeItem(index);"></i>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #searchableListTemplate let-tab="tab">
  <div class="form-card d-flex flex-column mb-3">
    <lfx-auto-complete-dropdown class="w-80 mr-3 mb-4-2" [parentForm]="dummyForm" [controlName]="'item'"
      [dropdownPlaceholder]="'search'" [items]="tab.items" [showSearchIcon]="true" [searchFieldName]="tab.viewName">
    </lfx-auto-complete-dropdown>
    <button type="button" class="btn btn-outline-primary ml-auto custom-btn"
      [ngClass]="{'mb-3': getSelectedItems(tab.controlName).length}" [disabled]="dummyForm.invalid"
      (click)="addListItem()">Add</button>
    <div class="d-flex flex-wrap">
      <div class="select-item mb-1 d-flex justify-content-between align-items-center"
        *ngFor="let item of getSelectedItems(tab.controlName).value; let index = index">
        {{item[tab.viewName]}}
        <i class="fas fa-times" (click)="removeItem(index);"></i>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #radioTemplate let-tab="tab">
  <form class="p-0 m-0 form-card d-flex flex-column mb-3" [formGroup]="componentForm">
    <div class="custom-control custom-radio mb-2 cursor-pointer">
      <input type="radio" class="custom-control-input cursor-pointer" [id]="'radio-tab-'+tab.infoTitle+'--1'"
        [formControlName]="tab.controlName" [name]="tab.controlName" [value]="''">
      <label class="custom-control-label cursor-pointer" [for]="'radio-tab-'+tab.infoTitle+'--1'">
        {{'none' | titlecase}}
      </label>
    </div>
    <div class="custom-control custom-radio mb-2 cursor-pointer" *ngFor="let item of tab.items;let index = index">
      <input type="radio" class="custom-control-input cursor-pointer" [id]="'radio-tab-'+tab.infoTitle+'-'+index"
        [formControlName]="tab.controlName" [name]="tab.controlName" [value]="item">
      <label class="custom-control-label cursor-pointer" [for]="'radio-tab-'+tab.infoTitle+'-'+index">
        {{item | titlecase}}
      </label>
    </div>
  </form>
</ng-template>


<ng-template #textTemplate let-tab="tab">
  <form class="p-0 m-0" [formGroup]="componentForm">
    <input type="text" class="form-control" [formControlName]="tab.controlName" placeholder="Enter Value">
  </form>
</ng-template>