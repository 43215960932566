<div class="d-flex task-management-container flex-wrap px-80">
  <lfx-breadcrumbs [breadcrumbs]="breadcrumbs"></lfx-breadcrumbs>
  <div class="col-lg-4 col-md-12 col-sm-12 mb-4-2">
    <div class="card">
      <h2 class="card-header">
        <span class="header-text size-20 weight-600">
          To Do
        </span>
        <span class="list-size size-18 weight-600">
          {{todo.length}}
        </span>
      </h2>
      <button class="create-milestone-btn weight-600 mx-4" (click)="createNewMilestone()">
        <i class="fa fa-plus" aria-hidden="true"></i>
        Create Milestone
      </button>
      <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="todo" id="todo"
        [cdkDropListConnectedTo]="[doingList,doneList]" class="list card-body" (cdkDropListDropped)="drop($event)">
        <lfx-milestone-card [milestone]="milestone" [generalView]="true" class="milestone-card d-flex mb-4-2"
          *ngFor="let milestone of todo" cdkDrag>
        </lfx-milestone-card>
      </div>
    </div>
  </div>

  <div class="col-lg-4 col-md-12 col-sm-12 mb-4-2">
    <div class="card">
      <h2 class="card-header">
        <span class="header-text size-20 weight-600"> Doing </span>
        <span class="list-size size-18 weight-600">
          {{doing.length}}
        </span>
      </h2>

      <div cdkDropList #doingList="cdkDropList" [cdkDropListData]="doing" id="doing"
        [cdkDropListConnectedTo]="[doneList,todoList]" class="list card-body" (cdkDropListDropped)="drop($event)">
        <lfx-milestone-card [milestone]="milestone" [generalView]="true" class="milestone-card d-flex mb-4-2"
          *ngFor="let milestone of doing" cdkDrag>
        </lfx-milestone-card>
      </div>
    </div>
  </div>

  <div class="col-lg-4 col-md-12 col-sm-12 mb-4-2">
    <div class="card">
      <h2 class="card-header">
        <span class="header-text size-20 weight-600">Done</span>
        <span class="list-size size-18 weight-600">
          {{done.length}}
        </span>
      </h2>

      <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="done" id="done"
        [cdkDropListConnectedTo]="[doingList,todoList]" class="list card-body" (cdkDropListDropped)="drop($event)">
        <lfx-milestone-card [milestone]="milestone" [generalView]="true" class="milestone-card d-flex mb-4-2"
          *ngFor="let milestone of done" cdkDrag>
        </lfx-milestone-card>
      </div>
    </div>
  </div>
</div>