import { Router } from '@angular/router';
import { MentorService } from './../../../core/services/mentor.service';
import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { APIService, Mentor } from 'src/app/API.service';

@Component({
  selector: 'lfx-mentor-list',
  templateUrl: './mentor-list.component.html',
  styleUrls: ['./mentor-list.component.scss']
})
export class MentorListComponent implements OnInit {

  mentors = new Array();
  breadcrumbs = [
    {
      name: 'Select Mentor',
      url: ''
    }
  ]
  constructor(
    private route: Router,
    private api: APIService,
    private mentorService: MentorService
  ) { }

  ngOnInit() {
    from(this.api.ListMentors()).subscribe(res => {
      this.mentors = res.items as any;
    });
  }

  updateActiveMentor(mentor: Mentor) {
    this.mentorService.activeMentor = mentor;
    this.route.navigate(['']);
  }

}
