import { MentorService } from './../services/mentor.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { APIService } from 'src/app/API.service';
import { from } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class TaskController {


    httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    });

    options = {
        headers: this.httpHeaders
    };

    constructor(
        private http: HttpClient,
        private api: APIService,
        private mentorService: MentorService
    ) { }

    createTask(request: any) {
        return this.http.post('https://0sz2cejc5f.execute-api.us-east-1.amazonaws.com/dev/mentorship-service/mentor/create-task', request, this.options).pipe(map((res: any) => res.response.data.createTask));
    }

    getAllMilestones() {
        return from(this.api.ListMilestones(this.generateProgramIDsFilter()));
    }

    private generateProgramIDsFilter() {
        const filter = { or: new Array() };
        this.mentorService.activeMentor?.programs?.items?.forEach(program => {
            filter.or.push({
                programID: { eq: program?.programID }
            });
        });
        return filter;
    }

    
}