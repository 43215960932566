import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Utilities } from './../../../core/utilities';
import { from } from 'rxjs';
import { Program } from './../../../models/program';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { APIService, CreateMilestoneInput } from '../../../API.service';
import { MentorService } from 'src/app/core/services/mentor.service';

@Component({
  selector: 'lfx-create-milestone-popup',
  templateUrl: './create-milestone-popup.component.html',
  styleUrls: ['./create-milestone-popup.component.scss']
})
export class CreateMilestonePopupComponent implements OnInit {

  milestoneForm = new FormGroup({
    name: new FormControl('', Validators.required),
    program: new FormControl('', Validators.required),
    term: new FormControl('', Validators.required),
    dueDate: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required)
  });
  programs = new Array<Program>();
  isLoading = false;
  today = new Date();
  utilities = new Utilities();
  minMilestoneDueDate = new Date();
  maxMilestoneDueDate = new Date();

  get program() {
    return this.milestoneForm.get('program');
  }
  constructor(
    private api: APIService,
    public activeModal: NgbActiveModal,
    private mentorService: MentorService
  ) { }

  ngOnInit() {
    this.getAllPrograms();
    this.program?.valueChanges.subscribe((newValue) => {
      const previousValue = this.milestoneForm?.value.program;
      if (previousValue && previousValue.id === newValue.id) {
        return;
      }
      this.milestoneForm.get('term')?.setValue('');
      this.milestoneForm.get('dueDate')?.setValue('');
    });
    this.milestoneForm.get('term')?.valueChanges.subscribe(newValue => {
      if (!newValue.length) {
        return;
      }
      this.minMilestoneDueDate = new Date(newValue.termStartDate);
      this.maxMilestoneDueDate = new Date(newValue.termEndDate);
    });
  }

  getAllPrograms() {
    this.isLoading = true;
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    this.programs = this.mentorService.activeMentor?.programs?.items?.map(program => program?.program) as any;
    this.programs = this.programs.map(program => {
      program.terms = program.terms.filter(term => this.utilities.isDateInRange(term.termStartDate, term.termEndDate, today))
      return program;
    })
    this.isLoading = false;
    // from(this.api.ListPrograms()).subscribe((res: any) => {
    //   let { items } = res;
    //   this.programs = items;
    // });
  }

  clearDate(FormControl: string) {
    this.milestoneForm.get(FormControl)?.reset();
  }

  async createMilestone() {
    const formValue = this.milestoneForm.value;
    let milestone: CreateMilestoneInput = {
      createdBy: '',
      dueDate: formValue.dueDate.getTime(),
      milestoneName: formValue.name,
      programID: formValue.program.id,
      status: "todo",
      termName: formValue.term.termName,
      description: formValue.description
    };
    from(this.api.CreateMilestone(milestone)).subscribe((milestone: any) => {
      milestone.dueDate = new Date(milestone.dueDate) as any;
      milestone.program.mentees.items = milestone.program.mentees.items.map((mentee: any) => mentee.mentee);
      this.activeModal.close(milestone);
    });
  }


}
