<div class="program-mentee-container px-80">
  <lfx-breadcrumbs [breadcrumbs]="breadcrumbs"></lfx-breadcrumbs>
  <div class="d-flex align-items-center flex-column-sm">
    <lfx-card-number-view class="col-lg-3 col-sm-12 flex-unset mb-3 mr-3 px-0" [cardValue]="0"
      [cardText]="'Ongoing Mentorships'">
    </lfx-card-number-view>
    <lfx-card-number-view class="col-lg-3 col-sm-12 flex-unset mb-3 mr-3 px-0" [cardValue]="0"
      [cardText]="'Completed Mentorships'">
    </lfx-card-number-view>
    <lfx-card-number-view class="col-lg-3 col-sm-12 flex-unset mb-3 mr-3 px-0" [cardValue]="0"
      [cardText]="'Participating Mentees'">
    </lfx-card-number-view>
    <lfx-card-number-view class="col-lg-3 col-sm-12 flex-unset mb-3 px-0" [cardValue]="0"
      [cardText]="'Participating Mentors'">
    </lfx-card-number-view>
  </div>

  <div class="card mb-5">
    <div class="card-header d-flex px-0 pb-0">
      <div class="tab">Mentees</div>
      <div class="actions ml-auto">
        <lfx-input-search [filterFun]="filterFun" class="mr-3" (filter)="applyNameFilter($event)"
          [template]="usernameTemplate"></lfx-input-search>
        <button type="button" class="btn btn-primary default-border-radius" (click)="openFilterDialog()">
          <i class="fa fa-filter" aria-hidden="true"></i>
        </button>
        <div class="switch-view">
          <i class="fas fa-th-list" [ngClass]="{'active': tableView}" (click)="tableView = true"></i>
          <i class="fas fa-th-large" [ngClass]="{'active': !tableView}" (click)="tableView = false"></i>
        </div>
      </div>
    </div>
    <div class="card-body">
      <ng-container *ngIf="!isLoadMore && pagination.items!.length > 0; else loadingTemplate">
        <div class="grid-container" *ngIf="tableView === false">
          <lfx-mentee-details-card *ngFor="let mentee of pagination.items" [mentee]="mentee">
          </lfx-mentee-details-card>
        </div>
        <app-common-table *ngIf="tableView === true" [tableConfiguration]="tableConfig"
          [tableRecords]="pagination.items"></app-common-table>
        <lfx-pagination [paginationConfig]="pagination" (paginationChange)="updatePagination()" class="pt-4 mt-auto">
        </lfx-pagination>
      </ng-container>
    </div>
  </div>

  <ng-template #loadingTemplate>
    <div *ngIf="!isLoadMore"
      class="size-20 weight-bold d-flex flex-grow-1 w-100 align-items-center justify-content-center">
      No records found
    </div>
    <lfx-loading *ngIf="isLoadMore" class="flex-grow-1"></lfx-loading>
  </ng-template>
  <div class="spacer"></div>
</div>

<ng-template #usernameTemplate let-element>
  <div class="d-flex align-items-center w-100 m-2 mb-0">
    <img class="user-image mr-2 border-radius-25" style="height: 50px;width: 50px;"
      [src]="element.imageUrl ? element.imageUrl : 'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/'+element.firstName.toLowerCase().charAt(0)+'.png'"
      alt="">
    <span class="custom-user-name text-primary cursor-pointer" lfxEllipsis>{{element.firstName + ' ' +
      element.lastName}}</span>
  </div>
</ng-template>


<ng-template #menteeNameTemplate let-element>
  <span class="text-primary" (click)="showProjects(element.rowIndex)">
    <i class="fas"
      [ngClass]="{'fa-angle-down': showAll == element.rowIndex, 'fa-angle-right': showAll !== element.rowIndex}"></i>
    <img class="user-images mx-4"
      [src]="element.rowData.imageUrl ? element.rowData.imageUrl :'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/'+element.rowData.firstName.toLowerCase().charAt(0) +'.png'"
      alt="">
    {{element.rowData.firstName}} {{element.rowData.lastName}}
  </span>
</ng-template>

<ng-template #programTemplate let-element>
  <span *ngIf="showAll !== element.rowIndex" class="text-primary w-100 mw-100 cursor-pointer py-2"
    lfxEllipsis>{{element.rowData.menteeApplications[0]?.program.programName}}</span>
  <div *ngIf="showAll === element.rowIndex" class="d-flex flex-column w-100 h-100">
    <span *ngFor="let app of element.rowData.menteeApplications" class="text-primary cursor-pointer w-100 mw-100 py-2"
      lfxEllipsis>
      {{app.program?.programName}}
    </span>
  </div>
</ng-template>

<ng-template #statusTemplate let-element>
  <span *ngIf="showAll !== element.rowIndex" class="status w-100 mw-100 py-2" [ngClass]="{
      'pending-status': element.rowData.menteeApplications[0]?.applicationStatus === menteeStatus.pending,
      'accepted-status': element.rowData.menteeApplications[0]?.applicationStatus === menteeStatus.accepted,
      'declined-status': element.rowData.menteeApplications[0]?.applicationStatus === menteeStatus.declined,
      'completed-status': element.rowData.menteeApplications[0]?.applicationStatus === menteeStatus.graduated
    }" lfxEllipsis>
    {{element.rowData.menteeApplications[0]?.applicationStatus | titlecase}}
  </span>
  <div *ngIf="showAll === element.rowIndex" class="d-flex flex-column w-100 h-100">
    <span *ngFor="let application of element.rowData.menteeApplications" class="status w-100 mw-100 py-2" [ngClass]="{
        'pending-status': application.applicationStatus === menteeStatus.pending,
        'accepted-status': application.applicationStatus === menteeStatus.accepted,
        'declined-status': application.applicationStatus === menteeStatus.declined,
        'completed-status': application.applicationStatus === menteeStatus.graduated
      }" lfxEllipsis>
      {{application.applicationStatus | titlecase}}
    </span>
  </div>
</ng-template>


<ng-template #actionTemplate let-element>
  <div class="d-flex justify-content-around align-items-center w-100 py-1">
    <button type="button" class="btn btn-outline-primary py-1 px-3">Message</button>
    <button type="button" class="btn btn-outline-primary py-1 px-3">Email</button>
  </div>
</ng-template>