import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { Mentee } from 'src/app/models/mentee';
import { Mentor } from 'src/app/models/mentor';
import { Program } from 'src/app/models/program';

@Component({
  selector: 'lfx-mentor-details-card',
  templateUrl: './mentor-details-card.component.html',
  styleUrls: ['./mentor-details-card.component.scss']
})
export class MentorDetailsCardComponent implements OnInit {

  activePopover!: NgbPopover;
  @Input() mentor!: Mentor;
  constructor(
    private route: Router
  ) { }

  ngOnInit() {
    this.mentor.mentees = [];
    this.mentor.programs?.forEach(program => {
      this.mentor.mentees = _.unionWith(this.mentor.mentees, program.program.mentees, (a: Mentee, b: Mentee) => {
        return a.id === b.id;
      });
    });
  }

  navigateToMentorDetail(): void {
    // TODO: navigate to mentor details page
  }

  viewProgram(program: Program) {
    this.route.navigateByUrl('/program/overview/' + program.id)
  }

  openPopover(popover: NgbPopover) {
    this.activePopover = popover;
    if (this.activePopover && !this.activePopover.isOpen()) {
      this.activePopover.open();
    }
  }

  closePopover() {
    if (this.activePopover && this.activePopover.isOpen()) {
      this.activePopover.close();
    }
  }

}
