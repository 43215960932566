import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MentorshipDashboardCardComponent } from './components/mentorship-dashboard-card/mentorship-dashboard-card.component';
import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MentorListComponent } from './pages/mentor-list/mentor-list.component';
import { MentorsPageComponent } from './pages/mentors-page/mentors-page.component';
import { MenteesPageComponent } from './pages/mentees-page/mentees-page.component';
import { MenteeDetailsCardComponent } from './components/mentee-details-card/mentee-details-card.component';
import { MentorDetailsCardComponent } from './components/mentor-details-card/mentor-details-card.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    MentorshipDashboardCardComponent,
    DashboardComponent,
    MentorListComponent,
    MentorsPageComponent,
    MenteesPageComponent,
    MenteeDetailsCardComponent,
    MentorDetailsCardComponent
  ],
})
export class DashboardModule { }
